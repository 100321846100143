import { gql } from "@apollo/client";
export const GET_EXPERTS = gql`
  query getAllExperts($page: Int) {
    getAllExperts(page: $page) {
      success
      message
      totalPages
      experts {
        _id
        description
        user {
          name
          email
          role
          profilePicture
        }
        contactNumber
        address
        created_at
        updated_at
      }
    }
  }
`;

export const GET_ALL_EXPERTS = gql`
  query {
    getAllExperts(page: 0) {
      success
      message
      experts {
        _id
        user {
          _id
          name
          profilePicture
        }
      }
    }
  }
`;

export const GET_EXPERT = gql`
  mutation($expertID: ID!) {
    getExpert(expertID: $expertID) {
      success
      message

      expert {
        _id
        description
        user {
          name
          email
          role
          profilePicture
        }
        contactNumber
        address
        created_at
        updated_at
      }
    }
  }
`;

export const ADD_EXPERT = gql`
  mutation addExpert(
    $name: String
    $email: String
    $password: String
    $contactNumber: String
    $address: String
    $profilePicture: String
    $description: String
  ) {
    addExpert(
      input: {
        name: $name
        email: $email
        password: $password
        contactNumber: $contactNumber
        address: $address
        profilePicture: $profilePicture
        description: $description
      }
    ) {
      success
      message
      expert {
        _id
        description
        user {
          profilePicture
          name
          email
          role
        }
        contactNumber
        address
        created_at
        updated_at
      }
    }
  }
`;

export const UPDATE_EXPERT = gql`
  mutation updateExpert($expertID: ID!, $updateObj: UpdateExpertInputFields!) {
    updateExpert(input: { expertID: $expertID, updateObj: $updateObj }) {
      success
      message
      expert {
        _id
        description
        user {
          name
          email
          role
          profilePicture
          password
        }
        contactNumber
        address
        created_at
        updated_at
      }
    }
  }
`;

export const DELETE_EXPERT = gql`
  mutation deleteExpert($expertID: ID!) {
    deleteExpert(expertID: $expertID) {
      success
      message
    }
  }
`;
