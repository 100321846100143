import { gql } from "@apollo/client";
export const GET_DEVELOPERS = gql`
  query getAllDevelopers($page: Int) {
    getAllDevelopers(page: $page) {
      success
      message
      developers {
        _id
        name
        images
        officeAddress
        email
        phoneNumber
        businessName
        website
        description
      }
      totalPages
    }
  }
`;
export const GET_ALL_DEVELOPERS = gql`
  query {
    getAllDevelopers(page: 0) {
      success
      message
      developers {
        _id
        name
      }
    }
  }
`;

export const ADD_DEVELOPER = gql`
  mutation addDeveloper(
    $name: String
    $email: String
    $businessName: String
    $phoneNumber: String
    $officeAddress: String
    $website: String
    $description: String
    $images: [String]
  ) {
    addDeveloper(
      input: {
        name: $name
        email: $email
        businessName: $businessName
        officeAddress: $officeAddress
        website: $website
        phoneNumber: $phoneNumber
        description: $description
        images: $images
      }
    ) {
      success
      message
      developer {
        name
        officeAddress
        email
        businessName
        phoneNumber
        website
      }
    }
  }
`;

export const UPDATE_DEVELOPER = gql`
  mutation updateDeveloper(
    $id: ID!
    $name: String
    $email: String
    $businessName: String
    $phoneNumber: String
    $officeAddress: String
    $website: String
    $description: String
    $images: [String]
  ) {
    updateDeveloper(
      id: $id
      input: {
        name: $name
        images: $images
        email: $email
        businessName: $businessName
        officeAddress: $officeAddress
        website: $website
        phoneNumber: $phoneNumber
        description: $description
      }
    ) {
      success
      message
      developer {
        _id
        name
        officeAddress
        email
        businessName
        phoneNumber
        website
        description
      }
    }
  }
`;

export const DELETE_DEVELOPER = gql`
  mutation deleteDeveloper($id: ID!) {
    deleteDeveloper(id: $id) {
      success
      message
    }
  }
`;
