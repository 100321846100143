import { gql } from '@apollo/client';

export const GET_QUERIES = gql`
  query getAllQueries($page: Int) {
    getAllQueries(page: $page) {
      success
      totalPages
      queries {
        _id
        status
        user {
          _id
          name
        }
        property {
          _id
          propertyName
        }
        expertInvolved {
        _id
          user {
            _id
            name
          }
        }
        created_at
        updated_at
      }
      totalDocs
      totalPages
      currentPage
    }
  }
`;

export const GET_QUERY_DETAIL = gql`
  query {
    getAllQueries(page: 1) {
      success
      message
      queries {
        getAllUsers(page: 0) {
          users {
            _id
            name
          }
        }
        getAllProperties(page: 0) {
          properties {
            _id
            propertyName
          }
        }
        getAllExperts(page: 0) {
          experts {
            _id
            user {
              name
            }
          }
        }
      }
    }
  }
`;

export const ADD_QUERY = gql`
  mutation addQuery($user: ID!, $property: ID!, $expertInvolved: ID!, $status: String!) {
    addQuery(input: { user: $user, property: $property, expertInvolved: $expertInvolved, status: $status }) {
      success
      message
      query {
        status
        created_at
        updated_at
      }
    }
  }
`;

export const DELETE_QUERY = gql`
  mutation deleteQuery($queryID: ID!) {
    deleteQuery(queryID: $queryID) {
      success
      message
    }
  }
`;

export const UPDATE_QUERY = gql`
  mutation ($queryID: ID!) {
  updateQueryStatus(queryID: $queryID) {
    success
    message
    query {
      _id
      status
      created_at
      updated_at
    }
  }
}`