// import { useEffect, useState } from 'react';


// material-ui
import { Grid, Typography } from '@mui/material';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Dashboard = () => {
  // const [isLoading, setLoading] = useState(true);
  // useEffect(() => {
  //   setLoading(false);
  // }, []);

  return (
    <Grid >
     
       
     <Typography 
    //  className='page-heading'
     variant='h2'

     > Dashboard</Typography>
         
     
  
  
    </Grid>
  );
};

export default Dashboard;
