import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { IOSSwitch, VerticalDivider } from 'ui-component/IOSSwitch';
import { useEffect } from 'react';
import { Button } from '@mui/material';

export default function SwitchesComponent({ access, handleAccess }) {
  const [bookingsSwitch, setBookingsSwitch] = useState(false);
  const [propertiesSwitch, setPropertiesSwitch] = useState(false);
  const [usersSwitch, setUsersSwitch] = useState(false);
  const [expertsSwitch, setExpertsSwitch] = useState(false);
  const [developersSwitch, setDevelopersSwitch] = useState(false);
  const [queriesSwitch, setQueriesSwitch] = useState(false);

  const handleSave = () => {
    handleAccess({
      bookingsAccess: bookingsSwitch,
      propertiesAccess: propertiesSwitch,
      usersAccess: usersSwitch,
      expertsAccess: expertsSwitch,
      developersAccess: developersSwitch,
      queriesAccess: queriesSwitch
    });
  };

  // console.log('bookingsSwitch:', bookingsSwitch);
  // console.log('propertiesSwitch:', propertiesSwitch);
  // console.log('usersSwitch:', usersSwitch);
  // console.log('expertsSwitch:', expertsSwitch);
  // console.log('developersSwitch:', developersSwitch);
  // console.log('-----------------------');

  useEffect(() => {
    if (access) {
      setBookingsSwitch(access?.bookingsAccess);
      setPropertiesSwitch(access?.propertiesAccess);
      setUsersSwitch(access?.usersAccess);
      setExpertsSwitch(access?.expertsAccess);
      setDevelopersSwitch(access?.developersAccess);
      setQueriesSwitch(access?.queriesAccess);
    }
  }, [access]);

  const handleBookingChange = () => {
    setBookingsSwitch((prev) => !prev);
  };
  const handlePropertyChange = () => {
    setPropertiesSwitch((prev) => !prev);
  };
  const handleUserChange = () => {
    setUsersSwitch((prev) => !prev);
  };
  const handleExpertChange = () => {
    setExpertsSwitch((prev) => !prev);
  };
  const handleDeveloperChange = () => {
    setDevelopersSwitch((prev) => !prev);
  };
  const handleQueriesChange = () => {
    setQueriesSwitch((prev) => !prev);
  };

  return (
    <>
      <Grid container spacing={3}>
        {/* Booking Access */}
        <Grid item xs={12} md={4}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <Typography variant="h4" gutterBottom>
                Bookings Access
              </Typography>
              <Typography variant="h6" gutterBottom>
                Give Bookings Access to User
              </Typography>
            </div>

            <div style={{ marginLeft: 'auto' }}>
              <FormGroup>
                <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={bookingsSwitch} />} onChange={handleBookingChange} />
              </FormGroup>
            </div>
            {/* <VerticalDivider /> */}
          </div>
        </Grid>

        {/* Property Access */}
        <Grid item xs={12} md={4}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <Typography variant="h4" gutterBottom>
                Properties Access
              </Typography>
              <Typography variant="h6" gutterBottom>
                Give Properties Access to User
              </Typography>
            </div>

            <div style={{ marginLeft: 'auto' }}>
              <FormGroup>
                <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={propertiesSwitch} />} onChange={handlePropertyChange} />
              </FormGroup>
            </div>
            {/* <VerticalDivider /> */}
          </div>
        </Grid>

        {/* User Access */}
        <Grid item xs={12} md={4}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <Typography variant="h4" gutterBottom>
                Users Access
              </Typography>
              <Typography variant="h6" gutterBottom>
                Give Users Access to User
              </Typography>
            </div>

            <div style={{ marginLeft: 'auto' }}>
              <FormGroup>
                <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={usersSwitch} />} onChange={handleUserChange} />
              </FormGroup>
            </div>
            {/* <VerticalDivider /> */}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ marginTop: '10px' }}>
        {/* Expert Access */}
        <Grid item xs={12} md={4}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <Typography variant="h4" gutterBottom>
                Experts
              </Typography>
              <Typography variant="h6" gutterBottom>
                Give Experts Access to User
              </Typography>
            </div>

            <div style={{ marginLeft: 'auto' }}>
              <FormGroup>
                <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={expertsSwitch} />} onChange={handleExpertChange} />
              </FormGroup>
            </div>
            {/* <VerticalDivider /> */}
          </div>
        </Grid>

        {/* DEveloper Access */}
        <Grid item xs={12} md={4}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <Typography variant="h4" gutterBottom>
                Developers Access
              </Typography>
              <Typography variant="h6" gutterBottom>
                Give Developers Access to User
              </Typography>
            </div>

            <div style={{ marginLeft: 'auto' }}>
              <FormGroup>
                <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={developersSwitch} />} onChange={handleDeveloperChange} />
              </FormGroup>
            </div>
            {/* <VerticalDivider /> */}
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <Typography variant="h4" gutterBottom>
                Queries Access
              </Typography>
              <Typography variant="h6" gutterBottom>
                Give Queries Access to User
              </Typography>
            </div>

            <div style={{ marginLeft: 'auto' }}>
              <FormGroup>
                <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={queriesSwitch} />} onChange={handleQueriesChange} />
              </FormGroup>
            </div>
            {/* <VerticalDivider /> */}
          </div>
        </Grid>
        <Button onClick={() => handleSave()}>
          <Typography color="primary" variant="h6" gutterBottom>
            Save
          </Typography>
        </Button>
      </Grid>
    </>
  );
}
