import React, { useContext, useEffect } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const AccountManagerPermission = ({ component }) => {
  const { isLoggedIn } = useContext(AuthContext);

  const auth = isLoggedIn();

  return auth.role[0] === 'Admin' || auth.role[0] === 'expert' ? component : <Navigate to="/404" />;
};

export default AccountManagerPermission;
