import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import SubCard from "ui-component/cards/SubCard";
import Chart from "components/Chart";
import { gridSpacing } from "store/constant";
import {
  AnnualRent,
  ROI,
  GrossRentalYield,
  ValueAppreciationAnnualRent,
  TotalReturn,
  ValueAppreciation,
} from "assets/images/estimator";
import Graph from "ui-component/EstimatorGraph";

const Estimator = ({ aiModelInformation }) => {
  const [annualRent, setAnnualRent] = useState("");
  const [grossRentalYield, setGrossRentalYield] = useState("");
  const [valueAppreciation, setValueAppreciation] = useState("");
  const [totalReturnPercentage, setTotalReturnPercentage] = useState("");
  const [roi, setRoi] = useState("");

  useEffect(() => {
    const assignValues = (searchData) => {
      console.log(searchData);
      setAnnualRent(searchData.aiModelInformation?.annualRent || 0);
      setGrossRentalYield(searchData.aiModelInformation?.grossRentalYield);
      setValueAppreciation(searchData.aiModelInformation?.appreciation);
      setTotalReturnPercentage(
        searchData.aiModelInformation?.totalReturnPercentage
      );
      setRoi(searchData.aiModelInformation?.roi);
    };

    if (aiModelInformation) {
      assignValues(aiModelInformation);
    }
  }, [aiModelInformation]);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <SubCard>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5}>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontSize: "24px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  marginBottom: "20px ",
                  marginLeft: "40px",
                }}
              >
                AI ESTIMATOR
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "100%", // Adjust height as needed
                  alignItems: "flex-start",
                  marginLeft: "40px",
                }}
              >
                {/* Item 1 */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "4px",
                    width: "80%", // Adjust width as needed
                    alignItems: "flex-start",
                    margin: "10px 0",
                  }}
                >
                  <AnnualRent />
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      textAlign: "center",
                      fontSize: "16px",
                      fontFamily: "Outfit",
                      fontWeight: "medium",
                      lineHeight: "24px",
                      color: "#fbb03b",
                      marginLeft: "20px",
                    }}
                  >
                    Annual Rent (AED {parseFloat(annualRent).toFixed(2)})
                  </Typography>
                </Box>

                {/* Item 2 */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "4px",
                    width: "80%", // Adjust width as needed
                    alignItems: "flex-start",
                    margin: "10px 0",
                  }}
                >
                  <GrossRentalYield />
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      textAlign: "center",
                      fontSize: "16px",
                      fontFamily: "Outfit",
                      lineHeight: "24px",
                      color: "#808080",
                      marginLeft: "20px",
                    }}
                  >
                    Gross Rental Yield (
                    {parseFloat(grossRentalYield).toFixed(4)} %)
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "4px",
                    width: "100%", // Adjust width as needed
                    alignItems: "flex-start",
                    margin: "10px 0",
                  }}
                >
                  <ValueAppreciation />
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      textAlign: "center",
                      fontSize: "16px",
                      fontFamily: "Outfit",
                      lineHeight: "24px",
                      color: "#808080",
                      marginLeft: "20px",
                    }}
                  >
                    Value Appreciation (AED{" "}
                    {parseFloat(valueAppreciation).toFixed(4)})
                  </Typography>
                </Box>

                {/* Item 4 */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "4px",
                    width: "60%", // Adjust width as needed
                    alignItems: "flex-start",
                    margin: "10px 0",
                  }}
                >
                  <TotalReturn />
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      textAlign: "center",
                      fontSize: "16px",
                      fontFamily: "Outfit",
                      lineHeight: "24px",
                      color: "#808080",
                      marginLeft: "20px",
                    }}
                  >
                    Total Return ({" "}
                    {parseFloat(totalReturnPercentage)?.toFixed(4)} %)
                  </Typography>
                </Box>

                {/* Item 5 */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "4px",
                    width: "60%", // Adjust width as needed
                    alignItems: "flex-start",
                    margin: "10px 0",
                  }}
                >
                  <ROI />
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      textAlign: "center",
                      fontSize: "16px",
                      fontFamily: "Outfit",
                      lineHeight: "24px",
                      color: "#808080",
                      marginLeft: "20px",
                    }}
                  >
                    ROI (AED {parseFloat(roi)?.toFixed(4)} )
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={7} justifyContent="flex-end">
              {/* <Chart /> */}
              <Graph graphData={aiModelInformation?.predict} />
            </Grid>
          </Grid>
        </SubCard>
      </Grid>
    </Grid>
  );
};

export default Estimator;
