import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Button, Typography, useMediaQuery } from "@mui/material";

import SubCard from "ui-component/cards/SubCard";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router";
import { ADD_EXPERT, UPDATE_EXPERT } from "grapgQL/experts";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useEffect } from "react";
import { ExpertsContext } from "context/expert";
import { useContext } from "react";
import UploadAccountAvatar from "ui-component/UploadIAccountAvatar";
import { AuthContext } from "context/AuthContext";

function ExpertsDetails() {
  const location = useLocation();

  const [expert, setExpert] = useState();

  const { logOut } = useContext(AuthContext);

  const status = location?.state?.status;

  const { setExpertsInfo } = useContext(ExpertsContext);

  const Navigate = useNavigate();

  const [profileImage, setProfileImage] = useState();

  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.up("xs"));

  const [addExpert] = useMutation(ADD_EXPERT, {
    onCompleted(data) {
      const { success, message } = data?.addExpert;

      if (success) {
        toast.success(message);
        setExpertsInfo(data?.addExpert);
        Navigate("/experts");
        // setUsersInfo(data?.addUser);
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      console.log(error);
      toast.error("Something Went Wrong!");
    },
  });

  const [updateExpert] = useMutation(UPDATE_EXPERT, {
    onCompleted(data) {
      console.log(data);
      const { success, message } = data?.updateExpert;

      if (success) {
        toast.success(message);
        Navigate("/experts");
        // setUsersInfo(data?.addUser);
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      console.log(error);
      toast.error("Something Went Wrong!");
    },
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      address: "",
      contactNumber: "",
      description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      address: Yup.string().required("Address is required"),
      contactNumber: Yup.number()
        .typeError("Phone Number must be a number")
        .required("Phone Number is required"),
    }),
    onSubmit: (values) => {
      if (status === "Add") {
        addExpert({
          variables: {
            name: values.name,
            email: values.email,
            password: values.password,
            address: values.address,
            contactNumber: values.contactNumber,
            profilePicture: profileImage,
            description: values.description,
          },
        });
      } else if (status === "Edit") {
        updateExpert({
          variables: {
            expertID: expert._id,
            updateObj: {
              name: values.name,
              email: values.email,
              address: values.address,
              contactNumber: values.contactNumber,
              profilePicture: profileImage,
              description: values.description,
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    const assignValues = (searchData) => {
      if (searchData) {
        setExpert(searchData);
        setProfileImage(searchData?.user?.profilePicture);

        formik.setValues({
          name: expert?.user.name || "",
          password: expert?.password || "",
          contactNumber: expert?.contactNumber || "",
          email: expert?.user.email || "",
          address: expert?.address,
          description: expert?.description,
        });
      }
    };

    const searchData = location?.state?.value;
    console.log(searchData);
    if (searchData) {
      assignValues(searchData);
    }
  }, [expert]);

  const GetExpertImage = (profileImage) => {
    setProfileImage(profileImage);
  };
  console.log("isMDScreen", isMdScreen, "isXSScreen", isXsScreen);

  return (
    <>
      <Typography variant="h2" gutterBottom style={{ marginBottom: "20px" }}>
        Experts Details
      </Typography>
      <Grid container spacing={2}>
        {/* Profile Avatar Section */}
        <Grid item xs={12} lg={3}>
          <SubCard title="Profile Avatar" style={{ height: "380px" }}>
            <UploadAccountAvatar
              GetProfilePic={GetExpertImage}
              profileImg={profileImage}
              status={status}
            />
          </SubCard>
        </Grid>

        {/* Card Section */}
        <Grid item xs={12} lg={9}>
          <SubCard
            title="Expert Details"
            style={{
              height: isMdScreen ? "380px" : "600px",
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    id="name"
                    label="Name"
                    placeholder="Name"
                    variant="outlined"
                    fullWidth
                    {...formik.getFieldProps("name")}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    InputProps={{
                      readOnly: status === "View", // Set readOnly based on the status
                    }}
                  />
                </Grid>
                {status === "Add" && (
                  <Grid item xs={12} lg={6}>
                    <TextField
                      id="outlined-password-input"
                      label="Password"
                      placeholder="Password"
                      type="password"
                      value={formik.values.password || ""}
                      onChange={formik.handleChange("password")}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      InputProps={{
                        readOnly: status === "View", // Set readOnly based on the status
                      }}
                      fullWidth
                    />
                  </Grid>
                )}
                <Grid item xs={12} lg={6}>
                  <TextField
                    id="email"
                    placeholder="Email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    {...formik.getFieldProps("email")}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    InputProps={{
                      readOnly: status === "View", // Set readOnly based on the status
                    }}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextField
                    id="address"
                    placeholder="Address"
                    label="Address"
                    variant="outlined"
                    fullWidth
                    {...formik.getFieldProps("address")}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                    InputProps={{
                      readOnly: status === "View", // Set readOnly based on the status
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    id="contactNumber"
                    placeholder="Contact Number"
                    label="Contact Number"
                    variant="outlined"
                    fullWidth
                    {...formik.getFieldProps("contactNumber")}
                    error={
                      formik.touched.contactNumber &&
                      Boolean(formik.errors.contactNumber)
                    }
                    helperText={
                      formik.touched.contactNumber &&
                      formik.errors.contactNumber
                    }
                    InputProps={{
                      readOnly: status === "View", // Set readOnly based on the status
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    id="description"
                    placeholder="Description"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2}
                    {...formik.getFieldProps("description")}
                    // error={
                    //   formik.touched.contactNumber &&
                    //   Boolean(formik.errors.contactNumber)
                    // }
                    // helperText={
                    //   formik.touched.contactNumber &&
                    //   formik.errors.contactNumber
                    // }
                    InputProps={{
                      readOnly: status === "View", // Set readOnly based on the status
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={8}>
                  {status === "Add" ? (
                    <Button
                      type="submit"
                      className="primary-button"
                      style={{
                        fontSize: "14px",
                        padding: "6px",
                        borderRadius: "5px",
                      }}
                    >
                      Submit
                    </Button>
                  ) : status === "Edit" ? (
                    <Button
                      type="submit"
                      className="primary-button"
                      style={{
                        fontSize: "14px",
                        padding: "6px",
                        borderRadius: "5px",
                      }}
                    >
                      Update
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </form>
          </SubCard>
        </Grid>
      </Grid>
    </>
  );
}

export default ExpertsDetails;
