import { gql } from "@apollo/client";

export const SIGNIN = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      success
      message
      user {
        _id
        name
        accessToken
        role
        isAdmin
        name
        email
        profilePicture

        whatsappNumber

        postalAddress {
          streetAddress
          countryRegion
          city
          zipPostalCode
        }
        accountManager {
          access {
            usersAccess
            expertsAccess
            bookingsAccess
            queriesAccess
            propertiesAccess
            developersAccess
          }
        }
      }
    }
  }
`;
