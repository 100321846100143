import { Navigate, useRoutes } from 'react-router-dom';
import MinimalLayout from 'layout/MinimalLayout';
import QueriesDetails from 'views/queries-page/QueryDetails';
import QueriesPage from 'views/queries-page';
import DeveloperDetails from 'views/developer-page/developerForm';
import DeveloperPage from 'views/developer-page';
import SettingPage from 'views/setting-page';
import PropertyDetails from 'views/property-page/propertydetails/propertydetails';
import AccountManager from 'ui-component/AccountManagers/AccountManager';
import AddUserForm from 'views/user-page/userdetails/addUser';
import UserPage from 'views/user-page';
import BookingForm from 'views/booking-page/bookingForm.js';
import BookingPage from 'views/booking-page';
import ExpertsDetails from 'views/expert-page/ExpertsDetails';
import ExpertsPage from 'views/expert-page';
import PropertyPage from 'views/property-page';
import MainLayout from 'layout/MainLayout';

import Dashboard from 'views/dashboard/Default';
import Login from 'views/pages/authentication/authentication3/Login3';
import { AuthContext } from 'context/AuthContext';
import { useContext } from 'react';
import Page404 from 'views/pages/Page404';
import PropertiesPermission from 'components/RoutesPermissions/PropertiesPermission';
import ExpertsPermission from 'components/RoutesPermissions/ExpertsPermission';
import BookingsPermission from 'components/RoutesPermissions/BookingsPermissionRoutes';
import UsersPermission from 'components/RoutesPermissions/UsersPermission';
import DevelopersPermission from 'components/RoutesPermissions/DevelopersPermission';
import QueriesPermission from 'components/RoutesPermissions/QueriesPermission';
import AccountManagerPermission from 'components/RoutesPermissions/AccountManagerPermisison';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { isLoggedIn } = useContext(AuthContext);

  const auth = isLoggedIn();
  console.log(auth);

  return useRoutes([
    {
      path: '/',

      element: auth?.isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
      children: [
        {
          path: 'dashboard',
          children: [
            {
              path: 'default',
              element: <Dashboard />
            }
          ]
        },
        {
          path: 'properties',

          element: <PropertiesPermission component={<PropertyPage />} />
        },
        {
          path: 'properties/propertydetails',
          element: <PropertyDetails />
        },
        // {
        //   path: 'properties/propertydetails',
        //   element: <PropertiesPermission component={<PropertyDetails />} />
        // },

        {
          path: 'experts',
          element: <ExpertsPermission component={<ExpertsPage />} />
        },
        {
          path: 'experts/expertsdetails',
          element: <ExpertsDetails />
        },
        // {
        //   path: 'experts/expertsdetails',
        //   element: <ExpertsPermission component={<ExpertsDetails />} />
        // },
        {
          path: 'bookings',
          element: <BookingsPermission component={<BookingPage />} />
        },
        ,
        {
          path: 'bookings/bookingdetails',
          element: <BookingsPermission component={<BookingForm />} />
        },

        { path: 'users', element: <UsersPermission component={<UserPage />} /> },
        // {
        //   path: 'users/adduser',
        //   element: <UsersPermission component={<AddUserForm />} />
        // },
        {
          path: 'users/adduser',
          element:  <AddUserForm /> 
        },
        {
          path: 'account-manager',
          element: <AccountManagerPermission component={<AccountManager />} />
        },

        {
          path: 'settings',
          element: <AccountManagerPermission component={<SettingPage />} />
        },
        {
          path: 'developers',
          element: <DevelopersPermission component={<DeveloperPage />} />
        },
        {
          path: 'developers/developerdetails',
          element: <DevelopersPermission component={<DeveloperDetails />} />
        },
        {
          path: 'queries',
          element: <QueriesPermission component={<QueriesPage />} />
        },
        {
          path: 'queries/queriesdetails',
          element: <QueriesPermission component={<QueriesDetails />} />
        }
      ]
    },

    // {
    //   path: '/',
    //   element: <MinimalLayout />,
    //   children: [
    //     {
    //       path: '/login',
    //       element: <Login />
    //     }
    //   ]
    // },
    {
      path: '/',
      element: <MinimalLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
        // { path: '/', element: <Navigate to="/users" /> },
        { path: '/login', element: auth.isLoggedIn ? <Navigate to="/" /> : <Login /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
