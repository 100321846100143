import React, { createContext, useState } from 'react';

// Create the context
export const PropertyContext = createContext();

// Create the context provider

export const PropertyProvider = ({ children }) => {
  const [propertyInfo, setPropertyInfo] = useState();

  return <PropertyContext.Provider value={{ propertyInfo, setPropertyInfo }}>{children}</PropertyContext.Provider>;
};
