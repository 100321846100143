import React, { useState, useEffect } from "react"; // ,{ useState }

import MUIDataTable from "mui-datatables";
import Typography from "@mui/material/Typography";
// import { userData } from 'apis/userData';

// import { createTheme } from '@mui/material/styles';
import { useNavigate } from "react-router";
// import { ThemeProvider } from '@emotion/react';
import { Button, IconButton, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import CircularLoader from "ui-component/loader/CircularLoader";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
// import '../pages/pages.scss';
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { mytheme } from "themes/tableHeader";

import { TableHeading } from "components/tableHeading";
import { DELETE_DEVELOPER, GET_DEVELOPERS } from "grapgQL/developer";
import { useContext } from "react";
import { DevelopersContext } from "context/developers";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box } from "@mui/system";
import { AuthContext } from "context/AuthContext";
// import { Link } from 'react-router-dom;';

const DeveloperPage = () => {
  const [devData, setDevData] = useState();

  const [loading, setLoading] = useState(true);

  const [rowData, setRowData] = useState("");

  // const theme = useTheme();

  const { developersInfo } = useContext(DevelopersContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const [totalPages, setTotalPages] = useState(0);

  const [totalRows, setTotalRows] = useState(1);

  const [page, setPage] = useState(1);

  const { logOut } = useContext(AuthContext);

  const [allDevelopers] = useLazyQuery(GET_DEVELOPERS, {
    fetchPolicy: "no-cache",
    context: { clientName: "backend" },
    onCompleted(data) {
      const { getAllDevelopers } = data;

      if (getAllDevelopers) {
        const { success, message, developers, totalPages } = getAllDevelopers;
        if (success) {
          setLoading(false);

          console.log("response", developers);
          const dataWithIndex = developers.map((data, index) => ({
            index: index + 1,
            ...data,
          }));

          setDevData(dataWithIndex);
          setTotalRows(dataWithIndex.length);
          setTotalPages(totalPages);
        }
        setLoading(false);
      } else {
        setLoading(false);
        // if (message === "Token Expired") {
        //   logOut();
        // }
      }
    },
    onError(error) {
      console.log("Something went wrong", error);
    },
  });

  useEffect(() => {
    if (page) {
      allDevelopers({
        variables: {
          page: page,
        },
      });
    }
  }, [developersInfo, page]);

  const [deleteDeveloper] = useMutation(DELETE_DEVELOPER, {
    onCompleted(data) {
      console.log(data);
      const { success, message } = data.deleteDeveloper;
      console.log("Message", message);
      console.log("Success", success);
      if (success) {
        setPage(1);
        allDevelopers();
        toast.success(message);
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      console.log("error", error);
    },
  });

  const handleClick = (value, event) => {
    setRowData(value);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (value) => {
    if (
      window.confirm("Are you sure you want to delete this Developer account?")
    ) {
      // User clicked "OK"
      console.log(value._id); // Assuming Value is defined elsewhere in your code
      deleteDeveloper({
        variables: {
          id: value._id,
        },
      });
      setAnchorEl(null);
    } else {
      // User clicked "Cancel" or closed the dialog
      // Handle accordingly, if needed
    }
  };
  const handleEdit = (value) => {
    setAnchorEl(null);
    Navigate("/developers/developerdetails", {
      state: { status: "Edit", value },
    });
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setDevData([]);
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    // Assuming 10 rows per page (adjust as needed)

    if (page < totalPages) {
      setDevData([]);
      setPage(page + 1);
    }
  };

  // const [open, setOpen] = useState(false);

  const Navigate = useNavigate();

  const handleClickOpen = () => {
    Navigate("/developers/developerdetails", { state: { status: "Add" } });
  };

  const columns = [
    {
      name: "index",
      label: " ",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        sort: false,
      },
    },

    {
      name: "businessName",
      label: "Business name",
      options: {
        sort: false,
      },
    },

    {
      name: "email",
      label: "Email",
      options: {
        sort: false,
      },
    },

    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        sort: false,
      },
    },

    {
      name: "website",
      label: "Website",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          let url = value;

          // Check if the URL starts with "http://" or "https://"
          if (!url?.startsWith("http://") && !url?.startsWith("https://")) {
            // If not, prepend "https://"
            url = `https://${url}`;
          }

          return (
            <a
              href={url}
              rel="noreferrer"
              target={"_blank"}
              style={{
                padding: "8px 6px",
                maxWidth: "100px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                background: "#8080801A",
                color: "black",
                borderRadius: "4px",
                textAlign: "center",
                cursor: "pointer",
                textDecoration: "none",
                display: "inline-block",
              }}
            >
              Visit Website
            </a>
          );
        },
      },
    },
    {
      name: "name",
      // name: 'ViewProfile',
      label: " ",

      options: {
        sort: false,
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Typography
              className="details-text"
              onClick={() =>
                Navigate("/developers/developerdetails", {
                  state: { status: "View", value: devData[dataIndex] },
                })
              }
              sx={{ cursor: "pointer", fontFamily: "Outfit" }}
            >
              View Details
            </Typography>
          );
        },
      },
    },
    {
      name: "website",
      label: " ",
      options: {
        sort: false,
        filter: false,

        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <Button
                type="button"
                onClick={(event) => handleClick(devData[dataIndex], event)}
              >
                <MoreVertIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => handleEdit(rowData)}>
                  <ModeEditOutlineOutlinedIcon /> Edit
                </MenuItem>
                <MenuItem onClick={() => handleDelete(rowData)}>
                  <DeleteOutlinedIcon /> Delete
                </MenuItem>
              </Menu>
            </>
          );
        },
      },
    },
  ];

  const HeaderElements = () => {
    return (
      <>
        {/* <Button type="button" onClick={() => handleClickOpen()}>
          + Add User
        </Button> */}

        <Button
          className="primary-button"
          onClick={() => handleClickOpen()}
          style={{
            fontSize: "14px",
            padding: "8px 12px",
            borderRadius: "8px",

            "&:hover": {
              backgroundColor: "#eeeeee",
            },
            margin: "0 8px",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Outfit",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0em",
              textAlign: "center",
            }}
          >
            Add Developer
          </Typography>
        </Button>
      </>
      // <Button type="button" onClick={() => Navigate('/addUser', { state: { isEdit: false } })}>
      //   + Add User
      // </Button>
    );
  };

  const options = {
    responsive: "standard",
    print: false,
    download: false,
    tableLayout: "fixed",
    customTableBodyWidth: "800px",
    tableBodyHeight: "500px",
    selectableRowsHideCheckboxes: true,
    customToolbar: HeaderElements,
    viewColumns: false,
    customFooter: () => {
      if (totalPages) {
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            height="60px"
          >
            {/* <Typography variant="body1" sx={{ margin: '0 4px', minWidth: 30, textAlign: 'center', marginRight: '10px' }}>
              Current Page:
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              value={page}
              onChange={(e) => {
                const pageNumber = parseInt(e.target.value, 10);
                if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
                  setPage(pageNumber);
                }
              }}
              style={{ marginRight: '20px', width: '40px' }}
            /> */}
            <Typography
              variant="body1"
              sx={{
                margin: "0 4px",
                minWidth: 40,
                textAlign: "center",
                marginRight: "20px",
              }}
            >
              {1}-{totalRows} of {totalRows}
            </Typography>

            <IconButton
              size="small"
              onClick={handlePrevPage}
              disabled={page === 1}
              sx={{ marginRight: 1, padding: 0.5 }}
            >
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton
              size="small"
              onClick={handleNextPage}
              disabled={page >= totalPages}
              sx={{ padding: 0.5, marginRight: "40px" }}
            >
              <KeyboardArrowRight />
            </IconButton>
          </Box>
        );
      }
      return null;
    },
  };

  return (
    <>
      <TableHeading name={"Developers"} />
      {loading ? (
        // <div>i am loading</div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularLoader />
        </div>
      ) : (
        <>
          {devData ? (
            <ThemeProvider theme={mytheme}>
              <MUIDataTable
                // title={'Users list'}
                data={devData}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          ) : (
            <ThemeProvider theme={mytheme}>
              <MUIDataTable
                // title={'Users list'}
                data={[]}
                columns={columns}
                options={options}
              />{" "}
            </ThemeProvider>
          )}

          {/* <ThemeProvider /> */}
        </>
      )}
    </>
  );
};

export default DeveloperPage;
