import React from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  InputAdornment,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  useMediaQuery,
} from "@mui/material";
// import MuiTypography from '@mui/material/Typography';
import SubCard from "ui-component/cards/SubCard";
// import ReactPlayer from 'react-player/lazy';
import { gridSpacing } from "store/constant";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import UploadFeaturePic from "ui-component/UploadFeaturePic";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { useState } from "react";
import MapWithAutocomplete from "components/googleMap";
import { set } from "immutable";

const ProfileContent = ({
  onTabsValue,
  propertyInformation,
  status,
  setSharedData,
  sharedData,
}) => {
  // console.log('Seeee', status);
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    location: Yup.string().required("Location is required"),
    price: Yup.object().shape({
      min: Yup.number().min(0, "Min Price must be at least 0"),
      max: Yup.number().min(0, "Max Price must be at least 0"),
    }),
    category: Yup.string().required("Category is required"),
    bedrooms: Yup.number()
      .required("Bedrooms is required")
      .min(0, "Bedrooms must be at least 0")
      .max(50, "Bedrooms must be at most 50"),
    description: Yup.string().required("Description is required"),
    area: Yup.number().required("Area is required"),
    // initialInvestment: Yup.number()
    // .required('Initial Investment is required')
    // .min(1, 'Initial Investment must be greater than 0'),
    // propertyType: Yup.string().required('Property Type is required'),
  });
  const [bookingDate, setBookingDate] = useState(dayjs("2023-02-11"));

  const [image, setImage] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const selectedLocationHandler = (location) => {
    setSelectedLocation(location);
    // console.log("Selected Location", location);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      location: "",
      price: {
        min: 0,
        max: 10000,
        fixPrice: 0,
      },
      initialInvestment: 10000,
      propertyType: "offPlan",
      bathrooms: "",
      propertyAge: "",

      category: "",
      bedrooms: 0,
      area: "",
      balconyTerrace: false,
      hvacSystem: false,
      furnishing: false,
      livingRoomSize: 20,
      description: "",
      handoverDate: dayjs("2023-02-11"),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formattedHandoverDate = dayjs(bookingDate).format("YYYY-MM-DD");
      if (!image) {
        toast.error("Please Upload Property Image");
        return;
      }
      // Update values with the formatted handoverDate
      const updatedValues = {
        ...values,
        handoverDate: formattedHandoverDate,
        imageUrl: image,
        selectedLocation: selectedLocation,
      };

      if (values.propertyType === "offPlan") {
        if (
          values.price.min > values.price.max &&
          values.price.max > 0 &&
          values.price.min > 0
        ) {
          toast.error("Max Price should be greater than Min Price");
        } else {
          setSharedData(updatedValues);
          onTabsValue(3);
        }
      } else if (values.propertyType === "ready") {
        if (values.price.fixPrice === 0) {
          toast.error("Fix Price is required");
        }
        // console.log("DATA", updatedValues);
        else {
          setSharedData(updatedValues);
          onTabsValue(3);
        }
        if (status !== "View") {
          // toast.success('Information Submited');
        }
      }
    },
  });

  useEffect(() => {
    const assignValues = (searchData) => {
      formik.setValues({
        bathrooms: searchData.propertyDetails?.bathrooms,
        propertyAge: searchData.propertyDetails?.propertyAge,

        category: searchData.propertyDetails?.buildingType,
        bedrooms: searchData.propertyDetails?.bedrooms,
        area: searchData.propertyDetails?.area,
        balconyTerrace: searchData.propertyDetails?.balcony,
        hvacSystem: searchData.propertyDetails?.heatingCoolingSystems,
        furnishing: searchData.propertyDetails?.furnished,
        livingRoomSize: searchData.propertyDetails?.livingRoomSize,
        ...searchData,
      });
      setSelectedLocation(searchData.setSelectedPlace);
      setImage(searchData.featuredImage);

      setBookingDate(dayjs(searchData.handover));
    };

    if (propertyInformation) {
      assignValues(propertyInformation);
    }
  }, [propertyInformation]);

  const handleDateChange = (newValue) => {
    setBookingDate(newValue);
  };

  const GetFeaturePic = (image) => {
    setImage(image);
  };

  return (
    <>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} lg={4} height={450}>
          <SubCard
            title="Feature Picture"
            style={{ height: isMdScreen ? "420px" : "400px" }}
          >
            <UploadFeaturePic
              GetFeaturePic={GetFeaturePic}
              image={image}
              status={status}
            />
          </SubCard>
        </Grid>

        <Grid item xs={12} lg={8}>
          <SubCard
            title="Property Informamtion"
            style={{
              fontFamily: "Outfit",
              fontSize: "20px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0em",
            }}
          >
            <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    inputProps={{
                      readOnly: status === "View", // Set readOnly based on the status
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Location"
                    name="location"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.location && Boolean(formik.errors.location)
                    }
                    helperText={
                      formik.touched.location && formik.errors.location
                    }
                    inputProps={{
                      readOnly: status === "View", // Set readOnly based on the status
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Initial Investment"
                    type="number"
                    name="initialInvestment"
                    value={formik.values.initialInvestment}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.initialInvestment &&
                      Boolean(formik.errors.initialInvestment)
                    }
                    helperText={
                      formik.touched.initialInvestment &&
                      formik.errors.initialInvestment
                    }
                    inputProps={{
                      readOnly: status === "View",
                    }}
                  />
                </Grid>
                {/* {status !== "View" && ( */}
                <Grid item xs={12} sm={6}>
                  <MapWithAutocomplete
                    onSelectedValue={selectedLocationHandler}
                    selectedLocation={selectedLocation}
                    status={status}
                  />
                </Grid>
                {/* )} */}

                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-helpe-label">
                      Property Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helpe-label"
                      id="demo-simple-select-helpe"
                      name="propertyType"
                      value={formik.values.propertyType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label="Property Type"
                      error={
                        formik.touched.propertyType &&
                        Boolean(formik.errors.propertyType)
                      }
                      inputProps={{
                        readOnly: status === "View",
                      }}
                    >
                      <MenuItem value="offPlan">Off-Plan</MenuItem>
                      <MenuItem value="ready">Ready</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {formik.values && formik.values.propertyType === "ready" && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Fixed Price"
                      type="number"
                      name="price.fixPrice"
                      value={formik.values.price?.fixPrice}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.price?.fixPrice &&
                        Boolean(formik.errors.price?.fixPrice)
                      }
                      helperText={
                        formik.touched.price?.fixPrice &&
                        formik.errors.pricefixedPrice
                      }
                      inputProps={{
                        readOnly: status === "View",
                      }}
                    />
                  </Grid>
                )}

                {formik.values && formik.values.propertyType === "offPlan" && (
                  <Grid item xs={12} sm={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          label="Min Price"
                          type="number"
                          name="price.min"
                          value={formik.values.price?.min}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.price?.min &&
                            Boolean(formik.errors.price?.min)
                          }
                          helperText={
                            formik.touched.price?.min &&
                            formik.errors.price?.min
                          }
                          inputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                AED
                              </InputAdornment>
                            ),
                            readOnly: status === "View",
                          }}
                        />
                      </Grid>

                      <Grid item xs={5}>
                        <TextField
                          label="Max Price"
                          type="number"
                          name="price.max"
                          value={formik.values.price?.max}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.price?.max &&
                            Boolean(formik.errors.price?.max)
                          }
                          helperText={
                            formik.touched.price?.max &&
                            formik.errors.price?.max
                          }
                          inputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                AED
                              </InputAdornment>
                            ),
                            readOnly: status === "View",
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Grid style={{ marginTop: "15px" }} container spacing={2}></Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Number of BedRooms"
                    type="number"
                    name="bedrooms"
                    value={formik.values.bedrooms}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.bedrooms && Boolean(formik.errors.bedrooms)
                    }
                    helperText={
                      formik.touched.bedrooms && formik.errors.bedrooms
                    }
                    inputProps={{
                      readOnly: status === "View",
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Number of Bathrooms"
                    type="number"
                    name="bathrooms"
                    value={formik.values.bathrooms}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.bathrooms &&
                      Boolean(formik.errors.bathrooms)
                    }
                    helperText={
                      formik.touched.bathrooms && formik.errors.bathrooms
                    }
                    inputProps={{
                      readOnly: status === "View",
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Property Age"
                    type="number"
                    name="propertyAge"
                    value={formik.values.propertyAge}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.propertyAge &&
                      Boolean(formik.errors.propertyAge)
                    }
                    helperText={
                      formik.touched.propertyAge && formik.errors.propertyAge
                    }
                    inputProps={{
                      readOnly: status === "View",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label">
                      Area
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      name="area"
                      value={formik.values.area}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label="Area"
                      error={formik.touched.area && Boolean(formik.errors.area)}
                      inputProps={{
                        readOnly: status === "View",
                      }}
                    >
                      <MenuItem value={3}>500 - 1,000 sq ft</MenuItem>
                      <MenuItem value={2}>1,000 - 2,500 sq ft</MenuItem>
                      <MenuItem value={4}>2,500 - 5,000 sq ft</MenuItem>
                      <MenuItem value={5}>5,000 - 8,000 sq ft</MenuItem>
                      <MenuItem value={6}>8,000 - 10,000 sq ft</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label">
                      Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      name="category"
                      value={formik.values.category}
                      onChange={formik.handleChange}
                      label="Category"
                      inputProps={{
                        readOnly: status === "View",
                      }}
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.category)
                      }
                    >
                      <MenuItem value="apartment">Apartment</MenuItem>
                      <MenuItem value="villa">Villa</MenuItem>
                      <MenuItem value="townhouse">Townhouses</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {formik.values && formik.values.propertyType === "offPlan" && (
                  <Grid item xs={12} sm={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <div>
                        <DatePicker
                          id="handoverDate"
                          name="handoverDate"
                          format="DD/MM/YYYY"
                          defaultValue={dayjs("2023-01-01")}
                          value={bookingDate}
                          inputProps={{
                            readOnly: status === "View",
                          }}
                          onChange={(newValue) => handleDateChange(newValue)}
                        />
                      </div>
                    </LocalizationProvider>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                    inputProps={{
                      readOnly: status === "View",
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-balcony-terrace-label">
                      Balcony/Terrace
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-balcony-terrace-label"
                      id="demo-simple-select-balcony-terrace"
                      name="balconyTerrace"
                      value={formik.values.balconyTerrace}
                      onChange={formik.handleChange}
                      label="Balcony/Terrace"
                      inputProps={{
                        readOnly: status === "View",
                      }}
                      error={
                        formik.touched.balconyTerrace &&
                        Boolean(formik.errors.balconyTerrace)
                      }
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-hvac-label">
                      Heating/Cooling System
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-hvac-label"
                      id="demo-simple-select-hvac"
                      name="hvacSystem"
                      value={formik.values.hvacSystem}
                      onChange={formik.handleChange}
                      label="Heating/Cooling System"
                      inputProps={{
                        readOnly: status === "View",
                      }}
                      error={
                        formik.touched.hvacSystem &&
                        Boolean(formik.errors.hvacSystem)
                      }
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-furnishing-label">
                      Furnishing
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-furnishing-label"
                      id="demo-simple-select-furnishing"
                      name="furnishing"
                      value={formik.values.furnishing}
                      onChange={formik.handleChange}
                      label="Furnishing"
                      inputProps={{
                        readOnly: status === "View",
                      }}
                      error={
                        formik.touched.furnishing &&
                        Boolean(formik.errors.furnishing)
                      }
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-living-room-size-label">
                      Living Room Size
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-living-room-size-label"
                      id="demo-simple-select-living-room-size"
                      name="livingRoomSize"
                      value={formik.values.livingRoomSize}
                      onChange={formik.handleChange}
                      label="Living Room Size"
                      inputProps={{
                        readOnly: status === "View",
                      }}
                      error={
                        formik.touched.livingRoomSize &&
                        Boolean(formik.errors.livingRoomSize)
                      }
                    >
                      <MenuItem value={20}>20 - 100 sqm</MenuItem>
                      <MenuItem value={100}>100 - 120 sqm</MenuItem>
                      <MenuItem value={150}>120 - 150 sqm</MenuItem>
                      <MenuItem value={200}>150 - 200 sqm</MenuItem>
                      <MenuItem value={250}>200 - 250 sqm</MenuItem>
                      {/* Add more options based on your specific needs */}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {status !== "View" && (
                <Button
                  className="primary-button"
                  type="submit"
                  onClick={() => console.log("Clicked")} // Replace with your actual click handler
                  style={{
                    fontSize: "14px",
                    padding: "8px 12px",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "#eeeeee",
                    },
                    margin: "20px 0 5px",
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      fontFamily: "Outfit",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    {status === "Add" ? "Add Property" : "Update Property"}
                  </Typography>
                </Button>
              )}
            </form>
          </SubCard>
        </Grid>
      </Grid>
    </>
  );
};

export default ProfileContent;
