// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';

import { AuthContext } from 'context/AuthContext';
import { useContext } from 'react';
import { AdminItems, ExpertsRoutes, ManagerItems } from 'menu-items/SideBarMenu';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const { isLoggedIn, logOut, authUser } = useContext(AuthContext);

  const userDataString = localStorage.getItem('userData');
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const AccountManagerAccess = userData?.accountManager?.access;
  let NavConfig;
  let result;
  const auth = isLoggedIn();

  if (auth.role[0] === 'accountManager') {
    const filteredRoutes = ManagerItems?.items?.filter((item) => {
      const permissionKey = item?.id + 'Access'; // Assuming keys are the same as the route names with "Access" suffix
      return AccountManagerAccess[permissionKey];
    });

    result = { items: filteredRoutes };
  }

  if (auth.role[0] === 'Admin') {
    NavConfig = AdminItems;
  } else if (auth.role[0] === 'expert') {
    NavConfig = ExpertsRoutes;
  } else if (auth.role[0] === 'accountManager') {
    NavConfig = result;
  } else {
    logOut();
  }

  if (NavConfig) {
    const navItems = NavConfig.items.map((item) => {
      switch (item.type) {
        case 'group':
          return <NavGroup key={item.id} item={item} />;

        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });

    return <>{navItems}</>;
  }
};

export default MenuList;
