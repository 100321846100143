import React, { useState } from "react";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";

import ListItemText from "@mui/material/ListItemText";

import Checkbox from "@mui/material/Checkbox";
// import MuiTypography from '@mui/material/Typography';
import SubCard from "ui-component/cards/SubCard";
// import ReactPlayer from 'react-player/lazy';

import Carousel from "react-material-ui-carousel";

import { gridSpacing } from "store/constant";
import { Paper } from "@mui/material";
import { useEffect } from "react";
import { toast } from "react-toastify";
import UploadMedia from "components/UploadMedia";
import PaymentPlan from "components/PaymentPlan";
import { ContactlessOutlined } from "@mui/icons-material";
import { ADD_PROPERTY, UPDATE_PROPERTY } from "grapgQL/property";
import { useLocation, useNavigate } from "react-router";
import { useLazyQuery, useMutation } from "@apollo/client";
import { PropertyContext } from "context/property";
import { useContext } from "react";
import { GET_ALL_DEVELOPERS } from "grapgQL/developer";
import { GET_ALL_EXPERTS } from "grapgQL/experts";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MoreInformationContent = ({
  morePropertyInformation,
  sharedData,
  status,
  onTabsValue,
}) => {
  const [personName, setPersonName] = useState([]);
  const Navigate = useNavigate();
  const { setPropertyInfo } = useContext(PropertyContext);

  const [paymentPlan, setPaymentPlan] = useState();
  const [developers, setDevelopers] = useState([]);
  const [experts, setExperts] = useState([]);
  const [selectedDeveloper, setSelectedDeveloper] = useState("");
  const [selectedExpert, setSelectedExpert] = useState("");
  const [media, setMedia] = useState([]);
  const [formValues, setFormValues] = useState({
    amenities: [],
    nearby: [],
    developerDetails: "",
    view: "cityView",
    kitchenType: "closed",
    parking: "covered",
    flooringType: "tile",
    windowFeatures: "sdf",
    gardenYard: false,

    utilityRoom: true,
    energyEfficiencyRating: "60%",
  });
  const [developerId, setDeveloperId] = useState("");

  const [getAllDevelopers] = useLazyQuery(GET_ALL_DEVELOPERS, {
    fetchPolicy: "no-cache",
    context: { clientName: "backend" },
    onCompleted(data) {
      const { getAllDevelopers } = data;

      if (getAllDevelopers) {
        const { success, message, developers } = getAllDevelopers;
        if (success) {
          setDevelopers(developers);
        }
      }
    },
    onError(error) {
      console.log("Something went wrong", error);
    },
  });

  const [getAllExperts] = useLazyQuery(GET_ALL_EXPERTS, {
    fetchPolicy: "no-cache",
    context: { clientName: "backend" },
    onCompleted(data) {
      const { getAllExperts } = data;

      if (getAllExperts) {
        const { success, message, experts } = getAllExperts;
        if (success) {
          setExperts(experts);
        }
      }
    },
    onError(error) {
      console.log("Something went wrong", error);
    },
  });

  useEffect(() => {
    const assignValues = (searchData) => {
      // console.log("assignValues", searchData.expertAssigned);

      setPaymentPlan(searchData.paymentPlans);
      setSelectedDeveloper(searchData.developerDetails);
      setSelectedExpert(searchData.expertAssigned?._id);
      if (searchData) {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          developer: searchData.developers[0],

          paymentPlan:
            searchData.paymentPlan || prevFormValues.paymentPlan || "",
          amenities: searchData.amenities || prevFormValues.amenities || [],
          nearby: searchData.nearby || prevFormValues.nearby || [],
          view: searchData.propertyDetails.view,
          kitchenType: searchData.propertyDetails.kitchenType,
          parking: searchData.propertyDetails.parking,
          flooringType: searchData.propertyDetails.flooringType,
          windowFeatures: searchData.propertyDetails.windowFeatures,
          gardenYard: searchData.propertyDetails.garden,

          utilityRoom: searchData.propertyDetails.utilityRoom,
          energyEfficiencyRating:
            searchData.propertyDetails.energyEfficiencyRating,
        }));
      }
    };
    getAllDevelopers();
    getAllExperts();
    if (morePropertyInformation) {
      assignValues(morePropertyInformation);
    }
  }, [morePropertyInformation]);

  const [addProperty] = useMutation(ADD_PROPERTY, {
    onCompleted(data) {
      const { success, message } = data?.addProperty;

      if (success) {
        // toast.success(message);
        setPropertyInfo(data?.addProperty);
        onTabsValue(4);
        // Navigate("/properties");
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      toast.error("Something Went Wrong!");
    },
  });

  const [updateProperty] = useMutation(UPDATE_PROPERTY, {
    onCompleted(data) {
      // console.log(data);
      const { success, message } = data.updateProperty;

      if (success) {
        // toast.success(message);
        Navigate("/properties");
      } else {
        if (message === '"updateObj.setSelectedPlace.name" must be a string') {
          toast.error("Selected place Is Not Correct");
        } else {
          toast.error(message);
        }
      }
    },

    onError(error) {
      toast.error("Something Went Wrong!");
    },
  });

  const handleMultiSelectChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const HandlePaymentMethod = (data) => {
    if (data) {
      toast.success("Payment Plan Added successfully");
      setPaymentPlan(data);
    }
  };

  const GetMediaImage = (media) => {
    setMedia(media);
  };

  const handleObjectChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };
  const handleDeveloperChange = (event) => {
    const developer = event.target.value;
    const id = developers?.find((item) => item.name === developer)?._id;

    setSelectedDeveloper(developer);

    setDeveloperId(id);
  };
  const handleExpertChange = (event) => {
    setSelectedExpert(event.target.value);
  };

  const handleSubmit = () => {
    console.log(media);
    if (media.length === 0) {
      toast.error("Please Add Property Media");
      return;
    }
    if (media?.images?.length < 3) {
      toast.error("Please Add Atleast 3 Media");
      return;
    }
    if (
      !personName ||
      formValues?.amenities?.length === 0 ||
      formValues?.nearby?.length === 0 ||
      !selectedDeveloper ||
      paymentPlan?.length === 0
    ) {
      toast.error("Please Fill all Fields");
      if (!paymentPlan) {
        toast.error("Please Add Payment Plan");
      }

      return;
    } else {
      if (status === "Add") {
        addProperty({
          variables: {
            property: {
              featuredImage: sharedData?.imageUrl,
              propertyMedia: media,
              propertyName: sharedData?.name,
              initialInvestment: sharedData?.initialInvestment,
              propertyType: sharedData?.propertyType,
              paymentPlans: paymentPlan?.map((plan) => ({
                reservation: plan.reservation,
                amount: plan.amount,
                afterTime: plan.afterTime,
                // initial: plan.initial,
              })),
              propertyInformation: {
                location: sharedData?.location,
                price: {
                  min: sharedData?.price?.fixPrice ? 0 : sharedData?.price?.min,
                  max: sharedData?.price?.fixPrice ? 0 : sharedData?.price?.max,
                  fixPrice: sharedData?.price?.fixPrice || 0,
                },
                handover: sharedData?.handoverDate,
              },
              setSelectedPlace: {
                name: sharedData?.selectedLocation?.name,
                location: {
                  lat: `${sharedData?.selectedLocation?.location.lat}`,
                  lng: `${sharedData?.selectedLocation?.location.lng}`,
                },
              },
              propertyDetails: {
                bedrooms: sharedData?.bedrooms,
                bathrooms: sharedData?.bathrooms,
                area: sharedData?.area,
                balcony: sharedData?.balconyTerrace,
                view: formValues?.view,
                kitchenType: formValues?.kitchenType,
                livingRoomSize: sharedData?.livingRoomSize,
                parking: formValues?.parking,
                flooringType: formValues?.flooringType,
                garden: formValues?.gardenYard,
                propertyAge: sharedData?.propertyAge,
                heatingCoolingSystems: sharedData?.hvacSystem,
                windowFeatures: formValues?.windowFeatures,
                utilityRoom: formValues?.utilityRoom,
                furnished: sharedData?.furnishing,
                buildingType: sharedData?.category,
                energyEfficiencyRating: formValues?.energyEfficiencyRating,
              },
              expertAssigned: selectedExpert,
              amenities: formValues.amenities,
              nearby: formValues.nearby,
              description: sharedData?.description,
              developerDetails: selectedDeveloper,
              developers: [developerId],
            },
          },
        });
      } else if (status === "Edit") {
        updateProperty({
          variables: {
            propertyID: sharedData?.propertyID,
            updateObj: {
              // featuredImage: sharedData?.imageUrl,
              propertyName: sharedData?.name,

              propertyMedia: media,
              initialInvestment: sharedData?.initialInvestment,
              propertyType: sharedData?.propertyType,
              paymentPlans: paymentPlan.map((plan) => ({
                reservation: plan.reservation,
                amount: plan.amount,
                afterTime: plan.afterTime,
                // initial: plan.initial,
              })),
              propertyInformation: {
                location: sharedData?.location,
                price: {
                  min: sharedData?.price?.fixPrice
                    ? 0
                    : parseInt(sharedData?.price?.min),
                  max: sharedData?.price?.fixPrice
                    ? 0
                    : parseInt(sharedData?.price?.max),
                  fixPrice: parseInt(sharedData?.price?.fixPrice) || 0,
                },

                handover: sharedData?.handoverDate,
              },
              setSelectedPlace: {
                name: sharedData?.selectedLocation?.name,
                location: {
                  lat: `${sharedData?.selectedLocation?.location.lat}`,
                  lng: `${sharedData?.selectedLocation?.location.lng}`,
                },
              },
              propertyDetails: {
                bedrooms: sharedData?.bedrooms,
                bathrooms: sharedData?.bathrooms,
                area: sharedData?.area,
                balcony: sharedData?.balconyTerrace,
                view: formValues?.view,
                kitchenType: formValues?.kitchenType,
                livingRoomSize: sharedData?.livingRoomSize,
                parking: formValues?.parking,
                flooringType: formValues?.flooringType,
                garden: formValues?.gardenYard,
                propertyAge: sharedData?.propertyAge,
                heatingCoolingSystems: sharedData?.hvacSystem,
                windowFeatures: formValues?.windowFeatures,
                utilityRoom: formValues?.utilityRoom,
                furnished: sharedData?.furnishing,
                buildingType: sharedData?.category,
                energyEfficiencyRating: formValues?.energyEfficiencyRating,
              },
              amenities: formValues.amenities,
              nearby: formValues?.nearby,
              description: sharedData?.description,
              expertAssigned: selectedExpert,
              // developers:
              developerDetails: selectedDeveloper,
            },
          },
        });
      }
    }
  };

  return (
    <>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <SubCard title="PAYMENT PLAN" sx={{ marginBottom: "20px" }}>
            <PaymentPlan
              onSave={HandlePaymentMethod}
              paymentPlans={paymentPlan}
              status={status}
            />
          </SubCard>
        </Grid>
        <Grid item xs={12} lg={6}>
          <SubCard title="MORE INFO" sx={{ marginBottom: "20px" }}>
            <form noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <FormControl fullWidth>
                    <InputLabel id="amenities-label">Amenities</InputLabel>
                    <Select
                      labelId="amenities-label"
                      id="amenities"
                      name="amenities"
                      multiple
                      value={formValues.amenities}
                      onChange={handleMultiSelectChange}
                      input={<OutlinedInput label="Amenities" />}
                      fullWidth
                      renderValue={(selected) => selected.join(" , ")}
                      MenuProps={MenuProps}
                      inputProps={{
                        readOnly: status === "View",
                      }}
                    >
                      {[
                        {
                          key: "privateSwimmingPool",
                          value: "Private Swimming Pool",
                        },
                        {
                          key: "communitySwimmingPool",
                          value: "Community Swimming Pool",
                        },
                        {
                          key: "gymFitnessCenter",
                          value: "Gym/Fitness Center",
                        },
                        {
                          key: "childrensPlayArea",
                          value: "Childrens Play Area",
                        },
                        { key: "security24/7", value: "Security 24/7" },
                        { key: "elevator", value: "Elevator" },
                        { key: "clubhouse", value: "Clubhouse" },
                        { key: "sportsFacilities", value: "Sports Facilities" },
                        { key: "tennisCourt", value: "Tennis Court" },
                        { key: "basketballCourt", value: "Basketball Court" },
                        { key: "spa/WellnessArea", value: "Spa/Wellness Area" },
                        {
                          key: "recreationalParks",
                          value: "Recreational Parks",
                        },
                        {
                          key: "highSpeedInternet/WiFi",
                          value: "High-Speed Internet/WiFi",
                        },
                        {
                          key: "petFriendlyEnvironment",
                          value: "Pet Friendly Environment",
                        },
                        { key: "maintenanceStaff", value: "Maintenance Staff" },
                        { key: "laundryServices", value: "Laundry Services" },
                        { key: "conciergeService", value: "Concierge Service" },
                        { key: "rooftopTerrace", value: "Rooftop Terrace" },
                        { key: "bbqArea", value: "BBQ Area" },
                        {
                          key: "meetingFunctionRooms",
                          value: "Meeting/Function Rooms",
                        },
                        {
                          key: "smartHomeFeatures",
                          value: "Smart Home Features",
                        },
                      ].map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                          <Checkbox
                            checked={
                              formValues.amenities.indexOf(option.key) > -1
                            }
                            color="secondary"
                          />
                          {option.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="DeveloperDetailsl">Developer</InputLabel>
                    <Select
                      labelId="Developer"
                      id="DeveloperDetails"
                      label="Developer"
                      fullWidth
                      inputProps={{
                        readOnly: status === "View",
                      }}
                      value={selectedDeveloper}
                      onChange={handleDeveloperChange}
                    >
                      {developers?.map((developer) => (
                        <MenuItem key={developer._id} value={developer.name}>
                          {developer.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="expertInvolved-label">
                      Expert Involved
                    </InputLabel>
                    <Select
                      labelId="expertInvolved-label"
                      id="expertInvolved"
                      label="Expert Involved"
                      fullWidth
                      value={selectedExpert}
                      onChange={handleExpertChange}
                      inputProps={{
                        readOnly: status === "View",
                      }}
                    >
                      {experts?.map((expert) => (
                        <MenuItem key={expert._id} value={expert._id}>
                          {expert.user.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl fullWidth>
                    <InputLabel id="nearby-label">Nearby</InputLabel>
                    <Select
                      labelId="nearby-label"
                      id="nearby"
                      name="nearby"
                      multiple
                      value={formValues.nearby}
                      inputProps={{
                        readOnly: status === "View",
                      }}
                      input={<OutlinedInput label="NearBy" />}
                      onChange={handleMultiSelectChange}
                      fullWidth
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {[
                        "parks",
                        "schools",
                        "restaurants",
                        "hospitals",
                        "mall",
                      ].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option.charAt(0).toUpperCase() + option.slice(1)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-view-label">
                      View
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-view-label"
                      id="demo-simple-select-view"
                      name="view"
                      value={formValues.view}
                      onChange={handleObjectChange}
                      label="View"
                      inputProps={{
                        readOnly: status === "View",
                      }}
                      // error={formik.touched.view && Boolean(formik.errors.view)}
                    >
                      <MenuItem value="seaFacing">Sea Facing</MenuItem>
                      <MenuItem value="cityView">City View</MenuItem>
                      <MenuItem value="gardenView">Garden View</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-kitchen-label">
                      Kitchen Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-kitchen-label"
                      id="demo-simple-select-kitchen"
                      name="kitchenType"
                      value={formValues.kitchenType}
                      onChange={handleObjectChange}
                      label="Kitchen Type"
                      inputProps={{
                        readOnly: status === "View",
                      }}
                      // error={formik.touched.kitchenType && Boolean(formik.errors.kitchenType)}
                    >
                      <MenuItem value="open">Open</MenuItem>
                      <MenuItem value="closed">Closed</MenuItem>
                      <MenuItem value="modular">Modular</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-parking-label">
                      Parking
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-parking-label"
                      id="demo-simple-select-parking"
                      name="parking"
                      value={formValues.parking}
                      onChange={handleObjectChange}
                      label="Parking"
                      inputProps={{
                        readOnly: status === "View",
                      }}
                      // error={formik.touched.parking && Boolean(formik.errors.parking)}
                    >
                      <MenuItem value="garage">Garage</MenuItem>
                      <MenuItem value="covered">Covered</MenuItem>
                      <MenuItem value="street">Street</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-flooring-label">
                      Flooring Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-flooring-label"
                      id="demo-simple-select-flooring"
                      name="flooringType"
                      value={formValues.flooringType}
                      onChange={handleObjectChange}
                      label="Flooring Type"
                      inputProps={{
                        readOnly: status === "View",
                      }}
                      // error={formik.touched.flooringType && Boolean(formik.errors.flooringType)}
                    >
                      <MenuItem value="hardwood">Hardwood</MenuItem>
                      <MenuItem value="tile">Tile</MenuItem>
                      <MenuItem value="carpet">Carpet</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-window-label">
                      Window Features
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-window-label"
                      id="demo-simple-select-window"
                      name="windowFeatures"
                      value={formValues.windowFeatures}
                      onChange={handleObjectChange}
                      label="Window Features"
                      inputProps={{
                        readOnly: status === "View",
                      }}
                      // error={formik.touched.windowFeatures && Boolean(formik.errors.windowFeatures)}
                    >
                      <MenuItem value="sdf">Shaded</MenuItem>
                      <MenuItem value="bayWindows">Bay Windows</MenuItem>
                      <MenuItem value="doubleGlazed">Double Glazed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-utility-room-label">
                      Utility Room
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-utility-room-label"
                      id="demo-simple-select-utility-room"
                      name="utilityRoom"
                      value={formValues.utilityRoom}
                      onChange={handleObjectChange}
                      label="Utility Room"
                      inputProps={{
                        readOnly: status === "View",
                      }}
                      // error={formik.touched.utilityRoom && Boolean(formik.errors.utilityRoom)}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-energy-efficiency-label">
                      Energy Efficiency Rating
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-energy-efficiency-label"
                      id="demo-simple-select-energy-efficiency"
                      name="energyEfficiencyRating"
                      value={formValues.energyEfficiencyRating}
                      onChange={handleObjectChange}
                      label="Energy Efficiency Rating"
                      inputProps={{
                        readOnly: status === "View",
                      }}
                      // error={formik.touched.energyEfficiencyRating && Boolean(formik.errors.energyEfficiencyRating)}
                    >
                      <MenuItem value="10%">1</MenuItem>
                      <MenuItem value="20%">2</MenuItem>
                      <MenuItem value="30%">3</MenuItem>
                      <MenuItem value="40%">4</MenuItem>
                      <MenuItem value="50%">5</MenuItem>
                      <MenuItem value="60%">6</MenuItem>
                      <MenuItem value="70%">7</MenuItem>
                      <MenuItem value="80%">8</MenuItem>
                      <MenuItem value="90%">9</MenuItem>
                      <MenuItem value="100%">10</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-garden-yard-label">
                      Garden/Yard
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-garden-yard-label"
                      id="demo-simple-select-garden-yard"
                      name="gardenYard"
                      value={formValues.gardenYard}
                      onChange={handleObjectChange}
                      label="Garden/Yard"
                      inputProps={{
                        readOnly: status === "View",
                      }}
                      // error={formik.touched.gardenYard && Boolean(formik.errors.gardenYard)}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {status !== "View" && (
                <Button
                  className="primary-button"
                  onClick={() => handleSubmit()}
                  style={{
                    fontSize: "14px",
                    padding: "8px 12px",
                    borderRadius: "8px",

                    "&:hover": {
                      backgroundColor: "#eeeeee",
                    },
                    margin: "20px 0 5px",
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      fontFamily: "Outfit",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    {status === "Add"
                      ? "Submit Information"
                      : "Update Information"}
                  </Typography>
                </Button>
              )}
            </form>
          </SubCard>
          {/* <Button
            className="primary-button"
            onClick={() => handleSubmit()}
            style={{
              fontSize: '14px',
              padding: '8px 12px',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#eeeeee'
              },
              margin: '10px 0 5px',
              marginLeft: 'auto' // Center to the right
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: 'Outfit',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0em',
                textAlign: 'center',
                color: 'white'
              }}
            >
              Submit Information
            </Typography>
          </Button> */}
        </Grid>
        <Grid item xs={12} lg={6}>
          <UploadMedia
            mediaItems={morePropertyInformation.propertyMedia}
            GetMediaImages={GetMediaImage}
            status={status}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MoreInformationContent;
