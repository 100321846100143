import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Paper, IconButton } from "@mui/material";
import SubCard from "ui-component/cards/SubCard";
import Carousel from "react-material-ui-carousel";
import { gridSpacing } from "store/constant";
import { toast } from "react-toastify";
import { UploadImage } from "Api/uplaodImage";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

const UploadMedia = ({ mediaItems, GetMediaImages, status }) => {
  const [media, setMedia] = useState([]);

  useEffect(() => {
    if (mediaItems && Object.keys(mediaItems).length > 0) {
      const reversedMedia = [];
      if (mediaItems.images && mediaItems.images.length > 0) {
        mediaItems.images.forEach((imageUrl) => {
          reversedMedia.push({ type: "image", url: imageUrl });
        });
      }
      if (mediaItems.videos && mediaItems.videos.length > 0) {
        mediaItems.videos.forEach((videoUrl) => {
          reversedMedia.push({ type: "video", url: videoUrl });
        });
      }
      setMedia(reversedMedia);
    }
  }, [mediaItems]);

  const handleMediaChange = async (event) => {
    const updatedMedia = [...media];

    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      const id = toast.loading(`Uploading ${file.name}...`);
      try {
        const response = await UploadImage(file);
        if (response) {
          toast.update(id, {
            render: `${file.name} uploaded successfully`,
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
          updatedMedia.push({
            type: file.type.startsWith("image/") ? "image" : "video",
            url: response.data.urlPath,
          });
        }
      } catch (error) {
        toast.update(id, {
          render: `Failed to upload ${file.name}`,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    }

    setMedia(updatedMedia);
    updatePropertyMedia(updatedMedia);
  };

  const handleDeleteMedia = (index) => {
    const updatedMedia = [...media];
    updatedMedia.splice(index, 1); // Remove the item at the specified index
    setMedia(updatedMedia);
    updatePropertyMedia(updatedMedia);
  };

  const renderMediaItem = (item, index) => {
    return (
      <Paper
        key={index}
        style={{ position: "relative", textAlign: "center", marginBottom: 10 }}
      >
        {status !== "View" && (
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              marginLeft: 400,
            }}
          >
            <CancelRoundedIcon onClick={() => handleDeleteMedia(index)} />
          </div>
        )}
        {item.type === "image" ? (
          <div style={{ position: "relative" }}>
            <img
              src={item.url}
              alt={`Image ${index}`}
              width="400"
              height="250"
            />
          </div>
        ) : (
          <div style={{ position: "relative" }}>
            <video controls width="400" height="250">
              <source src={item.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </Paper>
    );
  };

  const handleSubmit = () => {
    // Validate and submit form
  };

  const updatePropertyMedia = (updatedMedia) => {
    const propertyMedia = {
      images: updatedMedia
        .filter((item) => item.type === "image")
        .map((item) => item.url),
      videos: updatedMedia
        .filter((item) => item.type === "video")
        .map((item) => item.url),
    };

    GetMediaImages(propertyMedia);
  };

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <SubCard
          title="Property Media"
          sx={{
            fontFamily: "Outfit",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0em",
          }}
        >
          <form noValidate autoComplete="off" style={{ textAlign: "center" }}>
            <Grid item xs={12}>
              {media.length > 0 && (
                <Carousel autoPlay={false} animation="slide">
                  {media.map((item, i) => renderMediaItem(item, i))}
                </Carousel>
              )}
            </Grid>
          </form>
          <input
            accept="image/*,video/*"
            id="contained-button-files"
            multiple
            type="file"
            style={{ display: "none" }}
            onChange={handleMediaChange}
          />
          {status !== "View" && (
            <label htmlFor="contained-button-files">
              <Button
                className="primary-button"
                component="span"
                style={{
                  fontSize: "14px",
                  padding: "8px 12px",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#eeeeee",
                  },
                  margin: "0 8px",
                  justifyContent: "center",
                  backgroundColor: "#3f51b5",
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Upload Media
                </Typography>
              </Button>
            </label>
          )}
        </SubCard>
      </Grid>
    </Grid>
  );
};

export default UploadMedia;
