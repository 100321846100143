import React, { useEffect } from 'react';
import { Grid, TextField, Typography, Button } from '@mui/material';

import SubCard from 'ui-component/cards/SubCard';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { gridSpacing } from 'store/constant';
const AiEstimator = ({ aiModelInformation, onTabsValue, status }) => {
  useEffect(() => {
    const assignValues = (searchData) => {
      formik.setValues(searchData);
    };
    if (aiModelInformation) {
      assignValues(aiModelInformation);
    }
  }, [aiModelInformation]);

  const validationSchema = Yup.object().shape({
    annualRent: Yup.number().required('Annual Rent is required'),
    grossRentalYield: Yup.string().required('Gross Rental Yield is required'),
    valueAppreciation: Yup.string().required('Value Appreciation is required'),
    totalReturnPercentage: Yup.string().required('Total Return Percentage is required'),
    roi: Yup.string().required('ROI is required')
  });

  const formik = useFormik({
    initialValues: {
      annualRent: '',
      grossRentalYield: '',
      valueAppreciation: '',
      totalReturnPercentage: '',
      roi: ''
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      console.log(values);
      onTabsValue(3);
      if (status !== 'View') {
        toast.success('Information Submited');
      }
    }
  });

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <SubCard
          title="AI Estimator"
          style={{
            fontFamily: 'Outfit',
            fontSize: '24px',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0em'
          }}
        >
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Annual Rent"
                  name="annualRent"
                  value={formik.values.annualRent}
                  onChange={formik.handleChange}
                  error={formik.touched.annualRent && Boolean(formik.errors.annualRent)}
                  helperText={formik.touched.annualRent && formik.errors.annualRent}
                  InputProps={{
                    readOnly: status === 'View' // Set readOnly based on the status
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Gross Rental Yield"
                  name="grossRentalYield"
                  value={formik.values.grossRentalYield}
                  onChange={formik.handleChange}
                  error={formik.touched.grossRentalYield && Boolean(formik.errors.grossRentalYield)}
                  helperText={formik.touched.grossRentalYield && formik.errors.grossRentalYield}
                  InputProps={{
                    readOnly: status === 'View' // Set readOnly based on the status
                  }}
                />
              </Grid>
            </Grid>
            <Grid style={{ marginTop: '10px' }} container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Value Appreciation"
                  name="valueAppreciation"
                  value={formik.values.valueAppreciation}
                  onChange={formik.handleChange}
                  error={formik.touched.valueAppreciation && Boolean(formik.errors.valueAppreciation)}
                  helperText={formik.touched.valueAppreciation && formik.errors.valueAppreciation}
                  InputProps={{
                    readOnly: status === 'View' // Set readOnly based on the status
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Total Return Percentage"
                  name="totalReturnPercentage"
                  value={formik.values.totalReturnPercentage}
                  onChange={formik.handleChange}
                  error={formik.touched.totalReturnPercentage && Boolean(formik.errors.totalReturnPercentage)}
                  helperText={formik.touched.totalReturnPercentage && formik.errors.totalReturnPercentage}
                  InputProps={{
                    readOnly: status === 'View' // Set readOnly based on the status
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="ROI"
                  name="roi"
                  value={formik.values.roi}
                  onChange={formik.handleChange}
                  error={formik.touched.roi && Boolean(formik.errors.roi)}
                  helperText={formik.touched.roi && formik.errors.roi}
                  InputProps={{
                    readOnly: status === 'View' // Set readOnly based on the status
                  }}
                />
              </Grid>
            </Grid>
            {status === 'View' ? null : (
              <Button
                className="primary-button"
                type="submit"
                // onClick={() => console.log('Clicked', formValues)}
                style={{
                  fontSize: '14px',
                  padding: '8px 12px',
                  borderRadius: '8px',

                  '&:hover': {
                    backgroundColor: '#eeeeee'
                  },
                  margin: '20px 0 5px'
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontFamily: 'Outfit',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0em',
                    textAlign: 'center',
                    color: 'white'
                  }}
                >
                  Add Estimator
                </Typography>
              </Button>
            )}
          </form>
        </SubCard>
      </Grid>
    </Grid>
  );
};
export default AiEstimator;
