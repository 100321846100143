import { gql } from "@apollo/client";
export const GET_PROPERTIES = gql`
  query getAllProperties($page: Int) {
    getAllProperties(filters: { page: $page }) {
      success
      message
      totalPages
      properties {
        _id
        featuredImage
        propertyMedia {
          images
          videos
        }

        propertyName
        propertyType
        initialInvestment
        aiEstimator {
          annualRent
          grossRentalYield
          appreciation
          totalReturnPercentage
          roi
        }
        profilePivot
        predict {
          PVA {
            instance_date
            actual_worth
          }
          RVA {
            date
            annual_amount
          }
        }
        paymentPlans {
          reservation
          amount
          afterTime
        }
        propertyInformation {
          location
          price {
            min
            max
            fixPrice
          }
          handover
        }
        amenities
        nearby
        description
        developerDetails
        setSelectedPlace {
          location {
            lat
            lng
          }
          name
        }
        propertyDetails {
          bedrooms
          bathrooms
          balcony
          buildingType
          propertyAge
          area
          buildingType
          heatingCoolingSystems
          furnished
          livingRoomSize
          kitchenType
          view
          parking
          flooringType
          windowFeatures
          utilityRoom
          energyEfficiencyRating
          garden
        }
        expertAssigned {
          _id
        }
        developers
        propertyMedia {
          images
          videos
        }
      }
    }
  }
`;

export const GET_ALL_PROPERTIES = gql`
  query {
    getAllProperties(filters: { page: $page }) {
      success
      message
      totalPages
      properties {
        _id
        propertyName
        propertyInformation {
          location
          category
        }
        developerDetails
      }
    }
  }
`;

export const GET_PROPERTY = gql`
  mutation getProperty($propertyID: String!) {
    getProperty(propertyID: $propertyID) {
      success
      message
      property {
        _id
        featuredImage
        initialInvestment
        propertyType
        propertyName
        propertyMedia {
          images
          videos
        }
        aiEstimator {
          annualRent
          grossRentalYield
          appreciation
          totalReturnPercentage
          roi
        }
        profilePivot
        paymentPlans {
          reservation
          amount
          afterTime
        }
        propertyInformation {
          location
          price {
            min
            max
          }
          handover
        }
        amenities
        setSelectedPlace {
          location {
            lat
            lng
          }
          name
        }
        nearby
        description
        developerDetails
        developers
        propertyMedia {
          images
          videos
        }
      }
    }
  }
`;

export const GET_PROPERTIES_ID = gql`
  query getAllProperties($page: Int) {
    getAllProperties(page: $page) {
      success
      message
      totalPages
      properties {
        _id
        propertyName
        propertyInformation {
          location
          category
        }
      }
    }
  }
`;

export const ADD_PROPERTY = gql`
  mutation AddProperty($property: PropertyInput!) {
    addProperty(property: $property) {
      success
      message
      property {
        _id
        propertyName
      }
    }
  }
`;
export const UPDATE_PROPERTY = gql`
  mutation updateProperty(
    $propertyID: ID!
    $updateObj: UpdatePropertyInputFields!
  ) {
    updateProperty(input: { propertyID: $propertyID, updateObj: $updateObj }) {
      success
      message
      property {
        _id
        propertyName
      }
    }
  }
`;

export const DELETE_PROPERTY = gql`
  mutation deleteProperty($ID: ID!) {
    deleteProperty(ID: $ID) {
      success
      message
    }
  }
`;
