// import dashboard from './dashboard';

import users from './users';
import properties from './property';
import expert from './experts';
import bookings from './bookings';
import queries from './queries';
import setting from './setting';
import developers from './developers';

export const AdminItems = {
  items: [
    // dashboard,
    users,
    properties,
    bookings,
    expert,
    queries,
    developers,
    setting
  ]
};
export const ExpertsRoutes = {
  items: [users,properties, queries ]
};
export const ManagerItems = {
  items: [
    // dashboard,

    users,
    properties,
    bookings,
    expert,
    queries,
    developers
  ]
};
