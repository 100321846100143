import { InMemoryCache, ApolloClient, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

// Retrieve access token from localStorage
const accessToken = localStorage.getItem("token");

// Create an http link?
const httpLink = createHttpLink({
  uri: "https://staging.api.propvana.ai/api/v1/propvana",
});

// Create a middleware link to set the authorization header
const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}`,
    },
  };
});

// Initialize Apollo Client with the configured links
export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
