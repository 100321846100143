import { Typography } from '@mui/material';

export const TableHeading = ({ name }) => {
  return (
    <>
      <Typography
        variant="h2"
        sx={{
          fontFamily: 'Outfit',
          fontSize: '30px',
          fontWeight: 400,
          lineHeight: '32px',
          letterSpacing: '0em',
          margin: '20px 0'
        }}
      >
        {name}
      </Typography>
    </>
  );
};
