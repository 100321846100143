import { gql } from '@apollo/client';
export const GET_BOOKINGS = gql`
  query getAllBookings($page: Int) {
    getAllBookings(page: $page) {
      success
      message
      totalPages
      bookings {
        _id
        userId {
          _id
          name
        }

        name
        bookingDate
        status
        propertyId {
          _id
          propertyName
          developerDetails
          propertyInformation {
            location
        
          }
        }
      }
    }
  }
`;

export const ADD_BOOKING = gql`
  mutation createBooking($userId: String, $propertyId: String, $bookingDate: String) {
    createBooking(userId: $userId, propertyId: $propertyId, bookingDate: $bookingDate) {
      success
      message
      booking {
        _id
        userId
      }
    }
  }
`;

export const UPDATE_BOOKING = gql`
  mutation updateBookingStatus($bookingId: String) {
    updateBookingStatus(bookingId: $bookingId) {
      success
      message
      booking {
        _id
      }
    }
  }
`;

export const DELETE_BOOKING = gql`
  mutation deleteBooking($bookingId: String) {
    deleteBooking(bookingId: $bookingId) {
      success
      message
    }
  }
`;
