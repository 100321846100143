import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import { useLocation, useNavigate } from "react-router";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  Button,
  Grid,
  Typography,
} from "@mui/material";

// import { FetchUsers } from '_mocks/user';
import CircularLoader from "ui-component/loader/CircularLoader";
// import AddUser from '_mocks/addUser';
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { ADD_USER, UPDATE_USER } from "grapgQL/users";
import ProfileImageSection from "components/UploadProfileImage";
import { UserContext } from "context/user";
import { UploadImage } from "Api/uplaodImage";

const AddUserForm = () => {
  const location = useLocation();

  const Navigate = useNavigate();

  const [user, setUser] = useState();

  const [profileImage, setProfileImage] = useState("");

  const [loading, setLoading] = useState(true);

  const status = location?.state?.status;

  const { setUsersInfo } = useContext(UserContext);

  const theme = useTheme();

  const [addUser] = useMutation(ADD_USER, {
    onCompleted(data) {
      const { success, message } = data?.addUser;

      if (success) {
        toast.success(message);
        setUsersInfo(data?.addUser);
        Navigate("/users");
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      toast.error("Something Went Wrong!");
    },
  });

  const [updateUserInfo] = useMutation(UPDATE_USER, {
    onCompleted(data) {
      console.log(data);
      const { success, message } = data.updateUserInfo;
      if (success) {
        toast.success(message);
        Navigate("/users");
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      toast.error("Something Went Wrong!");
    },
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    whatsappNumber: Yup.string().required("Whatsapp Number is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    gender: Yup.string().required("Gender is required"),
    role: Yup.string().required("Role is required"),
    budgetToInvest: Yup.string().required("Budget To Invest is required"),
    postalAddress: Yup.object().shape({
      streetAddress: Yup.string().required("Street Address is required"),
      city: Yup.string().required("City is required"),
      countryRegion: Yup.string().required("Country/Region is required"),
      zipPostalCode: Yup.string()
        .min(5, "Zip/Postal Code must be at least 5 characters")
        .required("Zip/Postal Code is required"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      password: "",
      whatsappNumber: "",
      email: "",
      gender: "male",
      role: ["Investor"],
      budgetToInvest: ["BUDGET_1M_PLUS"],
      postalAddress: {
        streetAddress: "",
        city: "",
        countryRegion: "",
        zipPostalCode: 0,
      },
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      if (status === "Add") {
        addUser({
          variables: {
            profilePicture: profileImage,
            name: values.name,
            email: values.email,
            password: values.password,
            gender: values.gender,
            role: values.role,
            whatsappNumber: values.whatsappNumber,
            postalAddress: values.postalAddress,
            budgetToInvest: values.budgetToInvest,
          },
        });
      } else if (status === "Edit") {
        updateUserInfo({
          variables: {
            userID: user._id,
            updateObj: {
              profilePicture: profileImage,
              name: values.name,
              email: values.email,
              gender: values.gender,
              role: values.role,
              budgetToInvest: values.budgetToInvest,
              whatsappNumber: values.whatsappNumber,
              postalAddress: values.postalAddress,
            },
            // password: values.password,
            // gender: [values.gender],
            // role: values.role,
            // whatsappNumber: values.whatsappNumber,

            // budgetToInvest: [values.budgetToInvest]
          },
        });
      }
    },
  });
  const searchData = location?.state?.value;
  useEffect(() => {
    const assignValues = (searchData) => {
      if (searchData) {
        setUser(searchData);
        setProfileImage(searchData.profilePicture);
        formik.setValues({
          name: user?.name || "",
          password: user?.password || "",
          whatsappNumber: user?.whatsappNumber || "",
          email: user?.email || "",
          gender: user?.gender || "male",
          role: user?.role || ["Investor"],
          budgetToInvest: user?.budgetToInvest || "BUDGET_1M_PLUS",
          postalAddress: {
            streetAddress: user?.postalAddress?.streetAddress || "",
            city: user?.postalAddress?.city || "",
            countryRegion: user?.postalAddress?.countryRegion || "",
            zipPostalCode: user?.postalAddress?.zipPostalCode || 0,
          },
        });
      }
    };

    if (searchData) {
      assignValues(searchData);
    }
  }, [user, searchData?.profilePicture]);

  useEffect(() => {
    if (user) {
      formik.setValues({
        name: user.name || "",
        password: user.password || "",
        whatsappNumber: user.whatsappNumber || "",
        email: user.email || "",
        gender: user.gender || "male",
        role: user.role || ["Investor"],
        budgetToInvest: user.budgetToInvest || "BUDGET_1M_PLUS",
        postalAddress: {
          streetAddress: user.postalAddress?.streetAddress || "",
          city: user.postalAddress?.city || "",
          countryRegion: user.postalAddress?.countryRegion || "",
          zipPostalCode: user.postalAddress?.zipPostalCode || 0,
        },
      });
    }
  }, [user]);

  const handleImageChange = async (event) => {
    const selectedImage = event.target.files[0];
    const id = toast.loading("Uploading...");
    try {
      const response = await UploadImage(selectedImage);
      if (response) {
        toast.update(id, {
          render: "Image uploaded successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        setProfileImage(response.data.fileLocation);
      }
    } catch (error) {
      toast.update(id, {
        render: "Something went wrong. Please try again later",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div
            style={{
              background: "white",
              borderRadius: "10px",
              padding: "2rem",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Outfit",
                fontSize: "28px",
                fontWeight: 500,
                lineHeight: "32px",
                letterSpacing: "0em",
                margin: "20px 0",
              }}
            >
              User Profile
            </Typography>
            <Grid container spacing={2}>
              {/* Profile Image Section */}
              <Grid
                item
                xs={12}
                lg={4}
                style={{
                  marginBottom: theme.breakpoints.down("lg") ? "28px" : "0",
                }}
              >
                <ProfileImageSection
                  profileImage={profileImage}
                  handleImageChange={handleImageChange}
                  status={status}
                />
              </Grid>

              {/* User Details Form */}
              <Grid item xs={12} lg={8}>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="outlined-textarea"
                        label="Name"
                        placeholder="User Name"
                        value={formik.values.name || ""}
                        onChange={formik.handleChange("name")}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                        fullWidth
                        InputProps={{
                          readOnly: status === "View", // Set readOnly based on the status
                        }}
                      />
                    </Grid>

                    {status === "Add" && (
                      <Grid item xs={12} lg={6}>
                        <TextField
                          id="outlined-password-input"
                          label="Password"
                          placeholder="Password"
                          type="password"
                          value={formik.values.password || ""}
                          onChange={formik.handleChange("password")}
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                          helperText={
                            formik.touched.password && formik.errors.password
                          }
                          fullWidth
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="outlined-textarea-2"
                        label="Whatsapp Number"
                        placeholder="Whatsapp Number"
                        value={formik.values.whatsappNumber || ""}
                        onChange={formik.handleChange("whatsappNumber")}
                        error={
                          formik.touched.whatsappNumber &&
                          Boolean(formik.errors.whatsappNumber)
                        }
                        helperText={
                          formik.touched.whatsappNumber &&
                          formik.errors.whatsappNumber
                        }
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="outlined-textarea-3"
                        label="Email"
                        placeholder="Email..."
                        value={formik.values.email || ""}
                        onChange={formik.handleChange("email")}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                        fullWidth
                        InputProps={{
                          readOnly: status === "View", // Set readOnly based on the status
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="gender-label">Gender</InputLabel>
                        <Select
                          labelId="gender-label"
                          id="gender-select"
                          value={formik.values.gender}
                          label="Gender"
                          onChange={formik.handleChange("gender")}
                          error={
                            formik.touched.gender &&
                            Boolean(formik.errors.gender)
                          }
                          disabled={status === "View"}
                        >
                          <MenuItem value="male">Male</MenuItem>
                          <MenuItem value="female">Female</MenuItem>
                          <MenuItem value="not provided">Not Provided</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="role-label">Role</InputLabel>
                        <Select
                          labelId="role-label"
                          id="role-select"
                          value={formik.values.role}
                          label="Role"
                          onChange={formik.handleChange("role")}
                          error={
                            formik.touched.role && Boolean(formik.errors.role)
                          }
                          disabled={status === "View"}
                        >
                          <MenuItem value="Investor">Investor</MenuItem>
                          <MenuItem value="End User">End User</MenuItem>
                          <MenuItem value="Admin">Admin</MenuItem>
                          <MenuItem value="Real Estate Agent">
                            Real Estate Agent
                          </MenuItem>
                          <MenuItem value="Developer">Developer</MenuItem>
                          <MenuItem value="Curious">Curious</MenuItem>
                          {/* <MenuItem value="expert">Expert</MenuItem> */}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="budgetToInvest-label">
                          Budget To Invest
                        </InputLabel>
                        <Select
                          labelId="budgetToInvest-label"
                          id="budgetToInvest-select"
                          value={formik.values.budgetToInvest}
                          label="Budget To Invest"
                          onChange={formik.handleChange("budgetToInvest")}
                          error={
                            formik.touched.budgetToInvest &&
                            Boolean(formik.errors.budgetToInvest)
                          }
                          disabled={status === "View"} // Set disabled based on the status
                        >
                          <MenuItem value="BUDGET_150K_200K">
                            $150K - $200K
                          </MenuItem>
                          <MenuItem value="BUDGET_200K_350K">
                            $200K - $350K
                          </MenuItem>
                          <MenuItem value="BUDGET_350K_500K">
                            $350K - $500K
                          </MenuItem>
                          <MenuItem value="BUDGET_500K_1M">
                            $500K - $1M
                          </MenuItem>
                          <MenuItem value="BUDGET_1M_PLUS">Over $1M</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="outlined-textarea-4"
                        label="Street Address"
                        placeholder="Street Address"
                        value={formik.values.postalAddress.streetAddress || ""}
                        onChange={formik.handleChange(
                          "postalAddress.streetAddress"
                        )}
                        error={
                          formik.touched.postalAddress &&
                          formik.touched.postalAddress.streetAddress &&
                          Boolean(
                            formik.errors.postalAddress &&
                              formik.errors.postalAddress.streetAddress
                          )
                        }
                        helperText={
                          formik.touched.postalAddress &&
                          formik.touched.postalAddress.streetAddress &&
                          formik.errors.postalAddress &&
                          formik.errors.postalAddress.streetAddress
                        }
                        fullWidth
                        InputProps={{
                          readOnly: status === "View", // Set readOnly based on the status
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <TextField
                        id="outlined-textarea-5"
                        label="City"
                        placeholder="City"
                        value={formik.values.postalAddress.city || ""}
                        onChange={formik.handleChange("postalAddress.city")}
                        error={
                          formik.touched.postalAddress &&
                          formik.touched.postalAddress.city &&
                          Boolean(
                            formik.errors.postalAddress &&
                              formik.errors.postalAddress.city
                          )
                        }
                        helperText={
                          formik.touched.postalAddress &&
                          formik.touched.postalAddress.city &&
                          formik.errors.postalAddress &&
                          formik.errors.postalAddress.city
                        }
                        fullWidth
                        InputProps={{
                          readOnly: status === "View", // Set readOnly based on the status
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <TextField
                        id="outlined-textarea-6"
                        label="Country/Region"
                        placeholder="Country/Region"
                        value={formik.values.postalAddress.countryRegion || ""}
                        onChange={formik.handleChange(
                          "postalAddress.countryRegion"
                        )}
                        error={
                          formik.touched.postalAddress &&
                          formik.touched.postalAddress.countryRegion &&
                          Boolean(
                            formik.errors.postalAddress &&
                              formik.errors.postalAddress.countryRegion
                          )
                        }
                        helperText={
                          formik.touched.postalAddress &&
                          formik.touched.postalAddress.countryRegion &&
                          formik.errors.postalAddress &&
                          formik.errors.postalAddress.countryRegion
                        }
                        fullWidth
                        InputProps={{
                          readOnly: status === "View", // Set readOnly based on the status
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <TextField
                        id="outlined-number"
                        type="number"
                        label="Zip/Postal Code"
                        placeholder="Zip/Postal Code"
                        value={formik.values.postalAddress.zipPostalCode || 0}
                        onChange={formik.handleChange(
                          "postalAddress.zipPostalCode"
                        )}
                        error={
                          formik.touched.postalAddress &&
                          formik.touched.postalAddress.zipPostalCode &&
                          Boolean(
                            formik.errors.postalAddress &&
                              formik.errors.postalAddress.zipPostalCode
                          )
                        }
                        helperText={
                          formik.touched.postalAddress &&
                          formik.touched.postalAddress.zipPostalCode &&
                          formik.errors.postalAddress &&
                          formik.errors.postalAddress.zipPostalCode
                        }
                        fullWidth
                        InputProps={{
                          readOnly: status === "View", // Set readOnly based on the status
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {status !== "View" ? (
                          <Button
                            className="primary-button"
                            type="submit"
                            style={{
                              fontSize: "14px",
                              padding: "8px 12px",
                              borderRadius: "8px",
                              "&:hover": {
                                backgroundColor: "#eeeeee",
                              },
                              margin: "0 40px",
                            }}
                          >
                            {status === "Edit" ? (
                              <Typography
                                variant="h2"
                                sx={{
                                  fontFamily: "Outfit",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                  color: "white",
                                }}
                              >
                                Update User
                              </Typography>
                            ) : (
                              <Typography
                                variant="h2"
                                sx={{
                                  fontFamily: "Outfit",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                  color: "white",
                                }}
                              >
                                Add User
                              </Typography>
                            )}
                          </Button>
                        ) : null}
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              display: "flex",
              paddingTop: "1rem",
              justifyContent: "space-between",
            }}
          ></div>
        </Grid>
      </Grid>
    </>
  );
};

export default AddUserForm;
