import { Link } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';

import { Box, Button, Divider, Stack, Typography, useMediaQuery, Grid } from '@mui/material';

import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import AuthLogin from '../auth-forms/AuthLogin';

const Login = () => {
  const theme = useTheme();

  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const googleHandler = async () => {
    console.error('Login');
  };

  return (
    <AuthWrapper1>
      <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item xs={12}>
                    <Grid container direction={matchDownSM ? 'column-reverse' : 'row'} alignItems="center" justifyContent="center">
                      <Grid item>
                        <Stack alignItems="center" justifyContent="center" spacing={1}>
                          <Typography
                            className="primary"
                            gutterBottom
                            variant={matchDownSM ? 'h2' : 'h1'}
                            style={{
                              fontFamily: 'Outfit',
                              fontSize: '40px',
                              fontWeight: 500,
                              lineHeight: '48px',
                              letterSpacing: '0px',
                              textAlign: 'center'
                            }}
                          >
                            Sign In
                          </Typography>

                          <Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? 'center' : 'inherit'}>
                            Please enter your details to sign in to your account.
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <AuthLogin />
                  </Grid>

                  <Grid item xs={12}>
                    {/* <Grid item container direction="column" alignItems="center" xs={12}>
                      <Typography component={Link} to="/pages/register/register3" variant="subtitle1" sx={{ textDecoration: 'none' }}>
                        Don't have an account?{' '}
                        <Typography component="span" sx={{ color: '#FBB03B' }}>
                          Register
                        </Typography>
                      </Typography>
                    </Grid> */}
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper1>
  );
};

export default Login;
