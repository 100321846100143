import React, { useState, useEffect, useContext } from "react"; // ,{ useState }
import { gql, useLazyQuery, useMutation } from "@apollo/client";

import MUIDataTable from "mui-datatables";
import Typography from "@mui/material/Typography";

import { useNavigate } from "react-router";
import { Button, IconButton } from "@mui/material";

import CircularLoader from "ui-component/loader/CircularLoader";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import { ThemeProvider } from "@mui/material/styles";
import { mytheme } from "themes/tableHeader";

import { TableHeading } from "components/tableHeading";
import { DELETE_USER, GET_USERS } from "grapgQL/users";

import { UserContext } from "context/user";

import { toast } from "react-toastify";
import { Box } from "@mui/system";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { AuthContext } from "context/AuthContext";

const UserPage = () => {
  const { usersInfo, setUsersInfo } = useContext(UserContext);
  const { logOut } = useContext(AuthContext);

  const [relaod, setReload] = useState(false);

  // const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState([]);

  const [loading, setLoading] = useState(true);

  const [rowData, setRowData] = useState("");

  // const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [totalPages, setTotalPages] = useState(0);

  // const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(1);
  const [page, setPage] = useState(1);

  const [allusers] = useLazyQuery(GET_USERS, {
    fetchPolicy: "no-cache",
    context: { clientName: "backend" },
    onCompleted(data) {
      const { getAllUsers } = data;

      if (getAllUsers) {
        const { success, message, users, totalPages } = getAllUsers;
        if (success) {
          setLoading(false);
          console.log("response", users);
          const dataWithIndex = users?.map((data, index) => ({
            index: index + 1,
            ...data,
          }));
          setTotalRows(dataWithIndex.length);
          setTotalPages(totalPages);
          setUserData((prevData) => [...prevData, ...dataWithIndex]);
        } else {
          if (message === "Token Expired") {
            console.log("Token Expired");
            // logOut();
          }
          setLoading(false);
        }
      }
    },
    onError(error) {
      if (
        error.graphQLErrors.some(
          (err) => err.extensions.code === "UNAUTHENTICATED"
        )
      ) {
        logOut();
      }
      setLoading(false);

      console.log("Something went wrong", error);
    },
  });

  // const handleLoadMore = () => {
  //   setLoading(true);
  //   fetchMore({
  //     variables: { page: page + 1, pageSize }
  //   });
  //   setPage(page + 1);
  // };
  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted(data) {
      const { success, message } = data.deleteUser;

      if (success) {
        setPage(1);
        allusers({
          variables: {
            page: 1,
          },
        });
        toast.success(message);
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      console.log("error", error);
    },
  });
  useEffect(() => {
    if (page) {
      allusers({
        variables: {
          page: page,
        },
      });
    }
  }, [usersInfo, page]);

  const handleClick = (value, event) => {
    setRowData(value);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = (value) => {
    if (window.confirm("Are you sure you want to delete this user account?")) {
      // User clicked "OK"
      console.log(value._id); // Assuming Value is defined elsewhere in your code
      deleteUser({
        variables: {
          userID: value._id,
        },
      });
      setAnchorEl(null);
    } else {
      // User clicked "Cancel" or closed the dialog
      // Handle accordingly, if needed
    }
  };
  const handleEdit = (value) => {
    setAnchorEl(null);
    Navigate("/users/adduser", { state: { status: "Edit", value } });
  };

  const Navigate = useNavigate();

  const handlePrevPage = () => {
    if (page > 0) {
      setUserData([]);
      // setLoading(true);
      setPage(page - 1);
    }
  };
  const handlePage = (value) => {
    if (value > 0 || value <= totalPages) {
      setPage(value);
    }
  };

  const handleNextPage = () => {
    // Assuming 10 rows per page (adjust as needed)

    if (page < totalPages) {
      setUserData([]);
      // setLoading(true);
      setPage(page + 1);
    }
  };

  const handleClickOpen = () => {
    Navigate("/users/adduser", { state: { status: "Add" } });
  };

  const columns = [
    {
      name: "index",
      label: " ",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        sort: false,
        filter: false,
      },
    },

    {
      name: "whatsappNumber",
      label: "Phone No",
      options: {
        sort: false,
        filter: false,
      },
    },

    {
      name: "budgetToInvest",
      label: "Budget",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          // Assuming value is something like 'BUDGET_150K_200K'
          // Adjust this based on your actual data structure
          const budgetRanges = {
            BUDGET_150K_200K: "$150K - $200K",
            BUDGET_200K_350K: "$200K - $350K",
            BUDGET_350K_500K: "$350K - $500K",
            BUDGET_500K_1M: "$500K - $1M",
            BUDGET_OVER_1M: "Over $1M",
          };

          return budgetRanges[value] || value; // Return the custom label or the original value
        },
      },
    },

    {
      name: "email",
      label: "Email",
      options: {
        sort: false,
        filter: false,
      },
    },

    {
      name: "postalAddress",
      label: "Address",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value) => {
          const streetAddress = value?.streetAddress;
          const city = value?.city;
          const countryRegion = value?.countryRegion;
          return `${streetAddress}, ${city}, ${countryRegion}`;
        },
      },
    },
    {
      name: "name",
      // name: 'ViewProfile',
      label: " ",

      options: {
        sort: false,
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Typography
              className="details-text"
              onClick={() =>
                Navigate("/users/adduser", {
                  state: { status: "View", value: userData[dataIndex] },
                })
              }
              sx={{ cursor: "pointer", fontFamily: "Outfit" }}
            >
              View Details
            </Typography>
          );
        },
      },
    },

    {
      name: "name",
      label: " ",
      options: {
        sort: false,
        filter: false,

        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <Button
                type="button"
                onClick={(event) => handleClick(userData[dataIndex], event)}
              >
                <MoreVertIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => handleEdit(rowData)}>
                  <ModeEditOutlineOutlinedIcon /> Edit
                </MenuItem>
                <MenuItem onClick={() => handleDelete(rowData)}>
                  <DeleteOutlinedIcon /> Delete
                </MenuItem>
              </Menu>
            </>
          );
        },
      },
    },
  ];

  const HeaderElements = () => {
    return (
      <>
        <Button
          className="primary-button"
          onClick={() => handleClickOpen()}
          style={{
            fontSize: "14px",
            padding: "8px 12px",
            borderRadius: "8px",

            "&:hover": {
              backgroundColor: "#eeeeee",
            },
            margin: "0 8px",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Outfit",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0em",
              textAlign: "center",
            }}
          >
            Add User
          </Typography>
        </Button>
      </>
    );
  };
  const options = {
    responsive: "standard",
    print: false,
    filter: true,
    download: false,
    tableLayout: "fixed",
    customTableBodyWidth: "800px",
    tableBodyHeight: "500px",
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    pagination: false, // Enable pagination
    // rowsPerPage: 9, // Number of rows per page
    // rowsPerPageOptions: [9, 25],
    // customToolbar: () => (
    //   <div>
    //     <Button onClick={handlePrevPage} disabled={page === 1}>
    //       {'<'}
    //     </Button>
    //     <span>{page}</span>
    //     <Button onClick={handleNextPage} disabled={page >= 2}>
    //       {'>'}
    //     </Button>
    //   </div>
    // )
    customFooter: () => (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        height="60px"
      >
        <Typography
          variant="body1"
          sx={{
            margin: "0 4px",
            minWidth: 40,
            textAlign: "center",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontSize: "15px",
            fontWeight: 400,
            lineHeight: "20.02px",
            color: "rgba(0, 0, 0, 0.87)",
            marginRight: "20px",
          }}
        >
          1-{totalRows} of {totalRows}
        </Typography>
        <IconButton
          size="small"
          onClick={handlePrevPage}
          disabled={page === 1}
          sx={{ marginRight: 1, padding: 0.5 }}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          size="small"
          onClick={handleNextPage}
          disabled={page >= totalPages}
          sx={{ padding: 0.5, marginRight: "30px" }}
        >
          <KeyboardArrowRight />
        </IconButton>
      </Box>
    ),
    customToolbar: HeaderElements,
  };

  return (
    <>
      <TableHeading name={"Users"} />
      {loading ? (
        // <div>i am loading</div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularLoader />
        </div>
      ) : (
        <>
          {userData ? (
            <ThemeProvider theme={mytheme}>
              <MUIDataTable
                // title={'Users list'}
                data={userData}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          ) : (
            <ThemeProvider theme={mytheme}>
              <MUIDataTable
                // title={'Users list'}
                data={[]}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          )}

          {/* <ThemeProvider /> */}
        </>
      )}
    </>
  );
};

export default UserPage;
