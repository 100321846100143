import React, { useState, useEffect } from 'react';
// import {TextField,InputLabel} from '@mui/material/TextField';
// import { TextField, FormControl, InputLabel } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Button, Typography, Box } from '@mui/material';
import { UPDATE_USER_PASSWORD } from 'grapgQL/users';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

function General() {
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const userDataString = localStorage.getItem('userData');
  const authUser = userDataString ? JSON.parse(userDataString) : null;

  const [UpdateUserpassword] = useMutation(UPDATE_USER_PASSWORD, {
    context: { clientName: 'backend' },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const { success, message } = data.updateUserPassword;

      if (success) {
        toast.success(message);
      } else {
        toast.error(message);
      }
    },
    onError(err) {
      console.log('error on  updateUserPassword', err);
    }
  });
  const handleSubmit = () => {
    UpdateUserpassword({
      variables: {
        userID: authUser?._id,
        oldPassword: oldPassword,
        newPassword: newPassword
      }
    });
  };

  return (
    <>
      <Box className="propertycontainer">
        <Typography sx={{ mb: 1, fontSize: '20px' }}>Change Password</Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <InputLabel htmlFor="old-password">Enter Old Password</InputLabel>
            <TextField
              id="new-password"
              variant="outlined"
              fullWidth
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel htmlFor="new-password">Enter New Password</InputLabel>
            <TextField
              id="confirm-password"
              variant="outlined"
              fullWidth
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={8} lg={8}>
            <Button className="primary-button" onClick={handleSubmit}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default General;
