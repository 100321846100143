import React, { createContext, useState } from 'react';

// Create the context
export const ExpertsContext = createContext();

// Create the context provider

export const ExpertsProvider = ({ children }) => {
  const [expertsInfo, setExpertsInfo] = useState();

  return <ExpertsContext.Provider value={{ expertsInfo, setExpertsInfo }}>{children}</ExpertsContext.Provider>;
};
