import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
} from "@mui/material";

import UploadAccountAvatar from "ui-component/UploadIAccountAvatar";
import SubCard from "ui-component/cards/SubCard";
import { toast } from "react-toastify";
import SwitchesComponent from "components/SwitchesComponent";
import { useLocation, useNavigate } from "react-router";
import { ADD_DEVELOPER, UPDATE_DEVELOPER } from "grapgQL/developer";
import { useMutation } from "@apollo/client";
import { useContext } from "react";
import { DevelopersContext } from "context/developers";
import { useState, useEffect } from "react";

function DeveloperDetails() {
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const location = useLocation();

  const { setDevelopersInfo } = useContext(DevelopersContext);

  const [developer, setDeveloper] = useState();

  const status = location?.state?.status;

  const [developerImage, setDeveloperImage] = useState(null);

  const Navigate = useNavigate();

  const [addDeveloper] = useMutation(ADD_DEVELOPER, {
    onCompleted(data) {
      const { success, message } = data?.addDeveloper;

      if (success) {
        toast.success(message);
        setDevelopersInfo(data?.addDeveloper);
        Navigate("/developers");
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      console.log(error);
      toast.error("Something Went Wrong!");
    },
  });

  const [updateDeveloper] = useMutation(UPDATE_DEVELOPER, {
    onCompleted(data) {
      console.log(data);
      const { success, message } = data?.updateDeveloper;

      if (success) {
        toast.success(message);
        Navigate("/developers");
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      console.log(error);
      toast.error("Something Went Wrong!");
    },
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      description: "",
      businessName: "",
      officeAddress: "",
      phoneNumber: "",
      website: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),

      businessName: Yup.string().required("Business Name is required"),
      officeAddress: Yup.string().required("Office Address is required"),
      phoneNumber: Yup.number()
        .typeError("Phone Number must be a number")
        .required("Phone Number is required"),
      website: Yup.string().required("Website is required"),
    }),
    onSubmit: (values) => {
      // Handle the save logic here using the form values
      console.log("Form Values:", values.website);
      let website;
      if (values.website) {
        if (
          !values.website.startsWith("http://") &&
          !values.website.startsWith("https://")
        ) {
          // If not, add "https://"
          website = "https://" + values.website;
        } else {
          website = values.website;
        }
      } else {
        website = ""; // or handle the undefined/null case as needed
      }

      console.log("Website:", website);
      if (status === "Add") {
        addDeveloper({
          variables: {
            images: [developerImage],
            name: values.name,
            email: values.email,
            address: values.address,
            businessName: values.businessName,
            officeAddress: values.officeAddress,
            phoneNumber: values.phoneNumber,
            website: website,
            description: values.description,
          },
        });
      } else if (status === "Edit") {
        updateDeveloper({
          variables: {
            images: [developerImage],
            id: developer._id,
            name: values.name,
            email: values.email,
            address: values.address,
            businessName: values.businessName,
            officeAddress: values.officeAddress,
            phoneNumber: values.phoneNumber,
            website: website,
            description: values.description,
          },
        });
      }
    },
  });
  const searchData = location?.state?.value;
  useEffect(() => {
    const assignValues = (searchData) => {
      if (searchData) {
        console.log(searchData);
        setDeveloper(searchData);
        setDeveloperImage(searchData?.images[0]);
        formik.setValues({
          name: searchData?.name,
          email: searchData?.email,
          description: searchData?.description,
          businessName: searchData?.businessName,
          officeAddress: searchData?.officeAddress,
          phoneNumber: searchData?.phoneNumber,
          website: searchData?.website,
        });
      }
    };

    if (searchData) {
      assignValues(searchData);
    }
  }, [developer, searchData]);

  const handleImageChange = (image) => {
    setDeveloperImage(image);
  };

  return (
    <>
      <Typography variant="h2" gutterBottom style={{ marginBottom: "20px" }}>
        Developer Details
      </Typography>
      <Grid container spacing={2}>
        {/* Profile Avatar Section */}
        <Grid item xs={12} lg={3}>
          <SubCard
            title="Profile Avatar"
            style={{ height: isMdScreen ? "420px" : "400px" }}
          >
            <UploadAccountAvatar
              GetProfilePic={handleImageChange}
              status={status}
              profileImg={developerImage}
            />
          </SubCard>
        </Grid>

        {/* Card Section */}
        <Grid item xs={12} lg={9}>
          <SubCard
            title="Developer Details"
            style={{ height: isMdScreen ? "420px" : "400px" }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="name"
                    label="Name"
                    placeholder="Name"
                    variant="outlined"
                    fullWidth
                    {...formik.getFieldProps("name")}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    InputProps={{
                      readOnly: status === "View", // Set readOnly based on the status
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="email"
                    label="Email"
                    placeholder="Email"
                    variant="outlined"
                    fullWidth
                    {...formik.getFieldProps("email")}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    InputProps={{
                      readOnly: status === "View", // Set readOnly based on the status
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="businessName"
                    label="Business Name"
                    placeholder="Business Name"
                    variant="outlined"
                    fullWidth
                    {...formik.getFieldProps("businessName")}
                    error={
                      formik.touched.businessName &&
                      Boolean(formik.errors.businessName)
                    }
                    helperText={
                      formik.touched.businessName && formik.errors.businessName
                    }
                    InputProps={{
                      readOnly: status === "View", // Set readOnly based on the status
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="officeAddress"
                    label="Office Address"
                    placeholder="Office Address"
                    variant="outlined"
                    fullWidth
                    {...formik.getFieldProps("officeAddress")}
                    error={
                      formik.touched.officeAddress &&
                      Boolean(formik.errors.officeAddress)
                    }
                    helperText={
                      formik.touched.officeAddress &&
                      formik.errors.officeAddress
                    }
                    InputProps={{
                      readOnly: status === "View", // Set readOnly based on the status
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="phoneNumber"
                    label="Phone Number"
                    placeholder="Phone Number"
                    variant="outlined"
                    fullWidth
                    {...formik.getFieldProps("phoneNumber")}
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                    InputProps={{
                      readOnly: status === "View", // Set readOnly based on the status
                    }}
                  />
                </Grid>{" "}
                <Grid item xs={12} md={6}>
                  <TextField
                    id="website"
                    label="Website"
                    placeholder="Website"
                    variant="outlined"
                    fullWidth
                    {...formik.getFieldProps("website")}
                    error={
                      formik.touched.website && Boolean(formik.errors.website)
                    }
                    helperText={formik.touched.website && formik.errors.website}
                    InputProps={{
                      readOnly: status === "View", // Set readOnly based on the status
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="description"
                    label="Description"
                    placeholder="Description"
                    variant="outlined"
                    fullWidth
                    {...formik.getFieldProps("description")}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                    InputProps={{
                      readOnly: status === "View", // Set readOnly based on the status
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  {status === "Add" ? (
                    <Button
                      type="submit"
                      className="primary-button"
                      style={{
                        fontSize: "14px",
                        padding: "6px",
                        borderRadius: "5px",
                      }}
                    >
                      Submit
                    </Button>
                  ) : status === "Edit" ? (
                    <Button
                      type="submit"
                      className="primary-button"
                      style={{
                        fontSize: "14px",
                        padding: "6px",
                        borderRadius: "5px",
                      }}
                    >
                      Update
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </form>
          </SubCard>
        </Grid>
      </Grid>
    </>
  );
}

export default DeveloperDetails;
