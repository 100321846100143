import { IconUser } from '@tabler/icons';

// constant
const icons = {
  IconUser
};
const developers = {
  id: 'developers',
  type: 'group',
  children: [
    {
      id: 'developers',
      title: 'Developers',
      type: 'item',
      url: '/developers',
      icon: icons.IconUser,
      // icon: AccountCircleOutlinedIcon,
      breadcrumbs: false
    }
  ]
};

export default developers;
