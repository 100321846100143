import React from 'react';
import { Grid, TextField, Typography, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
// import MuiTypography from '@mui/material/Typography';
import SubCard from 'ui-component/cards/SubCard';
// import ReactPlayer from 'react-player/lazy';
import { gridSpacing } from 'store/constant';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import UploadFeaturePic from 'ui-component/UploadFeaturePic';

import { GET_ALL_PROPERTIES, GET_PROPERTIES_ID } from 'grapgQL/property';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { GET_ALL_USERS, GET_USERS_ID } from 'grapgQL/users';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { ADD_BOOKING, UPDATE_BOOKING } from 'grapgQL/booking';
import { useLocation, useNavigate } from 'react-router';
import { BookingContext } from 'context/Booking';
import { useContext } from 'react';

const BookingForm = () => {
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedProperty, setSelectedProperty] = useState('');
  const [category, setCategory] = useState('');
  const [propertyLocation, setPropertyLocation] = useState('');
  const [bookingDate, setBookingDate] = useState(dayjs('2023-02-11'));
  const [bookingStatus, setBookingStatus] = useState('');
  const [propertyDeveloper, setPropertyDeveloper] = useState('');
  const { setBookingInfo } = useContext(BookingContext);
  const Navigate = useNavigate();
  const location = useLocation();

  const status = location?.state?.status;

  const [properties, setProperties] = useState();
  const [users, setUsers] = useState();

  const [getAllUsers] = useLazyQuery(GET_ALL_USERS, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'backend' },
    onCompleted(data) {
      const { getAllUsers } = data;

      if (getAllUsers) {
        const { success, message, users } = getAllUsers;
        if (success) {
          setUsers(users);
        }
      }
    },
    onError(error) {
      console.log('Something went wrong', error);
    }
  });
  const [getAllProperties] = useLazyQuery(GET_ALL_PROPERTIES, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'backend' },
    onCompleted(data) {
      const { getAllProperties } = data;

      if (getAllProperties) {
        const { success, message, properties } = getAllProperties;
        if (success) {
          setProperties(properties);
        }
      }
    },
    onError(error) {
      console.log('Something went wrong', error);
    }
  });

  const [addBooking] = useMutation(ADD_BOOKING, {
    onCompleted(data) {
      const { success, message, booking } = data?.createBooking;

      if (success) {
        toast.success(message);
        setBookingInfo(booking);
        Navigate('/bookings');
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      console.log(error);
      toast.error('Something Went Wrong!');
    }
  });

  const [updateBookingStatus] = useMutation(UPDATE_BOOKING, {
    onCompleted(data) {
      const { success, message } = data.updateBookingStatus;
      if (success) {
        toast.success(message);
        Navigate('/bookings');
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      toast.error('Something Went Wrong!');
    }
  });

  useEffect(() => {
    const assignValues = (searchData) => {
      if (properties) {
        const filteredProperty = properties?.filter((property) => searchData?.propertyId?._id === property?._id);
        const filteredUser = users?.filter((user) => searchData?.userId?._id === user?._id);
        setBookingDate(dayjs(searchData.bookingDate));
        setSelectedProperty(filteredProperty);
        setSelectedUser(filteredUser);
        setBookingStatus(searchData.status);
      }
    };
    const searchData = location?.state?.value;
    console.log(searchData);
    if (searchData) {
      assignValues(searchData);
    }
  }, [location, properties]);

  useEffect(() => {
    getAllUsers();
    getAllProperties();
  }, []);

  useEffect(() => {
    if (selectedProperty.length > 0) {
      setCategory(selectedProperty[0]?.propertyInformation?.category);
      setPropertyDeveloper(selectedProperty[0]?.developerDetails);
      setPropertyLocation(selectedProperty[0]?.propertyInformation?.location);
    }
  }, [selectedProperty, location, category]);

  const handleDateChange = (newValue) => {
    setBookingDate(newValue);
  };
  const handleUserChange = (event) => {
    const filteredUser = users?.filter((user) => event.target.value === user?._id);

    setSelectedUser(filteredUser);
  };
  const handlePropertyChange = (event) => {
    const filteredProperty = properties?.filter((property) => event.target.value === property?._id);
    setSelectedProperty(filteredProperty);
  };
  const handleStatusChange = (event) => {
    setBookingStatus(event.target.value);
  };
  const handleSubmit = () => {
    if (selectedUser && selectedProperty && bookingDate && bookingStatus) {
      const formattedHandoverDate = dayjs(bookingDate).format('YYYY-MM-DD');
      //  console.log(selectedUser[0]._id, selectedProperty[0]._id, bookingStatus, formattedHandoverDate, bookingDate);
      if (status === 'Add') {
        addBooking({
          variables: {
            userId: selectedUser[0]?._id,
            propertyId: selectedProperty[0]?._id,
            bookingDate: formattedHandoverDate
            // status: bookingStatus
          }
        });
      } else if (status === 'Edit') {
        updateBookingStatus({
          variables: {
            bookingId: location?.state?.value?._id
          }
        });
      }
    } else toast.error('Please Fill The Required Fields');
  };

  return (
    <>
      <Typography variant="h2" gutterBottom style={{ marginBottom: '20px' }}>
        Booking Form
      </Typography>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} lg={4}>
          <SubCard title="Feature Picture" style={{ height: '425px' }}>
            <UploadFeaturePic />
          </SubCard>
        </Grid>

        <Grid item xs={12} lg={8}>
          <SubCard
            title="Booking Informamtion"
            style={{
              fontFamily: 'Outfit',
              fontSize: '20px',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '0em'
            }}
          >
            <Grid container spacing={2}>
              {/* <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    InputProps={{
                      readOnly: status === 'View' // Set readOnly based on the status
                    }}
                  />
                </Grid> */}
              {users?.length > 0 && (selectedUser?.length > 0 || status === 'Add') && (
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="user-select-label">Select User</InputLabel>
                    <Select
                      label="Select User"
                      labelId="user-select-label"
                      id="user-select"
                      name="Select User"
                      value={selectedUser[0]?._id}
                      defaultValue={users[0]?._id}
                      readOnly={status !== 'Add'}
                      onChange={handleUserChange} // Set a default value if needed
                    >
                      {users?.map((user) => (
                        <MenuItem key={user?._id} value={user?._id}>
                          {user?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {properties?.length > 0 && (selectedProperty?.length > 0 || status === 'Add') && (
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="property-select-label">Select Property</InputLabel>
                    <Select
                      label="Select Property"
                      labelId="property-select-label"
                      id="property-select"
                      name="Select Property"
                      defaultValue={properties[0]?._id} // Set a default value if needed
                      value={selectedProperty[0]?._id}
                      onChange={handlePropertyChange}
                      readOnly={status !== 'Add'}
                    >
                      {properties?.map((property) => (
                        <MenuItem key={property?._id} value={property?._id}>
                          {property?.propertyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Location"
                  name="location"
                  value={propertyLocation}
                  // onChange={formik.handleChange}
                  // error={formik.touched.location && Boolean(formik.errors.location)}
                  // helperText={formik.touched.location && formik.errors.location}
                  InputProps={{
                    readOnly: status !== 'Add' // Set readOnly based on the status
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    labelId="status-label"
                    id="status"
                    name="status"
                    value={bookingStatus}
                    onChange={handleStatusChange}
                    label="Status"
                    readOnly={status !== 'Add'}
                  >
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="Confirmed">Confirmed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Catrgory"
                  name="catrgory"
                  value={category}
                  // onChange={formik.handleChange}
                  InputProps={{
                    readOnly: status !== 'Add' // Set readOnly based on the status
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div>
                    <DatePicker
                      id="handoverDate"
                      name="handoverDate"
                      format="DD/MM/YYYY"
                      defaultValue={dayjs('2023-01-01')}
                      value={bookingDate}
                      onChange={(newValue) => handleDateChange(newValue)}
                    />
                  </div>
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Property Developer"
                  name="propertyDeveloper"
                  value={propertyDeveloper}
                  // onChange={formik.handleChange}

                  InputProps={{
                    readOnly: status !== 'Add' // Set readOnly based on the status
                  }}
                />
              </Grid>
            </Grid>

            {status === 'View' ? null : (
              <Button
                className="primary-button"
                onClick={() => handleSubmit()}
                style={{
                  fontSize: '14px',
                  padding: '8px 12px',
                  borderRadius: '8px',

                  '&:hover': {
                    backgroundColor: '#eeeeee'
                  },
                  margin: '20px 0 5px'
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontFamily: 'Outfit',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0em',
                    textAlign: 'center',
                    color: 'white'
                  }}
                >
                  {status === 'Add' ? 'Add' : 'Update Status'}
                </Typography>
              </Button>
            )}
          </SubCard>
        </Grid>
      </Grid>
    </>
  );
};

export default BookingForm;
