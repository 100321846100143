import { useState} from 'react';
// import { useTheme } from '@mui/material/styles';
import {
  Drawer,
  // Fab,
  // IconButton,
  // Tooltip,
  
} from '@mui/material';
// import { IconSettings } from '@tabler/icons';
// import AnimateButton from 'ui-component/extended/AnimateButton';


const Customization = () => {
  // const theme = useTheme();
  // const dispatch = useDispatch();
  // const customization = useSelector((state) => state.customization);

  // drawer on/off
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  // state - border radius
  // const [borderRadius, setBorderRadius] = useState(customization.borderRadius);
  // const handleBorderRadius = (event, newValue) => {
  //   setBorderRadius(newValue);
  // };

  // useEffect(() => {
  //   dispatch({ type: SET_BORDER_RADIUS, borderRadius });
  // }, [dispatch, borderRadius]);

  // let initialFont;
  // switch (customization.fontFamily) {
  //   case `'Inter', sans-serif`:
  //     initialFont = 'Inter';
  //     break;
  //   case `'Poppins', sans-serif`:
  //     initialFont = 'Poppins';
  //     break;
  //   case `'Roboto', sans-serif`:
  //   default:
  //     initialFont = 'Roboto';
  //     break;
  // }

  // state - font family
  // const [fontFamily, setFontFamily] = useState(initialFont);
  // useEffect(() => {
  //   let newFont;
  //   switch (fontFamily) {
  //     case 'Inter':
  //       newFont = `'Inter', sans-serif`;
  //       break;
  //     case 'Poppins':
  //       newFont = `'Poppins', sans-serif`;
  //       break;
  //     case 'Roboto':
  //     default:
  //       newFont = `'Roboto', sans-serif`;
  //       break;
  //   }
  //   dispatch({ type: SET_FONT_FAMILY, fontFamily: newFont });
  // }, [dispatch, fontFamily]);

  return (
    <>
      {/* toggle button */}
      {/* <Tooltip title="Live Customize">
        <Fab
          component="div"
          onClick={handleToggle}
          size="medium"
          variant="circular"
          color="secondary"
          sx={{
            borderRadius: 0,
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '4px',
            top: '25%',
            position: 'fixed',
            right: 10,
            zIndex: theme.zIndex.speedDial
          }}
        >
          <AnimateButton type="rotate">
            <IconButton color="inherit" size="large" disableRipple>
              <IconSettings />
            </IconButton>
          </AnimateButton>
        </Fab>
      </Tooltip> */}

      <Drawer
        anchor="right"
        onClose={handleToggle}
        open={open}
        PaperProps={{
          sx: {
            width: 280
          }
        }}
      >
   
      </Drawer>
    </>
  );
};

export default Customization;
