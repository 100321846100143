import React, { useState, useEffect } from "react"; // ,{ useState }

import MUIDataTable from "mui-datatables";
import Typography from "@mui/material/Typography";
// import { userData } from 'apis/userData';

// import { createTheme } from '@mui/material/styles';
import { useNavigate } from "react-router";
// import { ThemeProvider } from '@emotion/react';
import { Button, IconButton } from "@mui/material";

import CircularLoader from "ui-component/loader/CircularLoader";

import { createTheme, ThemeProvider } from "@mui/material/styles";
// import AddUser from '_mocks/addUser';
import { mytheme } from "themes/tableHeader";
import { TableHeading } from "components/tableHeading";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DELETE_QUERY, GET_QUERIES, UPDATE_QUERY } from "grapgQL/queries";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { AuthContext } from "context/AuthContext";
import { GET_USER } from "grapgQL/users";
import { GET_PROPERTY } from "grapgQL/property";
import { GET_EXPERT } from "grapgQL/experts";
import { useContext } from "react";

const QueriesPage = () => {
  const { logOut } = useContext(AuthContext);

  const [queryData, setQueryData] = useState([]);

  const [loading, setLoading] = useState(true);

  const [rowData, setRowData] = useState("");
  const [totalPages, setTotalPages] = useState(0);

  const [totalRows, setTotalRows] = useState(1);

  const [page, setPage] = useState(1);
  // const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const userDataString = localStorage.getItem("userData");

  const userData = userDataString ? JSON.parse(userDataString) : null;

  const open = Boolean(anchorEl);

  const [getAllQueries] = useLazyQuery(GET_QUERIES, {
    fetchPolicy: "no-cache",
    context: { clientName: "backend" },
    onCompleted(data) {
      const { getAllQueries } = data;

      if (getAllQueries) {
        const { success, message, queries, totalPages } = getAllQueries;
        if (success) {
          setLoading(false);

          const dataWithIndex = queries.map((data, index) => ({
            index: index + 1,
            ...data,
          }));
          if (userData?.role == "expert") {
            const filteredQueries = queries.filter(
              (query) => query.expertInvolved.user.name === userData.name
            );

            setQueryData((prevData) => [...prevData, ...filteredQueries]);
          } else {
            setQueryData((prevData) => [...prevData, ...dataWithIndex]);
          }

          setTotalRows(dataWithIndex.length);
          setTotalPages(totalPages);
        } else {
          if (message === "Token Expired") {
            logOut();
          }
          setLoading(false);
        }
      }
    },
    onError(error) {
      console.log("Something went wrong", error);
    },
  });
  const [getUser] = useMutation(GET_USER, {
    onCompleted(data) {
      const { success, message, user } = data?.getUser;

      if (success) {
        Navigate("/users/adduser", { state: { status: "View", value: user } });
      } else {
        toast.error(message);
      }
    },
    onError(error) {
      console.error("An error occurred during the mutation:", error.message);
      toast.error("Something Went Wrong!");
      // You can handle errors here, log them, or perform any other actions
    },
  });

  const [getProperty] = useMutation(GET_PROPERTY, {
    onCompleted(data) {
      const { success, message, property } = data?.getProperty;

      if (success) {
        Navigate("/properties/propertydetails", {
          state: { status: "View", value: property },
        });
      } else {
        toast.error(message);
      }
    },
    onError(error) {
      console.error("An error occurred during the mutation:", error.message);
      toast.error("Something Went Wrong!");
      // You can handle errors here, log them, or perform any other actions
    },
  });

  const [getExpert] = useMutation(GET_EXPERT, {
    onCompleted(data) {
      const { success, message, expert } = data?.getExpert;

      if (success) {
        Navigate("/experts/expertsdetails", {
          state: { status: "View", value: expert },
        });
      } else {
        toast.error(message);
      }
    },
    onError(error) {
      console.error("An error occurred during the mutation:", error);
      toast.error("Something Went Wrong!");
      // You can handle errors here, log them, or perform any other actions
    },
  });

  const [deleteQuery] = useMutation(DELETE_QUERY, {
    onCompleted(data) {
      const { success, message } = data.deleteQuery;

      if (success) {
        setPage(1);
        setLoading(true);
        setQueryData([]);
        getAllQueries();
        toast.success(message);
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      console.log("error", error);
    },
  });

  const [updateQuery] = useMutation(UPDATE_QUERY, {
    onCompleted(data) {
      const { success, message } = data.updateQueryStatus;

      if (success) {
        setPage(1);
        setLoading(true);
        setQueryData([]);
        getAllQueries();
        toast.success(message);
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      console.log("error", error);
    },
  });

  const handlePrevPage = () => {
    if (page > 0) {
      setQueryData([]);
      // setLoading(true);
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    // Assuming 10 rows per page (adjust as needed)

    if (page < totalPages) {
      setQueryData([]);
      // setLoading(true);
      setPage(page + 1);
    }
  };

  // };
  const handleDelete = (value) => {
    if (window.confirm("Are you sure you want to delete this user account?")) {
      // User clicked "OK"

      deleteQuery({
        variables: {
          queryID: value._id,
        },
      });
      setAnchorEl(null);
    } else {
      // User clicked "Cancel" or closed the dialog
      // Handle accordingly, if needed
    }
  };
  const handleEdit = (value) => {
    if (window.confirm("Are you sure you want to Update the Status of Query")) {
      // User clicked "OK"

      updateQuery({
        variables: {
          queryID: value._id,
        },
      });
      setAnchorEl(null);
    } else {
      // User clicked "Cancel" or closed the dialog
      // Handle accordingly, if needed
    }
  };
  // const handleEdit = (value) => {
  //   setAnchorEl(null);
  //   Navigate('/queries/queriesdetails', { state: { isEdit: true, value } });
  // };
  // const handleView = (value, event) => {
  //   console.log(value);
  //   // Navigate('/properties/propertydetails', { state: { status: 'View', value } });
  // };

  useEffect(() => {
    if (page) {
      getAllQueries({
        variables: {
          page: page,
        },
      });
    }
  }, [page]);

  const Navigate = useNavigate();
  const handleClickOpen = () => {
    Navigate("/queries/queriesdetails", { state: { status: "Add" } });
  };

  const columns = [
    {
      name: "index",
      label: " ",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: "user",
      label: "User Name",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => getUser({ variables: { userID: value?._id } })}
            >
              {value?.name}
            </span>
          );
        },
      },
    },

    {
      name: "property",
      label: "Property",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() =>
                getProperty({ variables: { propertyID: value?._id } })
              }
            >
              {value?.propertyName}
            </span>
          );
        },
      },
    },

    {
      name: "expertInvolved",
      label: "Expert Involved",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => getExpert({ variables: { expertID: value?._id } })}
            >
              {value?.user.name}
            </span>
          );
        },
      },
    },

    {
      name: "status",
      // name: 'Profile',
      label: "Status",

      options: {
        sort: false,
        filter: false,
        customBodyRender: (value) => {
          let statusStyle = {
            padding: "6px 4px",
            width: "100px",
            borderRadius: "4px",
            textAlign: "center",
          };

          if (value === "Resolved") {
            statusStyle.background = "#00BA611A";
            statusStyle.color = "#00BA61";
          } else if (value === "InProgress") {
            statusStyle.background = "#D9B91A1A";
            statusStyle.color = "#D9B91A";
          } else if (value === "Pending") {
            statusStyle.background = "#1A73E8";
            statusStyle.color = "white";
          }

          return <div style={statusStyle}>{value.toUpperCase()}</div>;
        },
      },
    },
    {
      name: "username",
      // name: 'Profile',
      label: " ",

      options: {
        sort: false,
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Typography
              className="details-text"
              onClick={(event) => handleDelete(queryData[dataIndex], event)}
              sx={{ cursor: "pointer", fontFamily: "Outfit" }}
            >
              Delete Query
            </Typography>
          );
        },
      },
    },
    // Adding a comma here

    {
      name: "username",
      label: " ",
      options: {
        sort: false,
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          return userData?.role == "expert" ? (
            <Typography
              className="details-text"
              onClick={(event) => handleEdit(queryData[dataIndex], event)}
              sx={{ cursor: "pointer", fontFamily: "Outfit" }}
            >
              Update Status
            </Typography>
          ) : null;
        },
      },
    },

    // {
    //   name: 'username',
    //   label: ' ',
    //   options: {
    //     sort: false,
    //     filter: false,

    //     customBodyRenderLite: (dataIndex) => {
    //       return (
    //         <>
    //           <Button type="button" onClick={(event) => handleClick(queryData[dataIndex], event)}>
    //             <MoreVertIcon />
    //           </Button>
    //           <Menu
    //             id="basic-menu"
    //             anchorEl={anchorEl}
    //             open={open}
    //             onClose={handleClose}
    //             MenuListProps={{
    //               'aria-labelledby': 'basic-button'
    //             }}
    //           >
    //             <MenuItem onClick={() => handleEdit(rowData)}>
    //               <ModeEditOutlineOutlinedIcon /> Edit
    //             </MenuItem>
    //             <MenuItem onClick={handleDelete}>
    //               <DeleteOutlinedIcon /> Delete
    //             </MenuItem>
    //           </Menu>
    //         </>
    //       );
    //     }
    //   }
    // }
  ];

  const HeaderElements = () => {
    return (
      <>
        {/* <Button type="button" onClick={() => handleClickOpen()}>
          + Add User
        </Button> */}

        <Button
          className="primary-button"
          onClick={() => handleClickOpen()}
          style={{
            fontSize: "14px",
            padding: "8px 12px",
            borderRadius: "8px",

            "&:hover": {
              backgroundColor: "#eeeeee",
            },
            margin: "0 8px",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Outfit",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0em",
              textAlign: "center",
            }}
          >
            Add Query
          </Typography>
        </Button>
      </>
    );
  };

  const options = {
    responsive: "standard",
    print: false,
    download: false,
    tableLayout: "fixed",
    customTableBodyWidth: "800px",
    tableBodyHeight: "500px",
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    // customToolbar: HeaderElements,
    customFooter: () => (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        height="60px"
      >
        <Typography
          variant="body1"
          sx={{
            margin: "0 4px",
            minWidth: 40,
            textAlign: "center",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontSize: "15px",
            fontWeight: 400,
            lineHeight: "20.02px",
            color: "rgba(0, 0, 0, 0.87)",
            marginRight: "20px",
          }}
        >
          1-{totalRows} of {totalRows}
        </Typography>
        <IconButton
          size="small"
          onClick={handlePrevPage}
          disabled={page === 1}
          sx={{ marginRight: 1, padding: 0.5 }}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          size="small"
          onClick={handleNextPage}
          disabled={page >= totalPages}
          sx={{ padding: 0.5, marginRight: "30px" }}
        >
          <KeyboardArrowRight />
        </IconButton>
      </Box>
    ),
  };

  return (
    <>
      <TableHeading name={"Queries"} />
      {loading ? (
        // <div>i am loading</div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularLoader />
        </div>
      ) : (
        <>
          {queryData ? (
            <ThemeProvider theme={mytheme}>
              <MUIDataTable
                // title={'Users list'}
                data={queryData}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          ) : (
            <ThemeProvider theme={mytheme}>
              <MUIDataTable
                // title={'Users list'}
                data={[]}
                columns={columns}
                options={options}
              />{" "}
            </ThemeProvider>
          )}

          {/* <ThemeProvider /> */}
        </>
      )}
    </>
  );
};

export default QueriesPage;
