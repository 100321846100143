import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

import AnimateButton from "ui-component/extended/AnimateButton";

// assets
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { SIGNIN } from "grapgQL/signin";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { useContext } from "react";
import { AuthContext } from "context/AuthContext";

const FirebaseLogin = () => {
  const { setAuthUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [signIn] = useMutation(SIGNIN, {
    onCompleted(data) {
      const { success, message, user } = data?.signIn;
      setLoading(false);

      if (success) {
        toast.success(message);
        console.log(user);
        localStorage.setItem("userData", JSON.stringify(user));
        localStorage.setItem("token", user?.accessToken);

        setAuthUser(user);
      } else {
        setLoading(false);
        if (message === "No User FOund") {
          toast.error("User Not Found");
        } else {
          toast.error(message);
        }
      }
    },

    onError(error) {
      toast.error("Something Went Wrong!", error);
      setLoading(false);
    },
  });
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid item xs={12}></Grid>
      </Grid>

      <Formik
        initialValues={{
          email: "",
          // email: 'info@codedthemes.com',
          password: "",
          // password: '123456',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={(values) => {
          setLoading(true);
          signIn({
            variables: {
              email: values.email,
              password: values.password,
            },
          });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div>
              <Typography
                variant="body1"
                style={{
                  marginBottom: "10px",
                  marginLeft: "5px",
                  fontWeight: "bold",
                }}
              >
                Enter Email
              </Typography>
              <FormControl
                fullWidth
                error={Boolean(touched.email && errors.email)}
              >
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Email Address / Username
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-email-login"
                  type="email"
                  value={values.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Email Address / Username"
                  inputProps={{}}
                />
                {touched.email && errors.email && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-email-login"
                  >
                    {errors.email}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div>
              <Typography
                variant="body1"
                style={{
                  margin: "10px 0",
                  marginLeft: "5px",
                  fontWeight: "bold",
                }}
              >
                Enter Password
              </Typography>
              <FormControl
                fullWidth
                error={Boolean(touched.password && errors.password)}
              >
                <InputLabel htmlFor="outlined-adornment-password-login">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password-login"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  inputProps={{}}
                />
                {touched.password && errors.password && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-password-login"
                  >
                    {errors.password}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(event) => setChecked(event.target.checked)}
                    name="checked"
                    color="warning"
                    sx={{ color: "#FBB03B" }}
                  />
                }
                label="Remember me"
              />

              {/* <Typography
                variant="subtitle1"
                sx={{
                  color: "#FBB03B",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Forgot Password?
              </Typography> */}
            </Stack>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            {!loading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <AnimateButton>
                  <Button
                    disableElevation
                    size="large"
                    type="submit"
                    variant="contained"
                    className="primary-button"
                    style={{ color: "white" }}
                  >
                    Sign in
                  </Button>
                </AnimateButton>
              </Box>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}

            <Grid item xs={12}>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                }}
              ></Box>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default FirebaseLogin;
