import { IconUser, IconBuildingEstate } from '@tabler/icons';

// constant
const icons = {
  IconUser,
  IconBuildingEstate
};
const properties = {
  id: 'properties',
  type: 'group',
  children: [
    {
      id: 'properties',
      //   id: 'users',
      title: 'Properties',
      type: 'item',
      url: '/properties',
      icon: icons.IconBuildingEstate,
      // icon: AccountCircleOutlinedIcon,
      breadcrumbs: false
    }
  ]
};

export default properties;
