import React, { useState } from "react";
import { Button, Paper, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { UploadImage } from "Api/uplaodImage";
import { useEffect } from "react";

const UploadFeaturePic = ({ GetFeaturePic, image, status }) => {
  const [featureImage, setFeatureImage] = useState();

  useEffect(() => {
    setFeatureImage(image);
  }, [image]);
  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    const id = toast.loading("Uploading...");
    try {
      const response = await UploadImage(file);
      if (response) {
        toast.update(id, {
          render: "Image uploaded successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        setFeatureImage(response.data.urlPath);

        GetFeaturePic(response.data.urlPath);
      }
    } catch (error) {
      toast.update(id, {
        render: "Something went wrong. Please try again later",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const clearFeatureImage = () => {};

  return (
    <div
      style={{
        width: "100%",
        height: "300px", // Set the height to 100% of the viewport height
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "100%", maxWidth: "400px" }}>
        {/* Feature Image Section */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <br />
          {featureImage && (
            <Paper
              elevation={3}
              style={{
                width: "350px", // Set the desired width
                height: "250px", // Set the desired height
                objectFit: "cover",
                borderRadius: "8px",
                margin: "0 auto 10px", // Center the Paper horizontally
                overflow: "hidden",
              }}
            >
              <img
                src={featureImage}
                alt="Feature"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Paper>
          )}
          {status !== "View" && (
            <>
              <Typography
                variant="caption"
                sx={{ textAlign: "center", fontSize: "13px" }}
              >
                {featureImage
                  ? "Update Your Feature Image"
                  : "Upload Your Feature Image"}
              </Typography>

              <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />

              <label htmlFor="contained-button-file">
                <Button
                  className="primary-button"
                  component="span"
                  style={{
                    fontSize: "11px",
                    padding: "6px 8px",
                    width: "100px",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "#eeeeee",
                    },
                    margin: featureImage ? "8px 0" : "0 auto 10px", // Add margin for spacing or center the button
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#3f51b5",
                  }}
                  onClick={featureImage ? handleImageChange : handleImageChange}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      fontFamily: "Outfit",
                      fontSize: "11px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    {featureImage ? "Update Image" : "Upload Image"}
                  </Typography>
                </Button>
              </label>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadFeaturePic;
