import React, { createContext, useState } from 'react';

// Create the context
export const BookingContext = createContext();

// Create the context provider

export const BookingProvider = ({ children }) => {
  const [bookingInfo, setBookingInfo] = useState();

  return <BookingContext.Provider value={{ bookingInfo, setBookingInfo }}>{children}</BookingContext.Provider>;
};
