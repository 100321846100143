import Propvana from '../assets/images/svgs/provana';

const Logo = () => {
  return (
    <>
      <Propvana />
    </>
  );
};

export default Logo;
