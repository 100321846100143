import React, { useState, useEffect } from 'react'; // ,{ useState }

import MUIDataTable from 'mui-datatables';
import Typography from '@mui/material/Typography';
// import { userData } from 'apis/userData';

// import { createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router';
// import { ThemeProvider } from '@emotion/react';
import { Button, IconButton } from '@mui/material';

import CircularLoader from 'ui-component/loader/CircularLoader';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
// import '../pages/pages.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { DELETE_ACCOUNT_MANAGER, GET_ACCOUNT_MANAGERS } from 'grapgQL/accountManagers';
import { useLazyQuery, useMutation } from '@apollo/client';
import { AccountManagersContext } from 'context/AccountManagers';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box } from '@mui/system';

const AccountManagerListing = () => {
  const [accountData, setAccountData] = useState([]);
  const { accountManagersInfo } = useContext(AccountManagersContext);

  const [loading, setLoading] = useState(true);

  const [rowData, setRowData] = useState('');
  const [totalRows, setTotalRows] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // const theme = useTheme();
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [getAllAccountManagers] = useLazyQuery(GET_ACCOUNT_MANAGERS, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'backend' },
    onCompleted(data) {
      const { getAllAccountManagers } = data;

      if (getAllAccountManagers) {
        const { success, message, accountManagers, totalPages } = getAllAccountManagers;
        if (success) {
          setLoading(false);

          const dataWithIndex = accountManagers.map((data, index) => ({ index: index + 1, ...data }));
          setTotalRows(dataWithIndex.length);
          setTotalPages(totalPages);
          setAccountData((prevData) => [...prevData, ...dataWithIndex]);
        } else {
          setLoading(false);
        }
      }
    },
    onError(error) {
      console.log('Something went wrong', error);
    }
  });

  const [deleteAccountManager] = useMutation(DELETE_ACCOUNT_MANAGER, {
    onCompleted(data) {
      console.log(data);
      const { success, message } = data.deleteAccountManager;
      console.log('Message', message);
      console.log('Success', success);
      if (success) {
        setPage(1);
        setAccountData([]);
        setLoading(true);
        getAllAccountManagers({
          variables: {
            page: 1
          }
        });

        toast.success(message);
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      console.log('error', error);
    }
  });

  useEffect(() => {
    setLoading(true);
    getAllAccountManagers({
      variables: {
        page: page
      }
    });
  }, [accountManagersInfo, page]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = (value) => {
    if (window.confirm('Are you sure you want to delete this Account Manager account?')) {
      console.log(value.id);
      deleteAccountManager({
        variables: {
          ID: value.id
        }
      });

      setAnchorEl(null);
    } else {
      // User clicked "Cancel" or closed the dialog
      // Handle accordingly, if needed
    }
  };
  const handlePrevPage = () => {
    if (page > 0) {
      setAccountData([]);
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    // Assuming 10 rows per page (adjust as needed)

    if (page < totalPages) {
      setAccountData([]);
      setPage(page + 1);
    }
  };
  const handleEdit = (value) => {
    setAnchorEl(null);
    Navigate('/account-manager', { state: { status: 'Edit', value } });
  };
  const handleClick = (value, event) => {
    setRowData(value);
    setAnchorEl(event.currentTarget);
  };

  const Navigate = useNavigate();
  const mytheme = createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            fontFamily: 'Outfit'
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            fontFamily: 'Outfit',
            fontSize: '16px',
            fontWeight: 'bold',
            lineHeight: '24px',
            letterSpacing: '0'
          }
        }
      }
    }
  });

  const handleClickOpen = () => {
    Navigate('/account-manager', { state: { status: 'Add' } });
  };

  const columns = [
    {
      name: 'index',
      label: ' ',
      options: {
        sort: false,
        filter: false
      }
    },
    {
      name: 'user',
      label: 'Name',
      options: {
        sort: false,
        customBodyRender: (value) => {
          return value?.name;
        }
      }
    },

    {
      name: 'user',
      label: 'Email',
      options: {
        sort: false,
        customBodyRender: (value) => {
          return value?.email;
        }
      }
    },

    {
      name: 'access',
      label: 'Access',
      options: {
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const statusStyle = {
            padding: '6px 4px',
            width: '100px',
            background: '#D9B91A1A',
            color: '#D9B91A',
            borderRadius: '4px',
            textAlign: 'center',
            cursor: 'pointer' // Add pointer cursor to indicate clickability
          };

          const handleAccessClick = (value) => {
            Navigate('/account-manager', { state: { status: 'View', value: value } });

            console.log(value);
          };

          return (
            <div style={statusStyle} onClick={() => handleAccessClick(accountData[dataIndex])}>
              View Access
            </div>
          );
        }
      }
    },
    {
      name: 'name',
      label: ' ',
      options: {
        sort: false,
        filter: false,

        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <Button type="button" onClick={(event) => handleClick(accountData[dataIndex], event)}>
                <MoreVertIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
              >
                <MenuItem onClick={() => handleEdit(rowData)}>
                  <ModeEditOutlineOutlinedIcon /> Edit
                </MenuItem>
                <MenuItem onClick={() => handleDelete(rowData)}>
                  <DeleteOutlinedIcon /> Delete
                </MenuItem>
              </Menu>
            </>
          );
        }
      }
    }
  ];

  const HeaderElements = () => {
    return (
      <>
        <Button
          className="primary-button"
          onClick={() => handleClickOpen()}
          style={{
            fontSize: '14px',
            padding: '8px 12px',
            borderRadius: '8px',

            '&:hover': {
              backgroundColor: '#eeeeee'
            },
            margin: '0 8px'
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontFamily: 'Outfit',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '0em',
              textAlign: 'center'
            }}
          >
            Add Managers
          </Typography>
        </Button>
      </>
    );
  };

  const options = {
    responsive: 'standard',
    print: false,
    download: false,
    tableLayout: 'fixed',
    customTableBodyWidth: '800px',
    tableBodyHeight: '500px',
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    customToolbar: HeaderElements,
    customFooter: () => (
      <Box display="flex" alignItems="center" justifyContent="flex-end" height="60px">
        <Typography
          variant="body1"
          sx={{
            margin: '0 4px',
            minWidth: 40,
            textAlign: 'center',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontSize: '15px',
            fontWeight: 400,
            lineHeight: '20.02px',
            color: 'rgba(0, 0, 0, 0.87)',
            marginRight: '20px'
          }}
        >
          1-{totalRows} of {totalRows}
        </Typography>
        <IconButton size="small" onClick={handlePrevPage} disabled={page === 1} sx={{ marginRight: 1, padding: 0.5 }}>
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton size="small" onClick={handleNextPage} disabled={page >= totalPages} sx={{ padding: 0.5, marginRight: '30px' }}>
          <KeyboardArrowRight />
        </IconButton>
      </Box>
    )
  };

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <CircularLoader />
          </div>
        </div>
      ) : (
        <>
          {accountData ? (
            <ThemeProvider theme={mytheme}>
              <MUIDataTable data={accountData} columns={columns} options={options} />
            </ThemeProvider>
          ) : (
            <ThemeProvider theme={mytheme}>
              <MUIDataTable data={[]} columns={columns} options={options} />{' '}
            </ThemeProvider>
          )}
        </>
      )}
    </>
  );
};

export default AccountManagerListing;
