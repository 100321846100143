import React, { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Button, Typography, useMediaQuery, Grid, TextField, MenuItem, Select, InputLabel, FormControl, Menu } from '@mui/material';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router';
import { ADD_EXPERT, GET_ALL_EXPERTS, UPDATE_EXPERT } from 'grapgQL/experts';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ExpertsContext } from 'context/expert';
import SubCard from 'ui-component/cards/SubCard';

import { GET_ALL_USERS } from 'grapgQL/users';
import { GET_ALL_PROPERTIES } from 'grapgQL/property';
import { useState } from 'react';
import { ADD_QUERY } from 'grapgQL/queries';

function QueriesDetails() {
  const location = useLocation();

  const status = location?.state?.status;
  const { setExpertsInfo } = useContext(ExpertsContext);
  const Navigate = useNavigate();
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const [properties, setProperties] = useState([]);
  const [users, setUsers] = useState([]);
  const [experts, setExperts] = useState([]);

  const [getAllUsers] = useLazyQuery(GET_ALL_USERS, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'backend' },
    onCompleted(data) {
      const { getAllUsers } = data;

      if (getAllUsers) {
        const { success, message, users } = getAllUsers;
        if (success) {
          setUsers(users);
        }
      }
    },
    onError(error) {
      console.log('Something went wrong', error);
    }
  });
  const [getAllProperties] = useLazyQuery(GET_ALL_PROPERTIES, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'backend' },
    onCompleted(data) {
      const { getAllProperties } = data;

      if (getAllProperties) {
        const { success, message, properties } = getAllProperties;
        if (success) {
          setProperties(properties);
        }
      }
    },
    onError(error) {
      console.log('Something went wrong', error);
    }
  });

  const [getAllExperts] = useLazyQuery(GET_ALL_EXPERTS, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'backend' },
    onCompleted(data) {
      const { getAllExperts } = data;

      if (getAllExperts) {
        const { success, message, experts } = getAllExperts;
        if (success) {
          console.log(experts);

          setExperts(experts);
        }
      }
    },
    onError(error) {
      console.log('Something went wrong', error);
    }
  });
  const [addQuery] = useMutation(ADD_QUERY, {
    onCompleted(data) {
      const { success, message } = data?.addQuery;

      if (success) {
        toast.success(message);
        setExpertsInfo(data?.addQuery);
        Navigate('/queries');
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      console.error(error);
      toast.error('Something Went Wrong!');
    }
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      status: 'pending',
      property: '',
      expertInvolved: ''
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Username is required'),

      property: Yup.string().required('Property is required'),
      expertInvolved: Yup.string().required('Expert Involved is required')
    }),

    onSubmit: (values) => {
      console.log(values);
      console.log('status', status);
      if (status === 'Add') {
        addQuery({
          variables: { user: values.username, property: values.property, expertInvolved: values.expertInvolved, status: values.status }
        });
      } else if (status === 'Edit') {
        // updateExpert({ variables: { ... } });
      }
    }
  });

  useEffect(() => {
    getAllUsers();
    getAllProperties();
    getAllExperts();
  }, []);

  return (
    <>
      <Typography variant="h2" gutterBottom style={{ marginBottom: '20px' }}>
        Queries Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <SubCard title="Query Details">
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="username-label">Username</InputLabel>
                    <Select
                      labelId="username-label"
                      id="username"
                      label="Username"
                      fullWidth
                      {...formik.getFieldProps('username')}
                      error={formik.touched.username && Boolean(formik.errors.username)}
                      inputProps={{
                        readOnly: status === 'View'
                      }}
                    >
                      {users?.map((username) => (
                        <MenuItem key={username._id} value={username._id}>
                          {username.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="expertInvolved-label">Expert Involved</InputLabel>
                    <Select
                      labelId="expertInvolved-label"
                      id="expertInvolved"
                      label="Expert Involved"
                      fullWidth
                      {...formik.getFieldProps('expertInvolved')}
                      error={formik.touched.expertInvolved && Boolean(formik.errors.expertInvolved)}
                      inputProps={{
                        readOnly: status === 'View'
                      }}
                    >
                      {experts?.map((expert) => (
                        <MenuItem key={expert._id} value={expert._id}>
                          {expert.user.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="property-label">Property</InputLabel>
                    <Select
                      labelId="property-label"
                      id="property"
                      label="Property"
                      fullWidth
                      {...formik.getFieldProps('property')}
                      error={formik.touched.property && Boolean(formik.errors.property)}
                      inputProps={{
                        readOnly: status === 'View'
                      }}
                      MenuComponent={({ children, ...props }) => (
                        <Menu {...props} PaperProps={{ style: { maxHeight: 300 } }}>
                          {children}
                        </Menu>
                      )}
                    >
                      {properties?.map((property) => (
                        <MenuItem key={property._id} value={property._id}>
                          {property.propertyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextField
                    id="status"
                    placeholder="status"
                    label="Status"
                    variant="outlined"
                    fullWidth
                    {...formik.getFieldProps('status')}
                    error={formik.touched.status && Boolean(formik.errors.status)}
                    helperText={formik.touched.status && formik.errors.status}
                    InputProps={{
                      readOnly: status === 'Add'
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Button type="submit" className="primary-button">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </SubCard>
        </Grid>
      </Grid>
    </>
  );
}

export default QueriesDetails;
