import { gql } from "@apollo/client";
export const GET_USERS = gql`
  query getAllUsers($page: Int) {
    getAllUsers(page: $page) {
      success
      message
      totalPages
      users {
        _id
        name
        email
        gender
        profilePicture
        role
        budgetToInvest
        whatsappNumber
        verificationToken
        postalAddress {
          streetAddress
          countryRegion
          city
          zipPostalCode
        }

        isAdmin
      }
    }
  }
`;
export const GET_USERS_ID = gql`
  query getAllUsers($page: Int) {
    getAllUsers(page: $page) {
      success
      message
      users {
        _id
        name
      }
    }
  }
`;
export const GET_USER = gql`
  mutation GetUser($userID: ID!) {
    getUser(userID: $userID) {
      success
      message
      user {
        _id
        name
        profilePicture
        email
        gender
        role
        budgetToInvest
        whatsappNumber
        verificationToken
        postalAddress {
          streetAddress
          countryRegion
          city
          zipPostalCode
        }

        isAdmin
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  query {
    getAllUsers(page: 0) {
      success
      message
      users {
        _id
        name
      }
    }
  }
`;

export const ADD_USER = gql`
  mutation addUser(
    $name: String
    $email: String
    $password: String
    $whatsappNumber: String
    $role: [String]
    $gender: String
    $budgetToInvest: String
    $postalAddress: PostalAddressInput
    $profilePicture: String
  ) {
    addUser(
      input: {
        whatsappNumber: $whatsappNumber
        name: $name
        email: $email
        password: $password
        role: $role
        gender: $gender
        postalAddress: $postalAddress
        budgetToInvest: $budgetToInvest
        profilePicture: $profilePicture
      }
    ) {
      message
      success
      user {
        _id
        name
        email
        gender
        role
        budgetToInvest
        whatsappNumber
        verificationToken
        postalAddress {
          streetAddress
          countryRegion
          city
          zipPostalCode
        }
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($userID: ID!) {
    deleteUser(userID: $userID) {
      success
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUserInfo($userID: ID!, $updateObj: UpdateUserInputFields!) {
    updateUserInfo(input: { userID: $userID, updateObj: $updateObj }) {
      success
      message
      user {
        _id
        name
        email
        gender
        role
        budgetToInvest
        whatsappNumber
        verificationToken
        postalAddress {
          streetAddress
          countryRegion
          city
          zipPostalCode
        }
      }
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation updateUserPassword(
    $userID: ID!
    $oldPassword: String!
    $newPassword: String!
  ) {
    updateUserPassword(
      input: {
        userID: $userID
        oldPassword: $oldPassword
        newPassword: $newPassword
      }
    ) {
      message
      success
    }
  }
`;
