import React, { createContext, useState } from 'react';

// Create the context
export const AccountManagersContext = createContext();

// Create the context provider

export const AccountManagersProvider = ({ children }) => {
  const [accountManagersInfo, setAccountManagersInfo] = useState();

  return (
    <AccountManagersContext.Provider value={{ accountManagersInfo, setAccountManagersInfo }}>{children}</AccountManagersContext.Provider>
  );
};
