import React, { createContext, useState } from 'react';

// Create the context
export const UserContext = createContext();

// Create the context provider

export const UserProvider = ({ children }) => {
  const [usersInfo, setUsersInfo] = useState();

  return <UserContext.Provider value={{ usersInfo, setUsersInfo }}>{children}</UserContext.Provider>;
};
