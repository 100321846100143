import React, { useContext, useEffect } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const QueriesPermission = ({ component }) => {
  const { isLoggedIn } = useContext(AuthContext);
  const userDataString = localStorage.getItem('userData');
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const AccountManagerAccess = userData?.accountManager?.access.queriesAccess;

  console.log(AccountManagerAccess);
  const auth = isLoggedIn();

  return auth.role[0] === 'Admin' || (auth.role[0] === 'accountManager' && AccountManagerAccess) || auth.role[0] === 'expert' ? (
    component
  ) : (
    <Navigate to="/404" />
  );
};

export default QueriesPermission;
