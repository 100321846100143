import React, { useState, useEffect, useContext } from "react"; // ,{ useState }

import MUIDataTable from "mui-datatables";
import Typography from "@mui/material/Typography";
// import { userData } from 'apis/userData';

// import { createTheme } from '@mui/material/styles';
import { useNavigate } from "react-router";

import { Button, IconButton } from "@mui/material";
// import PhotoCamera from '@mui/icons-material/PhotoCamera';
import CircularLoader from "ui-component/loader/CircularLoader";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { TableHeading } from "components/tableHeading";
import { mytheme } from "themes/tableHeader";
import { DELETE_BOOKING, GET_BOOKINGS } from "grapgQL/booking";
import { useLazyQuery, useMutation } from "@apollo/client";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box } from "@mui/system";
import { BookingContext } from "context/Booking";
import { toast } from "react-toastify";
import { GET_PROPERTY } from "grapgQL/property";
import { GET_USER } from "grapgQL/users";
import { AuthContext } from "context/AuthContext";

const BookingPage = () => {
  const [bookData, setBookData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [totalRows, setTotalRows] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { bookingInfo } = useContext(BookingContext);
  const { logOut } = useContext(AuthContext);

  const [getUser] = useMutation(GET_USER, {
    onCompleted(data) {
      const { success, message, user } = data?.getUser;

      if (success) {
        Navigate("/users/adduser", { state: { status: "View", value: user } });
      } else {
        toast.error(message);
      }
    },
    onError(error) {
      console.error("An error occurred during the mutation:", error.message);
      toast.error("Something Went Wrong!");
      // You can handle errors here, log them, or perform any other actions
    },
  });

  const [getProperty] = useMutation(GET_PROPERTY, {
    onCompleted(data) {
      const { success, message, property } = data?.getProperty;

      if (success) {
        Navigate("/properties/propertydetails", {
          state: { status: "View", value: property },
        });
      } else {
        toast.error(message);
      }
    },
    onError(error) {
      console.error("An error occurred during the mutation:", error.message);
      toast.error("Something Went Wrong!");
      // You can handle errors here, log them, or perform any other actions
    },
  });

  const [page, setPage] = useState(1);

  const open = Boolean(anchorEl);
  const handleClickOpen = () => {
    Navigate("/bookings/bookingdetails", {
      state: { status: "Add", value: 0 },
    });
  };
  const [allBookings] = useLazyQuery(GET_BOOKINGS, {
    fetchPolicy: "no-cache",
    context: { clientName: "backend" },
    onCompleted(data) {
      const { getAllBookings } = data;

      if (getAllBookings) {
        const { success, message, bookings, totalPages } = getAllBookings;
        if (success) {
          setLoading(false);

          const dataWithIndex = bookings.map((data, index) => ({
            index: index + 1,
            ...data,
          }));
          setTotalRows(dataWithIndex.length);
          setTotalPages(totalPages);
          setBookData((prevData) => [...prevData, ...dataWithIndex]);
        } else {
          if (message === "Token Expired") {
            logOut();
          }
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    },
    onError(error) {
      console.log("Something went wrong", error);
    },
  });

  const [deleteBooking] = useMutation(DELETE_BOOKING, {
    onCompleted(data) {
      const { success, message } = data.deleteBooking;
      if (success) {
        setBookData([]);
        setLoading(true);
        setPage(1);
        allBookings({
          variables: {
            page: 1,
          },
        });

        toast.success(message);
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      console.log("error", error);
    },
  });

  const handleClick = (value, event) => {
    setRowData(value);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = (value) => {
    if (window.confirm("Are you sure you want to delete this Booking?")) {
      // User clicked "OK"

      deleteBooking({
        variables: {
          bookingId: value._id,
        },
      });
      setAnchorEl(null);
    } else {
      // User clicked "Cancel" or closed the dialog
      // Handle accordingly, if needed
    }
  };
  const handleEdit = (value) => {
    setAnchorEl(null);
    Navigate("/bookings/bookingdetails", { state: { status: "Edit", value } });
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setBookData([]);
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    // Assuming 10 rows per page (adjust as needed)

    if (page < totalPages) {
      setBookData([]);
      setPage(page + 1);
    }
  };
  useEffect(() => {
    allBookings({
      variables: {
        page: page,
      },
    });
  }, [page, bookingInfo]);

  const Navigate = useNavigate();

  const columns = [
    // 'Name',
    // 'LastName',
    {
      name: "index",
      label: " ",
      options: {
        sort: false,
        filter: false,
      },
    },
    // {
    //   name: 'image',
    //   label: 'Image',
    //   options: {
    //     sort: false,

    //     customBodyRender: (value) => {
    //       const imageStyle = {
    //         maxHeight: '100px', // Adjust image size to fit within the cell
    //         objectFit: 'cover' // Maintain aspect ratio and cover the container
    //       };

    //       return <img src={value} alt="Preview" style={imageStyle} />;
    //     }
    //   }
    // },

    {
      name: "userId",
      label: "Name",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => getUser({ variables: { userID: value?._id } })}
            >
              {value?.name}
            </span>
          );
        },
      },
    },

    {
      name: "propertyId",
      label: "Location",

      options: {
        sort: false,
        filter: false,
        customBodyRender: (value) => {
          const location = value?.propertyInformation?.location;

          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() =>
                getProperty({ variables: { propertyID: value?._id } })
              }
            >
              {location ? location : "N/A"}
            </span>
          );
        },
      },
    },

    {
      name: "propertyId",
      label: "Type",

      options: {
        sort: false,
        filter: false,
        customBodyRender: (value) => {
          const category = value?.propertyInformation?.category;
          const displayName = category ? category.toUpperCase() : "N/A";
          return displayName;
        },
      },
    },

    {
      name: "propertyId",
      label: "Property Developer",
      options: {
        sort: false,
        customBodyRender: (value) => {
          const developer = value?.propertyName;
          return developer ? developer : "N/A";
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        sort: false,
        customBodyRender: (value) => {
          const isAvailable = value === "Confirmed";
          const statusStyle = {
            padding: "6px 4px", // Adding padding

            width: "100px",

            background: isAvailable ? "#00BA611A" : "#D9B91A1A",
            color: isAvailable ? "#00BA61" : "#D9B91A",
            borderRadius: "4px", // Adding border-radius for a box-like appearance
            textAlign: "center", // Centering the text

            // Add other common styles here
          };

          return <div style={statusStyle}>{value}</div>;
        },
      },
    },
    // {
    //   name: 'name',
    //   // name: 'ViewProfile',
    //   label: ' ',

    //   options: {
    //     sort: false,
    //     filter: false,
    //     customBodyRenderLite: (dataIndex) => {
    //       return (
    //         <Typography
    //           className="details-text"
    //           onClick={() => Navigate('/bookings/bookingdetails', { state: { status: 'View', value: bookData[dataIndex] } })}
    //           sx={{ cursor: 'pointer', fontFamily: 'Outfit' }}
    //         >
    //           View Details
    //         </Typography>
    //       );
    //     }
    //   }
    // },
    {
      name: "name",
      label: " ",
      options: {
        sort: false,
        filter: false,

        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <Button
                type="button"
                onClick={(event) => handleClick(bookData[dataIndex], event)}
              >
                <MoreVertIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {/* <MenuItem onClick={() => handleEdit(rowData)}>
                  <ModeEditOutlineOutlinedIcon /> Edit
                </MenuItem> */}
                <MenuItem onClick={() => handleDelete(rowData)}>
                  <DeleteOutlinedIcon /> Delete
                </MenuItem>
              </Menu>
            </>
          );
        },
      },
    },
  ];

  const HeaderElements = () => {
    return (
      <Button
        className="primary-button"
        onClick={() => handleClickOpen()}
        style={{
          fontSize: "14px",
          padding: "8px 12px",
          borderRadius: "8px",

          "&:hover": {
            backgroundColor: "#eeeeee",
          },
          margin: "0 8px",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontFamily: "Outfit",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0em",
            textAlign: "center",
          }}
        >
          Add Booking
        </Typography>
      </Button>
    );
  };

  const options = {
    // textLabels: {
    //   body: {
    //     noMatch: ''
    //   }
    // },
    customHeadRender: () => ({
      style: {
        fontFamily: "Outfit",
        fontSize: "48px",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0",
        textAlign: "center",
      },
    }),
    responsive: "standard",
    print: false,
    download: false,
    viewColumns: false,
    tableLayout: "fixed",
    customTableBodyWidth: "800px",
    tableBodyHeight: "500px",
    selectableRowsHideCheckboxes: true,
    // customToolbar: HeaderElements,
    customFooter: () => (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        height="60px"
      >
        <Typography
          variant="body1"
          sx={{
            margin: "0 4px",
            minWidth: 40,
            textAlign: "center",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontSize: "15px",
            fontWeight: 400,
            lineHeight: "20.02px",
            color: "rgba(0, 0, 0, 0.87)",
            marginRight: "20px",
          }}
        >
          1-{totalRows} of {totalRows}
        </Typography>
        <IconButton
          size="small"
          onClick={handlePrevPage}
          disabled={page === 1}
          sx={{ marginRight: 1, padding: 0.5 }}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          size="small"
          onClick={handleNextPage}
          disabled={page >= totalPages}
          sx={{ padding: 0.5, marginRight: "30px" }}
        >
          <KeyboardArrowRight />
        </IconButton>
      </Box>
    ),
  };

  return (
    <>
      <TableHeading name={"Booking"} />
      {loading ? (
        // <div>i am loading</div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularLoader />
        </div>
      ) : (
        <>
          {bookData ? (
            <ThemeProvider theme={mytheme}>
              <MUIDataTable
                // title={'Users list'}
                data={bookData}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          ) : (
            <ThemeProvider theme={mytheme}>
              <MUIDataTable
                // title={'Users list'}
                data={[]}
                columns={columns}
                options={options}
              />{" "}
            </ThemeProvider>
          )}

          {/* <ThemeProvider /> */}
        </>
      )}
    </>
  );
};

export default BookingPage;
