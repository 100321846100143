// // assets
// import { IconUser } from '@tabler/icons';

// // constant
// const icons = {
//   IconUser
// };

// // ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

// const users = {
// //   id: 'pages',
// //   title: 'Pages',
//   // caption: 'Pages Caption',
//   type: 'group',
//   children: [
//     {
//       id: 'authentication',
//       title: 'Users',
//       type: 'collapse',
//       icon: icons.IconUser,

//     //   children: [
//     //     {
//     //       id: 'login3',
//     //       title: 'Login',
          
//     //       type: 'item',
//     //       url: '/pages/login/login3',
//     //       target: true
//     //     },
//     //     {
//     //       id: 'register3',
//     //       title: 'Register',
//     //       type: 'item',
//     //       url: '/pages/register/register3',
//     //       target: true
//     //     }
//     //   ]
//     }
//   ]
// };

// export default users;







// constant
// const icons = { IconBrandChrome, IconHelp, IconUser };

// ==============================|| USER PAGE & DOCUMENTATION MENU ITEMS ||============================== //
  import { IconUser } from '@tabler/icons';

  // constant
  const icons = {
    IconUser
  };
const user = {
    id: 'users',
    type: 'group',
    children: [
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/users',
            icon: icons.IconUser,
            // icon: AccountCircleOutlinedIcon,
            breadcrumbs: false
        }
    ]
};

export default user;
