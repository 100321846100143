import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button, Typography } from "@mui/material";
import { Grid, InputLabel, TextField } from "@mui/material";
import UploadAccountAvatar from "ui-component/UploadIAccountAvatar";
import SubCard from "ui-component/cards/SubCard";
import { toast } from "react-toastify";
import SwitchesComponent from "components/SwitchesComponent";
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import {
  ADD_ACCOUNT_MANAGER,
  UPDATE_ACCOUNT_MANAGER,
} from "grapgQL/accountManagers";
import { AccountManagersContext } from "context/AccountManagers";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { IOSSwitch, VerticalDivider } from "ui-component/IOSSwitch";
import { useContext } from "react";

function AccountManager() {
  const location = useLocation();
  const Navigate = useNavigate();

  const [manager, setManager] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const { setAccountManagersInfo } = useContext(AccountManagersContext);

  const [getAllAccess, setGetAllAccess] = useState(null);
  const [bookingsSwitch, setBookingsSwitch] = useState(false);
  const [propertiesSwitch, setPropertiesSwitch] = useState(false);
  const [usersSwitch, setUsersSwitch] = useState(false);
  const [expertsSwitch, setExpertsSwitch] = useState(false);
  const [developersSwitch, setDevelopersSwitch] = useState(false);
  const [queriesSwitch, setQueriesSwitch] = useState(false);

  const status = location?.state?.status;
  const value = location?.state?.value;

  const [addAccountManager] = useMutation(ADD_ACCOUNT_MANAGER, {
    onCompleted(data) {
      const { success, message } = data?.addAccountManager;

      if (success) {
        toast.success(message);
        setAccountManagersInfo(data?.addAccountManager);

        Navigate("/settings");
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      toast.error("Something Went Wrong!");
    },
  });
  const [updateAccountManager] = useMutation(UPDATE_ACCOUNT_MANAGER, {
    onCompleted(data) {
      console.log(data);
      const { success, message } = data.updateAccountManager;
      if (success) {
        toast.success(message);
        Navigate("/settings");
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      toast.error("Something Went Wrong!");
    },
  });

  const handleSave = () => {
    const { values, touched, errors } = formik;

    // Check if required fields are present
    if (!values.name || !values.email) {
      toast.error("Name and email are required");
      return;
    }

    // Display error toasts
    Object.keys(errors).forEach((key) => {
      toast.error(errors[key]);
    });

    // If there are errors, return early
    if (Object.keys(errors).length > 0) {
      return;
    }

    // Set access
    setGetAllAccess({
      bookingsAccess: bookingsSwitch,
      propertiesAccess: propertiesSwitch,
      usersAccess: usersSwitch,
      expertsAccess: expertsSwitch,
      developersAccess: developersSwitch,
      queriesAccess: queriesSwitch,
    });

    // Perform actions based on status
    if (status === "Add") {
      if (values.password) {
        addAccountManager({
          variables: {
            name: values.name,
            email: values.email,
            password: values.password,
            profilePicture: profileImage,
            access: {
              bookingsAccess: bookingsSwitch,
              propertiesAccess: propertiesSwitch,
              usersAccess: usersSwitch,
              expertsAccess: expertsSwitch,
              developersAccess: developersSwitch,
              queriesAccess: queriesSwitch,
            },
          },
        });
      } else {
        toast.error("Password is required ");
      }
    } else if (status === "Edit") {
      updateAccountManager({
        variables: {
          id: manager?.id,
          name: values.name,
          email: values.email,
          profilePicture: profileImage,
          access: {
            bookingsAccess: bookingsSwitch,
            propertiesAccess: propertiesSwitch,
            usersAccess: usersSwitch,
            expertsAccess: expertsSwitch,
            developersAccess: developersSwitch,
            queriesAccess: queriesSwitch,
          },
        },
      });
    }
  };

  const handleBookingChange = () => {
    setBookingsSwitch((prev) => !prev);
  };
  const handlePropertyChange = () => {
    setPropertiesSwitch((prev) => !prev);
  };
  const handleUserChange = () => {
    setUsersSwitch((prev) => !prev);
  };
  const handleExpertChange = () => {
    setExpertsSwitch((prev) => !prev);
  };
  const handleDeveloperChange = () => {
    setDevelopersSwitch((prev) => !prev);
  };
  const handleQueriesChange = () => {
    setQueriesSwitch((prev) => !prev);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      // password: Yup.string().required('Password is required')
    }),
    onSubmit: (values) => {
      // Handle the save logic here using the form values
      console.log(status);
    },
  });

  useEffect(() => {
    const assignValues = (searchData) => {
      if (searchData) {
        setManager(searchData);
        const { access } = searchData;

        formik.setValues({
          name: searchData?.user.name,
          email: searchData?.user.email,

          // password: searchData?.password
        });
        setProfileImage(searchData?.user?.profilePicture);

        setUsersSwitch(access.usersAccess);
        setPropertiesSwitch(access.propertiesAccess);
        setBookingsSwitch(access.bookingsAccess);
        setDevelopersSwitch(access.developersAccess);
        setExpertsSwitch(access.expertsAccess);
        setQueriesSwitch(access.queriesAccess);
      }
    };

    const searchData = location?.state?.value;

    if (searchData) {
      assignValues(searchData);
    }
  }, [manager, location, formik.setValues]);
  const GetExpertImage = (profileImage) => {
    setProfileImage(profileImage);
  };

  return (
    <>
      <Typography variant="h2" gutterBottom style={{ marginBottom: "20px" }}>
        Account Manager
      </Typography>
      <Grid container spacing={2}>
        {/* Profile Avatar Section */}

        <Grid item xs={12} lg={3}>
          <SubCard title="Profile Avatar" style={{ height: "360px" }}>
            <UploadAccountAvatar
              GetProfilePic={GetExpertImage}
              profileImg={profileImage}
              status={status}
            />
          </SubCard>
        </Grid>

        {/* Card Section */}

        <Grid item xs={12} lg={9}>
          <SubCard title="Account Details" style={{ height: "360px" }}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-textarea1"
                    label="Name"
                    placeholder="Name"
                    fullWidth
                    value={formik.values.name || ""}
                    onChange={formik.handleChange("name")}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    InputProps={{
                      readOnly: status === "View", // Set readOnly based on the status
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-textarea2"
                    label="Email"
                    placeholder="Email"
                    fullWidth
                    value={formik.values.email || ""}
                    onChange={formik.handleChange("email")}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    InputProps={{
                      readOnly: status === "View", // Set readOnly based on the status
                    }}
                  />
                </Grid>

                {status === "Add" && (
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-textarea3"
                      label="Password"
                      placeholder="Password"
                      type="password"
                      fullWidth
                      value={formik.values.password || ""}
                      onChange={formik.handleChange("password")}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      InputProps={{
                        readOnly: status === "View", // Set readOnly based on the status
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </form>
          </SubCard>
        </Grid>
        <Grid item xs={12}>
          <SubCard>
            {/* <SwitchesComponent access={manager?.access} handleAccess={HandleAccess} /> */}
            <Grid>
              <Grid container spacing={3}>
                {/* Booking Access */}
                <Grid item xs={12} md={4}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <Typography variant="h4" gutterBottom>
                        Bookings Access
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        Give Bookings Access to User
                      </Typography>
                    </div>

                    <div style={{ marginLeft: "auto" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <IOSSwitch sx={{ m: 1 }} checked={bookingsSwitch} />
                          }
                          onChange={handleBookingChange}
                        />
                      </FormGroup>
                    </div>
                    {/* <VerticalDivider /> */}
                  </div>
                </Grid>

                {/* Property Access */}
                <Grid item xs={12} md={4}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <Typography variant="h4" gutterBottom>
                        Properties Access
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        Give Properties Access to User
                      </Typography>
                    </div>

                    <div style={{ marginLeft: "auto" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={propertiesSwitch}
                            />
                          }
                          onChange={handlePropertyChange}
                        />
                      </FormGroup>
                    </div>
                    {/* <VerticalDivider /> */}
                  </div>
                </Grid>

                {/* User Access */}
                <Grid item xs={12} md={4}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <Typography variant="h4" gutterBottom>
                        Users Access
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        Give Users Access to User
                      </Typography>
                    </div>

                    <div style={{ marginLeft: "auto" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <IOSSwitch sx={{ m: 1 }} checked={usersSwitch} />
                          }
                          onChange={handleUserChange}
                        />
                      </FormGroup>
                    </div>
                    {/* <VerticalDivider /> */}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ marginTop: "10px" }}>
                {/* Expert Access */}
                <Grid item xs={12} md={4}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <Typography variant="h4" gutterBottom>
                        Experts
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        Give Experts Access to User
                      </Typography>
                    </div>

                    <div style={{ marginLeft: "auto" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <IOSSwitch sx={{ m: 1 }} checked={expertsSwitch} />
                          }
                          onChange={handleExpertChange}
                        />
                      </FormGroup>
                    </div>
                    {/* <VerticalDivider /> */}
                  </div>
                </Grid>

                {/* DEveloper Access */}
                <Grid item xs={12} md={4}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <Typography variant="h4" gutterBottom>
                        Developers Access
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        Give Developers Access to User
                      </Typography>
                    </div>

                    <div style={{ marginLeft: "auto" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={developersSwitch}
                            />
                          }
                          onChange={handleDeveloperChange}
                        />
                      </FormGroup>
                    </div>
                    {/* <VerticalDivider /> */}
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <Typography variant="h4" gutterBottom>
                        Queries Access
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        Give Queries Access to User
                      </Typography>
                    </div>

                    <div style={{ marginLeft: "auto" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <IOSSwitch sx={{ m: 1 }} checked={queriesSwitch} />
                          }
                          onChange={handleQueriesChange}
                        />
                      </FormGroup>
                    </div>
                    {/* <VerticalDivider /> */}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </SubCard>
        </Grid>
        {status !== "View" && (
          <Grid item xs={12} md={8}>
            <Button
              onClick={() => handleSave()}
              className="primary-button"
              style={{ fontSize: "16px", padding: "6px", borderRadius: "5px" }}
            >
              Save
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default AccountManager;
