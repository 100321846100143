import axios from "axios";

export const UploadImage = async (file, type = "Public") => {
  let res = "";
  let err = "";

  let formData = new FormData();
  formData.append("file", file);
  formData.append("type", type);
  console.log("formData", formData);

  try {
    // Make the API request using axios.post
    res = await axios.post(
      "https://staging.api.propvana.ai/api/v1/fileupload/s3",
      formData

      // {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // }
    );
  } catch (error) {
    // if (error.response.status === 401) {
    //   logOut();
    //   console.log("logging Out");
    // }
    if (error.response) {
      console.log("Image upload error:", error.response.data);
    }
    err = error;
  }
  return res || err;
};
