import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";

import {
  Button,
  Typography,
  CircularProgress,
  useMediaQuery,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";

import SubCard from "ui-component/cards/SubCard";
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import { ExpertsContext } from "context/expert";
import { useContext } from "react";
import { v4 as uuid } from "uuid";

import { toast } from "react-toastify";
import { io } from "socket.io-client";
import { ADD_PROPERTY, UPDATE_PROPERTY } from "grapgQL/property";
import { useMutation } from "@apollo/client";
import { PropertyContext } from "context/property";

function PropertyArea() {
  const location = useLocation();
  const [socketInstance, setSocket] = useState();
  const [expert, setExpert] = useState();
  const status = location?.state?.status;
  const { setExpertsInfo } = useContext(ExpertsContext);
  const Navigate = useNavigate();
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [isConnected, setIsConnected] = useState(false);

  const [propertyUsageOptions, setPropertyUsageOption] = useState([]);
  const [propertyTypeOptions, setPropertyTypeOption] = useState([]);
  const [areaNameOptions, setAreaNameOption] = useState([]);
  const [nearestLandmarkOptions, setNearestLandmarkOption] = useState([]);
  const [nearestMetroOptions, setNearestMetroOption] = useState([]);
  const [nearestMallOptions, setNearestMallOption] = useState([]);
  const [roomsOptions, setRoomsOption] = useState([]);
  const [procedureAreaOptions, setProcedureAreaOption] = useState([]);
  const [hasParkingOptions, setHasParkingOption] = useState([]);

  const [showPropertyType, setShowPropertyType] = useState(false);
  const [showAreaName, setShowAreaName] = useState(true);
  const [showNearestLandmark, setShowNearestLandmark] = useState(true);
  const [showNearestMetro, setShowNearestMetro] = useState(true);
  const [showNearestMall, setShowNearestMall] = useState(true);
  const [showRooms, setShowRooms] = useState(true);
  const [showPropertyUsage, setShowPropertyUsage] = useState(true);
  const [showProcedureArea, setShowProcedureArea] = useState(true);
  const [showHasParking, setShowHasParking] = useState(true);

  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  const { propertyInfo } = useContext(PropertyContext);
  // console.log(propertyInfo.property._id);

  // console.log(process.env.REACT_APP_SOCKET_IP);
  const [id, setid] = useState();

  const [updateProperty] = useMutation(UPDATE_PROPERTY, {
    onCompleted(data) {
      // console.log(data);
      const { success, message } = data.updateProperty;
      if (success) {
        toast.success("Property Added Successfully");
        setLoading(false);
        setDisable(false);
        Navigate("/properties");
      } else {
        toast.error(message);
        setLoading(false);
        setDisable(false);
      }
    },

    onError(error) {
      setLoading(false);
      setDisable(false);
      toast.error("Something Went Wrong!");
    },
  });

  useEffect(() => {
    if (socketInstance?.isConnected) {
      socket.disconnect();
      setSocket(null);
    }
    const socket = io(process.env.REACT_APP_SOCKET_IP);

    socket?.on("connect", () => {
      console.log("Connected to server");
      setid(uuid());
      setIsConnected(true);
    });

    socket?.on("connect_error", (err) => {
      console.error("Connection error:", err);
    });

    socket?.on("disconnect", () => {
      console.log("Disconnected from server");
      setIsConnected(false);
    });

    socket?.on("available_values", (data) => {
      if (data?.property_usage) {
        setPropertyUsageOption(data?.property_usage);
      }
      if (data?.property_type) {
        setPropertyTypeOption(data?.property_type);
      }
      if (data?.area_name) {
        setAreaNameOption(data?.area_name);
      }
      if (data?.nearest_landmark) {
        setNearestLandmarkOption(data?.nearest_landmark);
      }
      if (data?.nearest_metro) {
        setNearestMetroOption(data?.nearest_metro);
      }
      if (data?.nearest_mall) {
        setNearestMallOption(data?.nearest_mall);
      }
      if (data?.rooms) {
        setRoomsOption(data?.rooms);
      }
      if (data?.procedure_area) {
        setProcedureAreaOption(data?.procedure_area);
      }
      if (data?.has_parking) {
        setHasParkingOption(data?.has_parking);
      }

      // Handle the received data as needed
    });

    // Emitting 'available_values' event immediately upon connection
    socket?.emit("available_values", id);
    socket?.on("available_values", (data) => {
      // console.log("Current List", data);
    });

    socket?.on("message", (data) => {
      console.log("Received After select_value ", data);
    });

    setSocket(socket);
    return () => {
      socket.disconnect();
      setSocket(null);
    };
  }, []);

  const GetAllItems = () => {
    socketInstance?.emit("available_values", id);
    socketInstance?.on("available_values", (data) => {
      // console.log("Current List", data);
      // Handle the received data as needed
    });
  };

  const formik = useFormik({
    initialValues: {
      property_usage: "",
      property_type: "",
      area_name: "",
      nearest_landmark: "",
      nearest_metro: "",
      nearest_mall: "",
      rooms: "",
      has_parking: "",
      procedure_area: "",
    },
    validationSchema: Yup.object({
      property_usage: Yup.string().required("Property Usage is required"),
      property_type: Yup.string().required("Property Type is required"),
      area_name: Yup.string().required("Area Name is required"),
      nearest_landmark: Yup.string().required("Nearest Landmark is required"),
      nearest_metro: Yup.string().required("Nearest Metro is required"),
      nearest_mall: Yup.string().required("Nearest Mall is required"),
      rooms: Yup.string().required("Rooms is required"),
      has_parking: Yup.string().required("Parking information is required"),
      procedure_area: Yup.string().required("Procedure Area is required"),
    }),
    onSubmit: (values) => {
      // Replace the API call logic with your desired behavior for form submission
      setLoading(true);
      setDisable(true);
      socketInstance?.emit("predict", id);
      socketInstance?.on("predict", (data) => {
        // console.log("Received After predict ", data);
        const actual_worth = Object.values(data.PVA.actual_worth);
        const instance_date = Object.values(data.PVA.instance_date);
        const date = Object.values(data.RVA.date);
        const annual_amount = Object.values(data.RVA.annual_amount);

        if (data.PVA && data.RVA) {
          // Handle the received data as needed
          updateProperty({
            variables: {
              propertyID: propertyInfo.property._id,
              updateObj: {
                predict: {
                  PVA: {
                    actual_worth: actual_worth,
                    instance_date: instance_date,
                  },
                  RVA: {
                    date: date,
                    annual_amount: annual_amount,
                  },
                },
              },
            },
          });
        } else {
          toast.error("Server Not Connected");
        }
      });

      // Navigate("/properties");
    },
  });

  const handleSelectValue = (name, value) => {
    if (isConnected) {
      socketInstance?.emit("select_value", id, name, value);

      GetAllItems();
    } else {
      toast.error("Server Not Connected");
    }
  };

  const AddPropertyType = (event) => {
    formik.handleChange("property_type")(event);
    setShowPropertyType(true);
    setShowPropertyUsage(false);
    handleSelectValue("property_type", event.target.value);
  };

  const AddRooms = (event) => {
    formik.handleChange("rooms")(event);
    setShowRooms(true);
    setShowHasParking(false);
    handleSelectValue("rooms", event.target.value);
  };

  const AddPropertyUsage = (event) => {
    formik.handleChange("property_usage")(event);
    setShowPropertyUsage(true);
    setShowAreaName(false);
    handleSelectValue("property_usage", event.target.value);
  };

  const AddProcedureArea = (event) => {
    formik.handleChange("procedure_area")(event);
    setShowProcedureArea(true);

    handleSelectValue("procedure_area", event.target.value);
  };

  const AddNearestMetro = (event) => {
    formik.handleChange("nearest_metro")(event);
    setShowNearestMetro(true);
    setShowNearestMall(false);
    handleSelectValue("nearest_metro", event.target.value);
  };

  const AddNearestMall = (event) => {
    formik.handleChange("nearest_mall")(event);
    setShowNearestMall(true);
    setShowRooms(false);
    handleSelectValue("nearest_mall", event.target.value);
  };

  const AddNearestLandmark = (event) => {
    formik.handleChange("nearest_landmark")(event);
    setShowNearestLandmark(true);
    setShowNearestMetro(false);
    handleSelectValue("nearest_landmark", event.target.value);
  };

  const AddHasParking = (event) => {
    formik.handleChange("has_parking")(event);
    setShowHasParking(true);
    setShowProcedureArea(false);
    handleSelectValue("has_parking", event.target.value);
  };

  const AddAreaName = (event) => {
    formik.handleChange("area_name")(event);
    setShowAreaName(true);
    setShowNearestLandmark(false);
    handleSelectValue("area_name", event.target.value);
  };

  useEffect(() => {
    const assignValues = (searchData) => {
      // if (searchData) {
      //   setExpert(searchData);
      //   formik.setValues({
      //     property_usage: expert?.property_usage || "",
      //     property_type: expert?.property_type || "",
      //     area_name: expert?.area_name || "",
      //     nearest_landmark: expert?.nearest_landmark || "",
      //     nearest_metro: expert?.nearest_metro || "",
      //     nearest_mall: expert?.nearest_mall || "",
      //     rooms: expert?.rooms || "",
      //     has_parking: expert?.has_parking | ""
      //     procedure_area: expert?.procedure_area || "",
      //   });
      // }
    };

    const searchData = location?.state?.value;

    if (searchData) {
      assignValues(searchData);
    }
  }, [expert]);

  return (
    <>
      {/* <div>{isConnected ? "Connected" : "Disconnected"}</div> */}

      {isConnected ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <SubCard title="Property Type Details">
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                      <InputLabel id="property-type-label">
                        Property Type
                      </InputLabel>
                      <Select
                        labelId="property-type-label"
                        id="property_type"
                        value={formik.values.property_type}
                        label="Property Type"
                        disabled={showPropertyType}
                        onChange={AddPropertyType}
                      >
                        {propertyTypeOptions?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText style={{ color: "red" }}>
                        {formik.touched.property_type &&
                          formik.errors.property_type}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                      <InputLabel id="property-usage-label">
                        Property Usage
                      </InputLabel>
                      <Select
                        labelId="property-usage-label"
                        id="property_usage"
                        name="property_usage"
                        label="Property Usage"
                        value={formik.values.property_usage}
                        onChange={AddPropertyUsage}
                        disabled={showPropertyUsage}
                      >
                        {propertyUsageOptions?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText style={{ color: "red" }}>
                        {formik.touched.property_usage &&
                          formik.errors.property_usage}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                      <InputLabel id="area-name-label">Area Name</InputLabel>
                      <Select
                        labelId="area-name-label"
                        id="area_name"
                        label="Area Name"
                        value={formik.values.area_name}
                        disabled={showAreaName}
                        onChange={AddAreaName}
                      >
                        {areaNameOptions?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText style={{ color: "red" }}>
                        {formik.touched.area_name && formik.errors.area_name}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                      <InputLabel id="nearest-landmark-label">
                        Nearest Landmark
                      </InputLabel>
                      <Select
                        labelId="nearest-landmark-label"
                        id="nearest_landmark"
                        label="Nearest Landmark"
                        disabled={showNearestLandmark}
                        value={formik.values.nearest_landmark}
                        onChange={AddNearestLandmark}
                      >
                        {nearestLandmarkOptions?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText style={{ color: "red" }}>
                        {formik.touched.nearest_landmark &&
                          formik.errors.nearest_landmark}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                      <InputLabel id="nearest-metro-label">
                        Nearest Metro
                      </InputLabel>
                      <Select
                        labelId="nearest-metro-label"
                        id="nearest_metro"
                        value={formik.values.nearest_metro}
                        label="Nearest Metro"
                        disabled={showNearestMetro}
                        onChange={AddNearestMetro}
                      >
                        {nearestMetroOptions?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText style={{ color: "red" }}>
                        {formik.touched.nearest_metro &&
                          formik.errors.nearest_metro}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                      <InputLabel id="nearest-mall-label">
                        Nearest Mall
                      </InputLabel>
                      <Select
                        labelId="nearest-mall-label"
                        id="nearest_mall"
                        value={formik.values.nearest_mall}
                        label="Nearest Mall"
                        disabled={showNearestMall}
                        onChange={AddNearestMall}
                      >
                        {nearestMallOptions?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText style={{ color: "red" }}>
                        {formik.touched.nearest_mall &&
                          formik.errors.nearest_mall}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                      <InputLabel id="rooms-label">Rooms</InputLabel>
                      <Select
                        labelId="rooms-label"
                        id="rooms"
                        label="Rooms"
                        value={formik.values.rooms}
                        disabled={showRooms}
                        onChange={AddRooms}
                      >
                        {roomsOptions?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText style={{ color: "red" }}>
                        {formik.touched.rooms && formik.errors.rooms}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                      <InputLabel id="has_parking-label">
                        Has Parking
                      </InputLabel>
                      <Select
                        labelId="has_parking-label"
                        id="has_parking"
                        label="Has Parking"
                        value={formik.values.has_parking}
                        disabled={showHasParking}
                        onChange={AddHasParking}
                      >
                        {hasParkingOptions?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText style={{ color: "red" }}>
                        {formik.touched.has_parking &&
                          formik.errors.has_parking}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                      <InputLabel id="procedure-area-label">
                        Procedure Area
                      </InputLabel>
                      <Select
                        labelId="procedure-area-label"
                        id="procedure_area"
                        label="Procedure Area"
                        disabled={showProcedureArea}
                        value={formik.values.procedure_area}
                        onChange={AddProcedureArea}
                      >
                        {procedureAreaOptions?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText style={{ color: "red" }}>
                        {formik.touched.procedure_area &&
                          formik.errors.procedure_area}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  {/* Include other dropdown fields similarly */}

                  <Grid item xs={12} md={8}>
                    {status === "Add" || status === "View" ? (
                      <Button
                        type="submit"
                        disabled={disable}
                        className="primary-button"
                        style={{
                          fontSize: "14px",
                          padding: "6px",
                          borderRadius: "5px",
                        }}
                      >
                        {loading ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    ) : status === "Edit" ? (
                      <Button
                        type="submit"
                        disabled={disable}
                        className="primary-button"
                        style={{
                          fontSize: "14px",
                          padding: "6px",
                          borderRadius: "5px",
                        }}
                      >
                        {loading ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          "Update"
                        )}
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>
              </form>
            </SubCard>
          </Grid>
        </Grid>
      ) : (
        <div style={{ textAlign: "center", marginTop: "40px" }}>
          <CircularProgress />
        </div>
      )}
    </>
  );
}

export default PropertyArea;
