// PaymentPlan.jsx
import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";

const PaymentPlan = ({ paymentPlans, onSave, status }) => {
  const [formValues, setFormValues] = useState({
    paymentPlans: [{ reservation: "", amount: "", afterTime: "" }],
  });

  useEffect(() => {
    if (paymentPlans && paymentPlans.length > 0) {
      setFormValues({ paymentPlans: paymentPlans });
    } else {
      // Set default values when paymentPlans is empty or undefined
      setFormValues({
        paymentPlans: [{ reservation: "", amount: "", afterTime: "" }],
      });
    }
  }, [paymentPlans]);
  const handleFormChange = (e, index) => {
    const { name, value } = e.target;
    setFormValues((prevFormValues) => ({
      paymentPlans: prevFormValues.paymentPlans.map((plan, i) =>
        i === index ? { ...plan, [name]: value } : plan
      ),
    }));
  };

  const handleAddNewPlan = () => {
    setFormValues((prevFormValues) => ({
      paymentPlans: [
        ...prevFormValues.paymentPlans,
        { reservation: "", amount: "", afterTime: "" },
      ],
    }));
  };

  const handleDeletePlan = (index) => {
    setFormValues((prevFormValues) => ({
      paymentPlans: prevFormValues.paymentPlans.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = () => {
    const nonEmptyListings = formValues.paymentPlans.filter((plan) => {
      return Object.values(plan).some((value) => value.trim() !== "");
    });

    onSave(nonEmptyListings);
  };

  return (
    <Grid item xs={12} sm={12}>
      <form noValidate autoComplete="off">
        {formValues?.paymentPlans.map((plan, index) => (
          <Grid
            container
            spacing={2}
            key={index}
            style={{ marginBottom: "20px" }}
          >
            <Grid item xs={12} sm={4}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel htmlFor={`reservation-${index}`}>
                  Reservation
                </InputLabel>
                <Select
                  fullWidth
                  name="reservation"
                  value={plan.reservation}
                  onChange={(e) => handleFormChange(e, index)}
                  label="Reservation"
                  inputProps={{
                    readOnly: status === "View",
                  }}
                >
                  <MenuItem value="Initial">Initial</MenuItem>
                  <MenuItem value="Reservation">Reservation</MenuItem>
                  <MenuItem value="PartialPayment">Partial Payment</MenuItem>
                  <MenuItem value="PaymentPlan">Payment Plan</MenuItem>
                  <MenuItem value="SalesAgreement">Sales Agreement</MenuItem>
                  <MenuItem value="Negotiating<">Negotiating</MenuItem>
                  <MenuItem value="Paid">Paid</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Amount"
                name="amount"
                value={plan.amount}
                inputProps={{
                  readOnly: status === "View",
                }}
                onChange={(e) => handleFormChange(e, index)}
              />
            </Grid>
            <Grid item xs={10} sm={3}>
              <TextField
                fullWidth
                label="Aftertime"
                name="afterTime"
                value={plan.afterTime}
                inputProps={{
                  readOnly: status === "View",
                }}
                onChange={(e) => handleFormChange(e, index)}
              />
            </Grid>
            {/* <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                label="Initial"
                name="initial"
                value={plan.initial}
                inputProps={{
                  readOnly: status === 'View'
                }}
                onChange={(e) => handleFormChange(e, index)}
              />
            </Grid> */}
            {status !== "View" && (
              <Grid item xs={1} sm={0.4}>
                <IconButton
                  color="black"
                  style={{ margin: "5px 0 5px" }}
                  inputProps={{
                    readOnly: status === "View",
                  }}
                  onClick={() => handleDeletePlan(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )}
            {status !== "View" && (
              <Grid item xs={1} sm={0.4}>
                <IconButton
                  color="black"
                  style={{ margin: "5px 0 5px" }}
                  onClick={handleAddNewPlan}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        ))}
        {status !== "View" && (
          <Button
            className="primary-button"
            onClick={handleSubmit}
            style={{
              fontSize: "14px",
              padding: "8px 12px",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#eeeeee",
              },
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Outfit",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
                color: "white",
              }}
            >
              {status === "Add" ? "Save" : "Update"}
            </Typography>
          </Button>
        )}
      </form>
    </Grid>
  );
};

export default PaymentPlan;
