import React, { useState, useEffect } from "react";
import { Avatar, Button, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { UploadImage } from "Api/uplaodImage";

const UploadAccountAvatar = ({ GetProfilePic, profileImg = null, status }) => {
  const [profileImage, setProfileImage] = useState(profileImg);

  useEffect(() => {
    // Update profileImage state when profileImg prop changes
    setProfileImage(profileImg);
  }, [profileImg]);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const id = toast.loading("Uploading...");
    try {
      const response = await UploadImage(file);
      if (response) {
        toast.update(id, {
          render: "Image uploaded successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        GetProfilePic(response.data.urlPath);
        setProfileImage(response.data.urlPath); // Update profileImage state
      }
    } catch (error) {
      toast.update(id, {
        render: "Something went wrong. Please try again later",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      {/* Profile Image Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <br />
          <Avatar
            src={profileImage}
            alt="Profile"
            className="profile-picture"
            style={{
              width: "175px", // Set the desired width
              height: "175px", // Set the desired height
              objectFit: "cover",
              borderRadius: "50%",
              margin: "0 auto 10px", // Center the Avatar horizontally
            }}
          />

          {status !== "View" && (
            <>
              <Typography
                variant="caption"
                sx={{ textAlign: "center", fontSize: "12px" }}
              >
                Upload/Update Your Profile Image
              </Typography>
              <br />
              {/* Input for selecting image */}
              <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              {/* Label for file input */}
              <label htmlFor="contained-button-file">
                <Button
                  className="primary-button"
                  component="span"
                  style={{
                    fontSize: "11px",
                    padding: "4px 6px",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "#eeeeee",
                    },
                    margin: "8px 0", // Add margin for spacing
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#3f51b5",
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      fontFamily: "Outfit",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    Upload Image
                  </Typography>
                </Button>
              </label>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadAccountAvatar;
