import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "./PropertyDetail.css";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const Graph = ({ graphData }) => {
  const isCarouselScreen = useMediaQuery("(max-width:600px)");
  const [graphRVAData, setGraphRVAData] = useState([]);
  const [graphPVAData, setGraphPVAData] = useState([]);
  const [graphToDisplay, setGraphToDisplay] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentYear = new Date().getFullYear();

  // if (graphData && graphData.RVA && graphData.RVA.annual_amount && graphData.RVA.date) {
  //   // Create a new array by mapping over the annual_amount and date arrays
  //   const newData = graphData.RVA.annual_amount.map((amount, index) => ({
  //     RentalValue: amount,
  //     Year: new Date(graphData.RVA.date[index]).getFullYear().toString(),
  //   }));
  //   setGraphRVAData(newData);
  // }
  // useEffect(() => {
  //   let indexVal = 0;
  //   if (
  //     graphData &&
  //     graphData.RVA &&
  //     graphData.RVA.annual_amount &&
  //     graphData.RVA.date
  //   ) {
  //     // const newData = graphData.RVA.annual_amount.map((amount, index) => ({
  //     //   RentalValue: amount,
  //     //   Year: new Date(graphData.RVA.date[index]).getFullYear().toString(),
  //     // }));
  //     const newData = graphData.RVA.annual_amount
  //       .map((amount, index) => {
  //         const year = new Date(graphData.RVA.date[index]).getFullYear();
  //         if (year >= currentYear) {
  //           return {
  //             RentalValue: amount,
  //             Year: year.toString(),
  //           };
  //         }
  //         return null; // Filter out items that don't match the criteria
  //       })
  //       .filter((item) => item !== null);
  //     setGraphRVAData(newData);
  //     setGraphToDisplay(newData);
  //     setLoading(false);
  //   }
  //   setLoading(true);
  //   if (
  //     graphData &&
  //     graphData.PVA &&
  //     graphData.PVA.actual_worth &&
  //     graphData.PVA.instance_date
  //   ) {
  //     // const newData1 = graphData.PVA.actual_worth.map((amount, index) => ({
  //     //   RentalValue: amount,
  //     //   Year: new Date(graphData.PVA.instance_date[index])
  //     //     .getFullYear()
  //     //     .toString(),
  //     // }));
  //     const newData1 = graphData.PVA.actual_worth
  //       .map((amount, index) => {
  //         const year = new Date(
  //           graphData.PVA.instance_date[index]
  //         ).getFullYear();
  //         if (year >= currentYear) {
  //           return {
  //             RentalValue: amount,
  //             Year: year.toString(),
  //           };
  //         }
  //         return null; // Filter out items that don't match the criteria
  //       })
  //       .filter((item) => item !== null);
  //     setGraphPVAData(newData1);
  //     setLoading(false);
  //   }
  // }, [graphData]);
  useEffect(() => {
    if (graphData) {
      setLoading(true);
      if (graphData.RVA && graphData.RVA.annual_amount && graphData.RVA.date) {
        const rvaData = graphData.RVA.annual_amount
          .map((amount, index) => {
            const year = new Date(graphData.RVA.date[index]).getFullYear();
            if (year >= currentYear) {
              return {
                RentalValue: amount,
                Year: year.toString(),
              };
            }
            return null;
          })
          .filter((item) => item !== null)
          .reduce((acc, curr) => {
            if (!acc[curr.Year]) {
              acc[curr.Year] = { total: 0, count: 0 };
            }
            acc[curr.Year].total += curr.RentalValue;
            acc[curr.Year].count += 1;
            return acc;
          }, {});
        const averagedRvaData = Object.keys(rvaData).map((year) => ({
          Year: year,
          RentalValue: rvaData[year].total / rvaData[year].count,
        }));
        setGraphRVAData(averagedRvaData);
        setGraphToDisplay(averagedRvaData);
      }
      if (
        graphData.PVA &&
        graphData.PVA.actual_worth &&
        graphData.PVA.instance_date
      ) {
        const pvaData = graphData.PVA.actual_worth
          .map((amount, index) => {
            const year = new Date(
              graphData.PVA.instance_date[index]
            ).getFullYear();
            if (year >= currentYear) {
              return {
                RentalValue: amount,
                Year: year.toString(),
              };
            }
            return null;
          })
          .filter((item) => item !== null)
          .reduce((acc, curr) => {
            if (!acc[curr.Year]) {
              acc[curr.Year] = { total: 0, count: 0 };
            }
            acc[curr.Year].total += curr.RentalValue;
            acc[curr.Year].count += 1;
            return acc;
          }, {});
        const averagedPvaData = Object.keys(pvaData).map((year) => ({
          Year: year,
          RentalValue: pvaData[year].total / pvaData[year].count,
        }));
        setGraphPVAData(averagedPvaData);
      }
      setLoading(false);
    }
  }, [graphData, currentYear]);
  const handleSelectChange = (e) => {
    if (e.target.value === "RVA") {
      setGraphToDisplay(graphRVAData);
    }
    if (e.target.value === "PVA") {
      setGraphToDisplay(graphPVAData);
    }
  };
  if (!graphData) {
    return <div>No Data found</div>;
  }
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <Box style={{ marginTop: "60px" }}>
      <LineChart
        width={isCarouselScreen ? 280 : 640}
        height={isCarouselScreen ? 240 : 327}
        data={graphToDisplay}
      >
        <Line
          type="monotone"
          dataKey="RentalValue"
          stroke="#B8D2E2"
          strokeWidth={4}
          style={{
            margin: "5 30 20 5",
          }}
        />
        {/* <Line
          type="monotone"
          dataKey="TotalROI"
          stroke="#FFDE88"
          strokeWidth={3}
        />
        <Line
          type="monotone"
          dataKey="RentalYield"
          stroke="#E1E1E1"
          strokeWidth={3}
        /> */}
        <CartesianGrid stroke="#ccc" />
        <XAxis dataKey="Year" />
        <YAxis dataKey="RentalValue" />
        <Tooltip />
        {/* <Legend /> */}
      </LineChart>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <FormControl style={{ width: "20%" }}>
          <InputLabel id="demo-simple-select-label">Graph</InputLabel>
          <Select
            label="Graph"
            onChange={handleSelectChange}
            className={classes.FieldStyl}
          >
            <MenuItem value="RVA">RVA</MenuItem>
            <MenuItem value="PVA">PVA</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};
export default Graph;
