import React, { useState, useEffect } from "react";
// import { styled } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
// import { createTheme} from '@mui/material/styles';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { Button, IconButton, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { mytheme } from "themes/tableHeader";
// import { PROPERTIES_ENDPOINT } from 'apis/api';
import CircularLoader from "ui-component/loader/CircularLoader";

import { TableHeading } from "components/tableHeading";
import { DELETE_PROPERTY, GET_PROPERTIES } from "grapgQL/property";
import { useContext } from "react";
import { PropertyContext } from "context/property";
import { useLazyQuery, useMutation } from "@apollo/client";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box } from "@mui/system";
import { toast } from "react-toastify";
import { AuthContext } from "context/AuthContext";
// import Avatar from '@mui/material/Avatar';
// import '../pages/pages.scss';
// import PropertyCard from 'ui-component/cards/PropertyCard';

const PropertyPage = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  // const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState("");
  const [propData, setPropData] = useState([]);
  const { propertyInfo, setPropertyInfo } = useContext(PropertyContext);
  const open = Boolean(anchorEl);

  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(1);
  // const theme = useTheme();
  const { logOut } = useContext(AuthContext);
  const [page, setPage] = useState(1);

  const [allProperties] = useLazyQuery(GET_PROPERTIES, {
    fetchPolicy: "no-cache",
    context: { clientName: "backend" },
    onCompleted(data) {
      const {
        properties,
        totalPages,
        success,
        message,
      } = data.getAllProperties;

      if (properties) {
        if (success) {
          setLoading(false);

          const dataWithIndex = properties?.map((data, index) => ({
            index: index + 1,
            ...data,
          }));

          setTotalRows(dataWithIndex.length);
          setTotalPages(totalPages);
          setPropData(dataWithIndex);
        } else {
          if (message === "Token Expired") {
            logOut();
          }
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    },
    onError(error) {
      setLoading(false);
      console.log("Something went wrong", error);
    },
  });

  const [deleteProperty] = useMutation(DELETE_PROPERTY, {
    onCompleted(data) {
      const { success, message } = data.deleteProperty;
      if (success) {
        setPropData([]);
        setLoading(true);
        setPage(1);
        allProperties({
          variables: {
            page: 1,
          },
        });

        toast.success(message);
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      console.log("error", error);
    },
  });

  useEffect(() => {
    if (page) {
      allProperties({
        variables: {
          page: page,
        },
      });
    }
  }, [propertyInfo, page, totalPages, totalRows]);

  const handleClick = (value, event) => {
    setRowData(value);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = (value) => {
    if (window.confirm("Are you sure you want to delete this Propety?")) {
      // User clicked "OK"
      // Assuming Value is defined elsewhere in your code
      deleteProperty({
        variables: {
          ID: value._id,
        },
      });
      setAnchorEl(null);
    } else {
      // User clicked "Cancel" or closed the dialog
      // Handle accordingly, if needed
    }
  };
  const handleEdit = (value) => {
    setAnchorEl(null);
    Navigate("/properties/propertydetails", {
      state: { status: "Edit", value },
    });
  };
  const handleClickOpen = () => {
    Navigate("/properties/propertydetails", {
      state: { status: "Add", value: 0 },
    });
  };
  const handleView = (value, event) => {
    Navigate("/properties/propertydetails", {
      state: { status: "View", value },
    });
  };

  const Navigate = useNavigate();

  const columns = [
    {
      name: "index",
      label: " ",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: "propertyName",
      label: "Project Name",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: "propertyInformation",
      label: "Location",

      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return value?.location;
        },
      },
    },
    {
      name: "propertyInformation",
      label: "Price",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return value?.price
            ? value?.price?.fixPrice > 0
              ? `AED ${value?.price?.fixPrice}`
              : `AED ${value?.price?.min} - ${value?.price?.max}`
            : "PRICE UNAVAILABLE";
        },
        onRowClick: (rowData) => {
          console.log(rowData);
        },
      },
    },
    {
      name: "propertyDetails",
      label: "Category",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return value?.buildingType?.toUpperCase();
        },
      },
    },
    // {
    //   name: 'propertyInformation',
    //   label: 'Bedrooms',
    //   options: {
    //     sort: false,
    //     customBodyRender: (value) => {
    //       return value?.bedrooms;
    //     }
    //   }
    // },
    // {
    //   name: 'propertyInformation',
    //   label: 'Area (sq ft)',
    //   options: {
    //     sort: false,
    //     customBodyRender: (value) => {
    //       return value?.area;
    //     }
    //   }
    // },
    // {
    //   name: 'aiEstimator',
    //   label: 'Initial Investment',
    //   options: {
    //     sort: false,
    //     filter: false,
    //     customBodyRender: (value) => {
    //       return value?.initialInvestment;
    //     }
    //   }
    // },
    {
      name: "aiEstimator",
      label: "Gross Rental Yield",
      options: {
        sort: false,
        customBodyRender: (value) => {
          // Check if value is a string
          if (typeof value?.grossRentalYield === "string") {
            // Parse the string into a floating-point number
            const numericValue = parseFloat(value?.grossRentalYield);
            // Check if parsing was successful and the result is a number
            if (!isNaN(numericValue)) {
              // Round the number to two decimal places using toFixed()
              const roundedValue = numericValue.toFixed(2);
              // Convert the rounded value back to a number
              const roundedNumber = parseFloat(roundedValue);
              return roundedNumber;
            }
          }
          // If value is not a string or parsing failed, return it as is
          return value?.grossRentalYield;
        },
      },
    },
    {
      name: "aiEstimator",
      label: "Value Appreciation",
      options: {
        sort: false,
        customBodyRender: (value) => {
          // Check if value is a string
          if (typeof value?.appreciation === "string") {
            // Parse the string into a floating-point number
            const numericValue = parseFloat(value?.appreciation);
            // Check if parsing was successful and the result is a number
            if (!isNaN(numericValue)) {
              // Round the number to two decimal places using toFixed()
              const roundedValue = numericValue.toFixed(2);
              // Convert the rounded value back to a number
              const roundedNumber = parseFloat(roundedValue);
              return roundedNumber;
            }
          }
          // If value is not a string or parsing failed, return it as is
          return value?.appreciation;
        },
      },
    },
    {
      name: "propertyInformation",
      // name: 'Profile',
      label: " ",

      options: {
        sort: false,
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Typography
              className="details-text"
              onClick={(event) => handleView(propData[dataIndex], event)}
              sx={{ cursor: "pointer", fontFamily: "Outfit" }}
            >
              View Details
            </Typography>
          );
        },
      },
    },
    {
      name: "propertyInformation",
      label: " ",

      options: {
        sort: false,
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <Button
                type="button"
                onClick={(event) => handleClick(propData[dataIndex], event)}
              >
                <MoreVertIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {/* <MenuItem onClick={() => handleEdit(rowData)}>
                  <ModeEditOutlineOutlinedIcon /> Edit
                </MenuItem> */}
                <MenuItem onClick={() => handleDelete(rowData)}>
                  <DeleteOutlinedIcon /> Delete
                </MenuItem>
              </Menu>
            </>
          );
        },
      },
    },
  ];
  const handlePrevPage = () => {
    if (page > 0) {
      setPropertyInfo([]);
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    // Assuming 10 rows per page (adjust as needed)

    if (page < totalPages) {
      setPropertyInfo([]);
      setPage(page + 1);
    }
  };

  const HeaderElements = () => {
    return (
      <Button
        className="primary-button"
        onClick={() => handleClickOpen()}
        style={{
          fontSize: "14px",
          padding: "8px 12px",
          borderRadius: "8px",

          "&:hover": {
            backgroundColor: "#eeeeee",
          },
          margin: "0 8px",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontFamily: "Outfit",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0em",
            textAlign: "center",
          }}
        >
          Add Properties
        </Typography>
      </Button>
    );
  };

  const options = {
    customHeadRender: () => ({
      style: {
        fontFamily: "Outfit",
        fontSize: "48px",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0",
        textAlign: "center",
      },
    }),

    responsive: "standard",
    print: false,
    download: false,
    viewColumns: false,
    tableLayout: "fixed",
    customTableBodyWidth: "800px",
    tableBodyHeight: "500px",
    selectableRowsHideCheckboxes: true,
    customFooter: () => (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        height="60px"
      >
        <Typography
          variant="body1"
          sx={{
            margin: "0 4px",
            minWidth: 40,
            textAlign: "center",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontSize: "15px",
            fontWeight: 400,
            lineHeight: "20.02px",
            color: "rgba(0, 0, 0, 0.87)",
            marginRight: "20px",
          }}
        >
          1-{totalRows} of {totalRows}
        </Typography>
        <IconButton
          size="small"
          onClick={handlePrevPage}
          disabled={page === 1}
          sx={{ marginRight: 1, padding: 0.5 }}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          size="small"
          onClick={handleNextPage}
          disabled={page >= totalPages}
          sx={{ padding: 0.5, marginRight: "30px" }}
        >
          <KeyboardArrowRight />
        </IconButton>
      </Box>
    ),

    customToolbar: HeaderElements,
  };

  return (
    <>
      <TableHeading name={"Properties"} />
      {/* <Loader/> */}
      {loading ? (
        // <div>i am loading</div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularLoader />
        </div>
      ) : (
        <>
          {propData ? (
            <ThemeProvider theme={mytheme}>
              <MUIDataTable
                // title={'Users list'}
                data={propData}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          ) : (
            <ThemeProvider theme={mytheme}>
              <MUIDataTable
                // title={'Users list'}
                data={[]}
                columns={columns}
                options={options}
              />{" "}
            </ThemeProvider>
          )}
        </>
      )}
    </>
  );
};

export default PropertyPage;
