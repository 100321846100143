import React, { useState } from 'react';
import { Typography } from '@mui/material';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import General from 'ui-component/General';
import AccountManager from 'ui-component/AccountManagers/AccountManager';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import AccountManagerListing from 'ui-component/AccountManagers/AccountManagerLisitng';
import { TableHeading } from 'components/tableHeading';

function SettingPage() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <TableHeading name={'Setting'} />
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              textColor="secondary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#FBB03B'
                }
              }}
            >
              <Tab label="General" value="1" />

              <Tab label="Account Users" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <General />
          </TabPanel>

          <TabPanel value="2">
            <AccountManagerListing />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}

export default SettingPage;
