import { IconUser } from '@tabler/icons';

// constant
const icons = {
  IconUser
};
const queries = {
  id: 'queries',
  type: 'group',
  children: [
    {
      id: 'queries',
      title: 'Queries',
      type: 'item',
      url: '/queries',
      icon: icons.IconUser,
      // icon: AccountCircleOutlinedIcon,
      breadcrumbs: false
    }
  ]
};

export default queries;
