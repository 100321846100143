import React, { useContext, useEffect } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const DevelopersPermission = ({ component }) => {
  const { isLoggedIn } = useContext(AuthContext);
  const userDataString = localStorage.getItem('userData');
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const AccountManagerAccess = userData?.accountManager?.access.developersAccess;

  console.log(AccountManagerAccess);
  const auth = isLoggedIn();

  return auth.role[0] === 'Admin' || (auth.role[0] === 'accountManager' && AccountManagerAccess) ? component : <Navigate to="/404" />;
};

export default DevelopersPermission;
