import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

export default function Chart() {
  return (
    <>
      <LineChart
        xAxis={[{ data: [2019, 2020, 2021, 2022, 2023] }]}
        series={[
          {
            data: [25000, 5000, 30000, 15000, 45000],
            color: '#fbb03b'
          }
        ]}
        width={500}
        height={300}
      />
    </>
  );
}
