import React from "react";
// import {TextField,InputLabel} from '@mui/material/TextField';
// import { TextField, FormControl, InputLabel } from '@mui/material';
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Button, Typography, Box, Avatar } from "@mui/material";
import Password from "./Password";
import { useTheme } from "@mui/material/styles";

import User1 from "../assets/images/users/user-round.svg";
import { useState } from "react";

import { useContext } from "react";
import { UPDATE_USER } from "grapgQL/users";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { UploadImage } from "Api/uplaodImage";

function General() {
  const theme = useTheme();
  const userDataString = localStorage.getItem("userData");
  const authUser = userDataString ? JSON.parse(userDataString) : null;
  // useEffect(() => {
  //   setUserImage(authUser?.profilePicture);
  // }, []);

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phoneNo: "",
    country: "",
    address: "",
  });

  const [userImage, setUserImage] = useState(authUser?.profilePicture);

  const handleInputChange = (field) => (event) => {
    setUserData({ ...userData, [field]: event.target.value });
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const id = toast.loading("Uploading...");
    try {
      const response = await UploadImage(file);
      if (response) {
        toast.update(id, {
          render: "Image uploaded successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });

        setUserImage(response.data.urlPath); // Update profileImage state
      }
    } catch (error) {
      toast.update(id, {
        render: "Something went wrong. Please try again later",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };
  const [updateUserInfo] = useMutation(UPDATE_USER, {
    onCompleted(data) {
      const { success, message, user } = data.updateUserInfo;
      if (success) {
        localStorage.setItem("userData", JSON.stringify(user));
        toast.success(message);
      } else {
        toast.error(message);
      }
    },

    onError(error) {
      toast.error("Something Went Wrong!");
    },
  });

  const handleAvatarClick = () => {
    document.getElementById("image-upload").click();
  };

  const handleSaveClick = () => {
    if (
      !userData.name ||
      !userData.email ||
      !userData.phoneNo ||
      !userData.country ||
      !userData.address
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    if (userData.email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(userData.email)) {
        toast.error("Please enter valid email address");
        return;
      }
    }

    const postalAddress = {
      countryRegion: userData.country || authUser.postalAddress.countryRegion,
      streetAddress: userData.address || authUser.postalAddress.streetAddress,
      city: authUser?.postalAddress?.city || " ",
      zipPostalCode: authUser?.postalAddress?.zipPostalCode || 0,
    };
    updateUserInfo({
      variables: {
        userID: authUser._id,
        updateObj: {
          profilePicture: userImage,
          name: userData.name,
          email: userData.email,
          whatsappNumber: userData.phoneNo,
          postalAddress: postalAddress,
        },
      },
    });
    // You can perform further actions with the form data and uploaded image
  };

  return (
    <>
      {/* <FormControl> */}
      <Box className="propertycontainer">
        <Typography>Profile</Typography>
        <div
          onClick={handleAvatarClick}
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          <Avatar
            src={userImage}
            sx={{
              width: "120px",
              height: "120px",
              marginRight: "20px",
              margin: "16px 0",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontFamily: "Outfit",
                fontSize: "24px",
                fontWeight: 400,
                lineHeight: "32px",
                color: "rgba(0, 0, 0, 0.85)",
              }}
            >
              {authUser.name}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              {authUser.email}
            </Typography>
          </div>
          <input
            id="image-upload"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <InputLabel htmlFor="my-input">Name</InputLabel>
            <TextField
              id="my-input"
              variant="outlined"
              fullWidth
              value={userData.name}
              onChange={handleInputChange("name")}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <InputLabel htmlFor="email">Email</InputLabel>
            <TextField
              id="email"
              variant="outlined"
              fullWidth
              value={userData.email}
              onChange={handleInputChange("email")}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <InputLabel htmlFor="phoneNo">Phone No</InputLabel>
            <TextField
              id="phoneNo"
              variant="outlined"
              fullWidth
              value={userData.phoneNo}
              onChange={handleInputChange("phoneNo")}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <InputLabel htmlFor="country">Country</InputLabel>
            <TextField
              id="country"
              variant="outlined"
              fullWidth
              value={userData.country}
              onChange={handleInputChange("country")}
            />
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <InputLabel htmlFor="address">Address</InputLabel>
            <TextField
              id="address"
              variant="outlined"
              fullWidth
              value={userData.address}
              onChange={handleInputChange("address")}
            />
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <Button className="primary-button" onClick={handleSaveClick}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Password />
    </>
  );
}

export default General;
