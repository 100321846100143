import { gql } from "@apollo/client";
export const GET_ACCOUNT_MANAGERS = gql`
  query getAllAccountManagers($page: Int) {
    getAllAccountManagers(page: $page) {
      success
      message
      accountManagers {
        id
        user {
          name
          email
          role
          profilePicture
        }

        access {
          bookingsAccess
          propertiesAccess
          expertsAccess
          usersAccess
          developersAccess
          queriesAccess
        }
        created_at
        updated_at
      }
      totalDocs
      totalPages
      currentPage
    }
  }
`;

export const ADD_ACCOUNT_MANAGER = gql`
  mutation addAccountManager(
    $name: String
    $email: String
    $password: String
    $profilePicture: String
    $access: AccessPermissionsInput
  ) {
    addAccountManager(
      input: {
        name: $name
        email: $email
        password: $password
        access: $access
        profilePicture: $profilePicture
      }
    ) {
      success
      message

      accountManager {
        id
        user {
          name
          email
        }

        created_at
        updated_at
        access {
          bookingsAccess
          propertiesAccess
          expertsAccess
          usersAccess
          developersAccess
          queriesAccess
        }
      }
    }
  }
`;
export const UPDATE_ACCOUNT_MANAGER = gql`
  mutation updateAccountManager(
    $id: ID!
    $name: String
    $email: String
    $password: String
    $access: AccessPermissionsInput
    $profilePicture: String
  ) {
    updateAccountManager(
      id: $id
      input: {
        name: $name
        email: $email
        password: $password
        access: $access
        profilePicture: $profilePicture
      }
    ) {
      success
      message

      accountManager {
        id
        user {
          name
          email
          profilePicture
        }

        # Omitting password for security reasons
        access {
          bookingsAccess
          propertiesAccess
          expertsAccess
          usersAccess
          developersAccess
          queriesAccess
        }
        created_at
        updated_at
      }
    }
  }
`;
export const DELETE_ACCOUNT_MANAGER = gql`
  mutation deleteAccountManager($ID: ID!) {
    deleteAccountManager(ID: $ID) {
      success
      message
    }
  }
`;
