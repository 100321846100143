import React, { createContext, useState } from 'react';

// Create the context
export const DevelopersContext = createContext();

// Create the context provider

export const DevelopersProvider = ({ children }) => {
  const [developersInfo, setDevelopersInfo] = useState();

  return <DevelopersContext.Provider value={{ developersInfo, setDevelopersInfo }}>{children}</DevelopersContext.Provider>;
};
