import { IconUser } from '@tabler/icons';

  // constant
  const icons = {
    IconUser
  };
const bookings = {
  // id: 'user-docs-roadmap',
    id: 'bookings',
    type: 'group',
    children: [
        {
            id: 'bookings',
            title: 'Bookings',
            type: 'item',
            url: '/bookings',
            icon: icons.IconUser,
            // icon: AccountCircleOutlinedIcon,
            breadcrumbs: false
        }
    ]
};

export default bookings;
