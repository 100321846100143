import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import ProfileContent from "./propertydetail-tabs/profileContent";
import AIContent from "../../../ui-component/AiEstimator";
import MoreInformationContent from "./propertydetail-tabs/moreInformationContent";
// import { propertyData } from 'apis/propertydata';
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import Estimator from "views/property-page/propertydetails/propertydetail-tabs/Estimator";
import PropertyArea from "./propertydetail-tabs/PropertyArea";

function PropertyDetails() {
  const location = useLocation();

  const [value, setValue] = useState("1");
  const [property, setProperty] = useState();
  const [propertyInformation, setPropertyInformation] = useState("");
  const [aiModelInformation, setAIModelInformation] = useState("");
  const [morePropertyInformation, setMorePropertyInformation] = useState("");
  const [permission, setPermission] = useState(false);
  const [viewStatus, setViewStatus] = useState("");
  const [sharedData, setSharedData] = useState("");

  useEffect(() => {
    const assignValues = (searchData) => {
      console.log("Ss,", searchData.predict);
      setProperty(searchData);
      setPropertyInformation({
        ...searchData.propertyInformation,
        propertyID: searchData._id,
        description: searchData.description,
        name: searchData.propertyName,
        propertyDetails: searchData.propertyDetails,
        setSelectedPlace: searchData.setSelectedPlace,
        featuredImage: searchData.featuredImage,

        initialInvestment: searchData.initialInvestment,
        propertyType: searchData.propertyType,
      });
      setAIModelInformation({
        aiModelInformation: searchData.aiEstimator,
        predict: searchData.predict,
      });
      if (property) {
        const { propertyInformation, aiEstimator, ...otherFields } = property;
        setMorePropertyInformation(otherFields);
      }
    };

    const searchData = location?.state?.value;
    setViewStatus(location?.state?.status);

    if (searchData) {
      assignValues(searchData);
    }
  }, [location, property]);
  console.log("Ddddddddd", aiModelInformation);
  const handleChange = (event, newValue) => {
    if (newValue < permission) {
      setPermission(newValue);
      setValue(newValue);
    } else {
      toast.error("Please Fill the Information To Proceed");
    }
  };

  const handleChangeView = (event, newValue) => {
    setValue(newValue);
  };

  const handleReactTabs = (newValue) => {
    setPermission(newValue);
    if (newValue === 2 || newValue === 3 || newValue === 4) {
      setValue(newValue.toString());
    } else {
      viewStatus === "View"
        ? toast.error("Press F")
        : toast.error("Please Fill the Information To Proceed");
    }
  };
  const getPropertyInfo = (updatedVal) => {
    console.log("values ", updatedVal);
  };

  return (
    <>
      <Typography variant="h2">Property Details</Typography>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={viewStatus === "View" ? handleChangeView : handleChange}
              aria-label="lab API tabs example"
              textColor="secondary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#FBB03B",
                },
              }}
            >
              <Tab label="Property" value="1" />
              {viewStatus === "View" && <Tab label="Estimator" value="2" />}
              <Tab label="More Information" value="3" />
              {viewStatus !== "View" && (
                <Tab label="Property Rental" value="4" />
              )}
              {/* <Tab label="Property Rental" value="4" /> */}
            </TabList>
          </Box>

          {viewStatus === "View" ? (
            <>
              <TabPanel value="1">
                <ProfileContent
                  propertyInformation={propertyInformation}
                  status={viewStatus}
                  onTabsValue={handleReactTabs}
                />
              </TabPanel>
              <TabPanel value="2">
                <Estimator aiModelInformation={aiModelInformation} />
              </TabPanel>
              <TabPanel value="3">
                <MoreInformationContent
                  morePropertyInformation={morePropertyInformation}
                  status={viewStatus}
                />
              </TabPanel>

              <TabPanel value="4">
                <PropertyArea />
              </TabPanel>
            </>
          ) : (
            <>
              <TabPanel value="1">
                <ProfileContent
                  propertyInformation={propertyInformation}
                  status={viewStatus}
                  onTabsValue={handleReactTabs}
                  propertyInfomation={getPropertyInfo}
                  sharedData={sharedData}
                  setSharedData={setSharedData}
                />
              </TabPanel>
              <TabPanel value="3">
                <MoreInformationContent
                  morePropertyInformation={morePropertyInformation}
                  status={viewStatus}
                  sharedData={sharedData}
                  onTabsValue={handleReactTabs}
                  setSharedData={setSharedData}
                />
              </TabPanel>

              <TabPanel value="4">
                <PropertyArea />
              </TabPanel>
            </>
          )}
        </TabContext>
      </Box>
    </>
  );
}

export default PropertyDetails;
