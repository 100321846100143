import React, { useState, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Autocomplete,
  Marker,
} from "@react-google-maps/api";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { useEffect } from "react";

const MapWithAutocomplete = ({ onSelectedValue, selectedLocation, status }) => {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [mapZoom, setMapZoom] = useState(2);
  const [inputValue, setInputValue] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };
  const autocompleteRef = useRef(null);
  const markerRef = useRef(null);
  useEffect(() => {
    if (selectedLocation) {
      setInputValue(
        `${selectedLocation?.location?.lat},${selectedLocation?.location?.lng}`
      );
    }
  }, [selectedLocation]);

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (!place.geometry || !place.geometry.location) {
      console.log("Place not found");
      return;
    }

    setSelectedPlace({
      name: place.name,
      location: {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      },
    });
    onSelectedValue({
      name: place.name,
      location: {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      },
    });
    openModal();

    setMapCenter({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });

    setMapZoom(12); // Set a zoom level appropriate for the selected place
    setInputValue(
      `${place.geometry.location.lat()},${place.geometry.location.lng()}`
    );
  };

  const onInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const onMarkerDragEnd = (e) => {
    const name = selectedPlace.name;
    const newLat = e.latLng.lat();
    const newLng = e.latLng.lng();
    setSelectedPlace({
      ...selectedPlace,
      location: {
        lat: newLat,
        lng: newLng,
      },
    });
    onSelectedValue({
      name: name,
      location: {
        lat: newLat,
        lng: newLng,
      },
    });
  };

  return (
    <>
      <LoadScript
        googleMapsApiKey="AIzaSyDe-ZOLNib2nv_i3gTo10Ndf4-WAs71IdI"
        libraries={MapWithAutocomplete.libraries}
        onLoad={() => console.log("Google Maps API loaded")}
        loadingElement={<div style={{ height: "100%" }} />}
        googleMapsUrl={`https://maps.googleapis.com/maps/api/js?key=AIzaSyDe-ZOLNib2nv_i3gTo10Ndf4-WAs71IdI&v=weekly&libraries=places&callback=initMap`}
      >
        <Autocomplete
          onLoad={(auto) => {
            autocompleteRef.current = auto;
            console.log("Autocomplete loaded:", auto);
          }}
          onPlaceChanged={onPlaceChanged}
        >
          <TextField
            type="text"
            placeholder="Enter location"
            fullWidth
            inputProps={{
              readOnly: status === "View",
            }}
            label="Exact Location"
            value={inputValue}
            onChange={onInputChange}
          />
        </Autocomplete>
      </LoadScript>
      <Dialog open={modalOpen} onClose={closeModal}>
        <div style={{ height: "400px", width: "600px" }}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={mapCenter}
            zoom={mapZoom}
          >
            {selectedPlace && (
              <Marker
                position={{
                  lat: selectedPlace.location.lat,
                  lng: selectedPlace.location.lng,
                }}
                draggable={true}
                onDragEnd={onMarkerDragEnd}
                ref={markerRef}
              />
            )}
          </GoogleMap>
        </div>

        <DialogActions>
          <Button onClick={closeModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

MapWithAutocomplete.libraries = ["places"];

export default MapWithAutocomplete;
