import { createTheme } from '@mui/material/styles';
export const mytheme = createTheme({
  components: {
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Outfit'
        }
      }
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Outfit',
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '24px',
          letterSpacing: '0'
        }
      }
    }
  }
});
