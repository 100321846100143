import { IconUser } from '@tabler/icons';

// constant
const icons = {
  IconUser
};
const expert = {
  id: 'experts',
  type: 'group',
  children: [
    {
      id: 'experts',
      title: 'Experts',
      type: 'item',
      url: '/experts',
      icon: icons.IconUser,
      // icon: AccountCircleOutlinedIcon,
      breadcrumbs: false
    }
  ]
};

export default expert;
