import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import Routes from 'routes';

// defaultTheme
import themes from 'themes';
import NavigationScroll from 'layout/NavigationScroll';
import Notificaction from 'ui-component/notication';

//contextProvider
import { UserProvider } from 'context/user';
import { ExpertsProvider } from 'context/expert';
import { DevelopersProvider } from 'context/developers';
import { PropertyProvider } from 'context/property';
import { AccountManagersProvider } from 'context/AccountManagers';
import { BookingProvider } from 'context/Booking';
import { AuthProvider } from 'context/AuthContext';

const App = () => {
  const customization = useSelector((state) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <AuthProvider>
          <UserProvider>
            <PropertyProvider>
              <BookingProvider>
                <ExpertsProvider>
                  <DevelopersProvider>
                    <AccountManagersProvider>
                      <CssBaseline />
                      <NavigationScroll>
                        <Routes />
                        <Notificaction />
                      </NavigationScroll>
                    </AccountManagersProvider>
                  </DevelopersProvider>
                </ExpertsProvider>
              </BookingProvider>
            </PropertyProvider>
          </UserProvider>
        </AuthProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
