// import dashboard from './dashboard';

import users from './users';
import property from './property';
import expert from './experts';
import bookings from './bookings';
import queries from './queries';
import setting from './setting';
import developers from './developers';

const menuItems = {
  items: [
    // dashboard,
    users,
    property,
    bookings,
    expert,
    queries,
    developers,
    setting
  ]
};

export default menuItems;
